@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CoachNotificationSettingsContainer{
    @include container($width: 92%);
    position: relative;
    margin-left: 1.5%;
    background: #FFF;
    box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
    flex-grow: 2;
    overflow-x: hidden;
    .NotificationContainerContent{
        @include flex-box($direction: column, $align: flex-start, $justify: flex-start); 
        width: 100%;
        padding: 0px 16px;
        box-sizing: border-box;
        .CoachNotificationTopSection{
            width: 100%;
            h2{
                margin: 0;
                padding: 0;
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
            }
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
        }
        .CoachNotificationMidSection{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 24px;
            width: 100%;
            .MidSectionContentDrawer{
                width: 100%;
                height: 48px;
                padding: 12px 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                @include flex-box($justify: space-between);
                cursor: pointer;
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px; /* 175% */
                }
                .MidSectionIcon{
                    height: 24px;
                    width: 24px;
                    color: #9BA6BC;
                }
            }
        }
    }
}