@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.TopContent {
    width: 100%;
    gap: 32px;
    @include flex-box($direction: column, $align: flex-start);
    .ApplicationsTop {
        @include flex-box($justify: flex-start);
        width: 100%;
        gap: 18px;
        cursor: pointer;
        .AppIcon {
            width: 24px;
            height: 24px;
            color: #21272A;
        }
        p {
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; /* 26.4px */
            padding: 0;
            margin: 0;
        }
    }
    h6 {
        color: var(--Foundation-PRI-pri-500, #2A53A9);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        padding: 0;
        margin: 0;
    }
    .ApplicantCounts {
        @include flex-box($justify: space-between);
        width: 100%;
        .ApplicantCount {
            @include flex-box($justify: flex-start);
            gap: 16px;
            p {
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 22.4px */
            }
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 114px;
                height: 48px;
                padding: 12px 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid var(--Secondary-200, #9BA6BC);
                background: #ECF1FE;
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                text-align: center;
                font-family: $first-font;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 28px */
            }
        }
        .ApplicantStatus {
            @include flex-box($justify: flex-start);
            gap: 16px;
            p {
                color: #000;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
            }
            span {
                border-radius: 4px;
                border: 0.5px solid var(--Secondary-200, #9BA6BC);
                background: var(--Alert-Succes-600, #00B374);
                display: flex;
                width: 185px;
                height: 48px;
                padding: 12px 16px;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                color: var(--Black-And-White-White, #FFF);
                text-align: center;

                /* Body/Medium */
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
            }
        }
    }
}

.ApplicationsContainer {
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .ApplicationsContent {
        @include flex-box($direction: column, $align: flex-start);
        width: 100%;
        background-color: #FFF;
        margin-bottom: 100px;
        // padding: 40px;
        box-sizing: border-box;
        gap: 40px;
        .ApplicationBox {
            width: 100%;
            display: flex;
            .ApplicationDistribution {
                width: 100%;
                display: none;
                @include flex-box($justify: space-between, $align: flex-start);
                .ApplicationTable {
                    width: 65%;
                    gap: 11px;
                    border-radius: 4px;
                    border: 0.5px solid var(--Foundation-PRI-pri-200, #9DB0D7);
                    background: #FFF;
                    box-shadow: 0px 7px 5px -4px rgba(60, 118, 241, 0.05);
                    padding: 16px 0px;
                    @include flex-box($direction: column, $align: flex-start);
                    .AppTableHeading {
                        width: 100%;
                        padding: 0px 16px;
                        box-sizing: border-box;
                        @include flex-box($justify: space-between);
                        h6 {
                            color: var(--Foundation-PRI-pri-500, #2A53A9);

                            /* Heading/H7 */
                            font-family: $first-font;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%; /* 22px */
                            padding: 0;
                            margin: 0;
                        }
                        a {
                            color: var(--Black-And-White-White, #FFF);
                            text-align: center;
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                            text-decoration: none;
                            height: 57px;
                            padding: 16px 24px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            border: 0.5px solid var(--Primary-200, #D8E4FC);
                            background: var(--Foundation-PRI-pri-500, #2A53A9);
                            cursor: pointer;
                        }
                    }
                    .AppPaper {
                        width: 100%;
                        .AppTable {
                            width: 100%;
                            .AppTableHead {
                                width: 100%;
                                .AppTableRow {
                                    width: 100%;
                                    border-radius: 4px 4px 0px 0px;
                                    border: 0.5px solid var(--Secondary-200, #9BA6BC);
                                    background: var(--Primary-100, #ECF1FE);
                                    .AppTableCell {
                                        color: var(--CoolGray-100, #121619);

                                        /* Caption/Medium */
                                        font-family: $first-font;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 140%; /* 19.6px */
                                    }
                                }
                            }
                            .AppTableBody {
                                width: 100%;
                                .AppTableBodyRow {
                                    width: 100%;
                                    .AppTableBodyCell {
                                        color: var(--Foundation-Neutral-Black, #2E2C2C);

                                        /* Body/Regular */
                                        font-family: $first-font;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 140%; /* 22.4px */
                                        .ActionContainer {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .AppTableTotal {
                        width: 100%;
                        padding: 0px 52px;
                        box-sizing: border-box;
                        @include flex-box($justify: space-between);
                        border: none;
                        // background: #F5F8FF;
                        p {
                            padding: 0;
                            margin: 0;
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 140%; /* 22.4px */
                        }
                        p:last-child {
                            margin-right: 193px;
                        }
                    }
                }
                .ApplicationChart {
                    width: 30%;
                    padding: 16px;
                    border-radius: 4px;
                    box-sizing: border-box;
                    gap: 16px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    background: var(--Default-White, #FFF);
                    box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05);
                    @include flex-box($direction: column, $align: flex-start);
                    h6 {
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-family: $first-font;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%; /* 22px */
                        padding: 0;
                        margin: 0;
                    }
                    .AppPieWrapper {
                        width: 100%;
                        height: 200px;
                        padding: 0;
                    }
                    .AppPieChartKeys {
                        width: 100%;
                        gap: 20px;
                        @include flex-box($direction: column, $align: flex-start);
                        .AppPieChartKey {
                            width: 100%;
                            @include flex-box($justify: space-between);
                            span {
                                p {
                                    padding: 0;
                                    margin: 0;
                                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                                    font-family: $first-font;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 140%; /* 19.6px */
                                }
                            }

                        }
                    }
                }

            }
            .AllApplicantsView {
                display: none;
            }
            .AllApplicantsView .reveal {
                display: flex;
                height: auto;
                width: 100%;
                max-height: 99999px;
            }
        }
    }
}
.ApplicationDetails {
    @include flex-box($direction: column, $align: flex-start);
    gap: 10px;
    width: 100%;
    padding: 32px 16px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--Primary-200, #D8E4FC);
    background: var(--Default-White, #FFF);
    .ApplicationInputs {
        @include flex-box($justify: space-between);
        width: 100%;
        #ApplicationInput {
            width: 32%;
            @include flex-box($direction: column, $align: flex-start);
            gap: 8px;
            label {
                color: var(--CoolGray-90, #21272A);

                /* Body/Regular */
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
        }
        .ApplicationInput{
            width: 100%;
            height: 48px;
            padding: 12px 16px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 0.5px solid var(--Secondary-200, #9BA6BC);
            background: var(--Primary-100, #ECF1FE);
        }
        .ApplicationInput::placeholder {
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
        }
    }
}

.ViewOverlay {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .View {
        position: fixed;
        left: 81%;
        // top: 50%;
        width: 150px;
        padding: 8px 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 4px;
        background: var(--Dark-Base-Base-White, #FFF);
        box-shadow: 0px 2px 8px 0px rgba(41, 82, 225, 0.15);
        a {
            color: var(--Dark-Base-Base-Black, #2C2C2C);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            margin: 0;
            cursor: pointer;
            text-decoration: none;
        }
        a:hover {
            background: var(--Primary-100, #ECF1FE);
        }
    }
}

.AllApplicantContainer {
    width: 100%;
    .AllPaper {
        width: 100%;
        .AllTable {
            width: 100%;
            .AllTableHead {
                width: 100%;
                .AllTableRow {
                    width: 100%;
                    border-radius: 4px 4px 0px 0px;
                    background: var(--Primary-100, #ECF1FE);
                    .AllTableCell {
                        color: var(--CoolGray-100, #121619);

                        /* Caption/Medium */
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 19.6px */
                    }
                }
            }
            .AllTableBody {
                width: 100%;
                .AllTableBodyRow {
                    width: 100%;
                    .AllTableBodyCell {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);

                        /* Body/Regular */
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
            }
        }
    }
}