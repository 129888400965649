@import "../../static/styles/shared.scss";
@import "../../static/styles/variables.styles.scss";

.CoachCalendarContainer {
	@include container($width: 90%);
	position: relative;
	margin-top: 1.5rem;
	margin-left: 9%;
	margin-bottom: 3%;
	flex-grow: 2;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	gap: 30px;
	.SearchField {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.SearchIcon {
			position: absolute;
			color: #9ba6bc;
			width: 20px;
			height: 20px;
			transform: translateX(8px) translateY(-2px);
		}
		#SearchField {
			width: 100%;
			height: 40px;
			border-radius: 4px;
			padding-left: 30px;
			border-radius: 4px;
			border: 0.5px solid #d8e4fc;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			&::placeholder {
				color: var(--Secondary-200, #9ba6bc);
				font-family: "TT Wellingtons Trl";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
			}
		}
	}
	.AppointmentSection {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		h1 {
			color: var(
				--Foundation-PRI-pri-500,
				#2a53a9
			);
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
		}
		button {
			color: var(--Black-And-White-White, #fff);
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			padding: 10px 20px;
			border-radius: 4px;
			background: var(
				--Foundation-PRI-pri-500,
				#2a53a9
			);
			cursor: pointer;
			border: none;
		}
	}
	.FilterCalendar {
		width: 100%;
		overflow-y: scroll;
		overflow-x: scroll;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		.FilterSection {
			width: 26%;
			display: flex;
			flex-direction: column;
			margin-right: 10px;
			gap: 20px;
			// border-right: 1px solid #D9D9D9;
			min-height: 300px;
			.FilterField {
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				p {
					font-family: "TT Wellingtons Trl";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					color: #2c2c2c;
				}
				.SelectField {
					border-radius: 8px;
					border: 1px solid
						var(--Foundation-PRI-pri-500, #2a53a9);
					width: 90px;
					height: 33px;
					color: var(
						--Foundation-PRI-pri-500,
						#2a53a9
					);
					text-align: center;
					font-family: "TT Wellingtons Trl";
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
				}
			}
			.CalendarDates {
				padding-right: 10px;
				.FilterCalendar {
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					cursor: pointer;
					border-bottom: solid 0.5px #9ba6bc;
					.FilterHeader {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						.Accordion {
							width: 20px;
							height: 20px;
							color: #21272a;
							cursor: pointer;
						}
						h1 {
							width: 70%;
							color: var(
								--Dark-Base-Base-Black,
								#2c2c2c
							);
							font-family: "TT Wellingtons Trl";
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							margin-left: -1px;
						}
					}
					.FilterDate {
						margin-left: 50px;
						margin-top: -12px;
						height: 30px;
						display: flex;
						align-items: center;
						p {
							color: #3c98cc;
							font-family: "TT Wellingtons Trl";
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
						}
					}
					.EventList {
						margin-left: 50px;
						margin-top: -14px;
						p {
							color: #979797;
							font-family: "TT Wellingtons Trl";
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
						}
						ul {
							list-style-position: outside;
							li {
								color: var(
									--Secondary-200,
									#9ba6bc
								);
								font-family: "TT Wellingtons Trl";
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								margin-left: -20px;
							}
						}
					}
				}
			}
		}
		.CalendarSection {
			width: 74%;
			overflow-x: scroll;
			min-height: 100vh;
			transform: translateY(7px);
		}
	}
}
