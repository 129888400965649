@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.ApplicantJobViewContainer {
  @include container();
  @include flex-box($direction: row, $justify: flex-start, $align: flex-start);
  gap: 60px;
  overflow-x: hidden;
  .ApplicantJobViewContent {
    padding: 80px 40px;
    box-sizing: border-box;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 9%;
    padding: 80px 40px;
    box-sizing: border-box;
    width: 100%;
    @include flex-box($direction: column, $align: flex-start);
    .ApplicantJobViewNavigation {
      @include flex-box();
      gap: 18px;
      cursor: pointer;
      margin-bottom: 40px;
      .ApplicantBackIcon {
        height: 24px;
        width: 24px;
        color: #21272a;
      }
      p {
        padding: 0;
        margin: 0;
        color: var(--Foundation-Neutral-Black, #2e2c2c);
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 26.4px */
      }
    }
    .JobViewTopsection {
      @include flex-box($justify: space-between);
      width: 100%;
      padding: 25px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid var(--Primary-200, #d8e4fc);
      background: var(--Default-White, #fff);
      margin-bottom: 25px;
      .ApplicantProfile {
        @include flex-box();
        gap: 8px;
        img {
          width: 88px;
          height: 88px;
          border: #21272a 1px solid;
          border-radius: 100%;
        }
        span {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          h6 {
            margin: 0;
            padding: 0;
            color: var(--Foundation-Neutral-Black, #2e2c2c);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
          }
          p {
            color: var(--Secondary-200, #9ba6bc);
            font-family: $first-font;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 16.8px */
            padding: 0;
            margin: 0;
          }
        }
      }
      button {
        color: var(--Foundation-PRI-pri-500, #2a53a9);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 162.5% */
        letter-spacing: 0.2px;
        border-radius: 4px;
        padding: 8px 16px;
        background: transparent;
        border: 1px solid var(--Foundation-PRI-pri-500, #2a53a9);
      }
    }
    .ApplicantJobTabsSection {
      width: 100%;
      @include flex-box($justify: space-between);
      .JobViewTabs {
        @include flex-box($justify: flex-start);
        gap: 24px;
        border-bottom: 1px solid var(--Primary-200, #d8e4fc);
        .JobViewTab {
          text-decoration: none;
          button {
            @include flex-box();
            color: var(--Foundation-Neutral-Black, #2e2c2c);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            padding: 16px 0px;
            height: 48px;
            box-sizing: border-box;
            border: none;
            background: transparent;
          }
        }
      }
      .JobViewTabsNavigation {
        @include flex-box($justify: flex-start);
        gap: 20px;
        .IconWrapper {
          @include flex-box();
          width: 40px;
          height: 40px;
          border-radius: 4px;
          background: rgba(42, 83, 169, 0.05);
          cursor: pointer;
          .TabIcon {
            height: 20px;
            width: 20px;
            color: #2a53a9;
          }
        }
      }
    }
    .ApplicantJobTabDetails {
      width: 100%;
      margin-top: 20px;
    }
  }
}
