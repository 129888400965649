@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CourseSuccessModalContainer{
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .CourseSuccessModal{
        width: 390px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px;
        box-sizing: border-box;
        gap: 16px;
        border: 1px solid var(--CoolGray-20, #DDE1E6);
        background: var(--Default-White, #FFF);
        .SuccessIconWrapper{
            height: 48px;
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            background: var(--alert-succes-500-base, #00C781);
            .SuccessIcon{
                width: 24px;
                height: 24px;
                color: #E6EBF1;
            }
        }
        h3{
            color: var(--CoolGray-90, #21272A);
            font-family: $first-font;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 35.2px */
            padding: 0;
            margin: 0;
        }
        p{
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
        }
        button{
            cursor: pointer;
            width: 100%;
            height: 48px;
            padding: 16px 12px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Default-White, #FFF);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */ 
        }
    }
}