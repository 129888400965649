@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.RecruitmentProcessContainer{
    margin-top: -40px;
    display: flex;
    align-items: flex-start;
    padding: 48px;
    .RecruitmentProcessContent{
        @include flex-box($direction: column, $justify: flex-start, $align: flex-start);
        width: 100%;
        gap: 40px;
        h2{
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 26.4px */
        }
        .RecruitmentProcessForm{
            width: 100%;
            gap: 40px;
            @include flex-box($direction: column, $justify: flex-start, $align: flex-start);
            .RecruitmentSelectField{
                width: 100%;
                height: 48px;
                padding: 12px 16px;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                color: var(--Secondary-200, #9BA6BC);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
            #RecruitmentSelectField{
                width: 100%;
                gap: 8px;
                label{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
            .RecruitmentInterviewProcess{
                width: 100%;
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    margin: 0;
                    padding: 0;
                }
                .RecruitmentProcessBox{
                    width: 100%;
                    height: 48px;
                }
            }
            .RecruitmentOnBoardingProcess{
                width: 100%;
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    margin: 0;
                    padding: 0;
                }
                .RecruitmentProcessBox{
                    width: 100%;
                    height: 48px;
                }
            }
            .RecruitmentContractLength{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
                .RecruitmentProcessSlider{
                    width: 100%;
                    @include flex-box();
                    gap: 10px;
                    span{
                        text-align: center;
                        font-family: $first-font;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 8.633px; /* 86.326% */
                    }
                    .SliderWrapper{
                        width: 90%;
                        color: #3C76F1;
                    }
                }
            }
            .RecruitmentSaveButton{
                width: 100%;
                height: 48px;
                box-sizing: border-box;
                @include flex-box($justify: flex-end);
                button{
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 16px */
                    letter-spacing: 0.5px;
                    height: 100%;
                    width: 69px;
                    padding: 16px 12px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
                    background-color: transparent;
                }
            }
        }
        .RecruitmentProceedButton{
            color: var(--Default-White, #FFF);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            height: 48px;
            width: 100%;
            border-radius: 4px;
            border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
        }
    }
}