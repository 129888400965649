@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.SettingsSideBarContainer{
    @include flex-box();
    border-radius: 4px;
    border: 0.5px solid var(--Primary-200, #D8E4FC);
    margin-left: 48px;
    width: 450px;
    .ContainerContent{
        width: 100%;
        box-sizing: border-box;
        gap: 32px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .SideBarHeader{
            padding: 8px 0px;
            padding-left: 16px;
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            border-bottom: 0.5px solid var(--Primary-200, #D8E4FC);
            .SideBarHeaderIcon{
                width: 56px;
                height: 56px;
            }
            h2{
                color: var(--Secondary-200, #9BA6BC);
                font-family: $first-font;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 86.667% */
                letter-spacing: 0.2px;
                margin: 0;
                padding: 0;
            }
        }    
        .SideTabs{
            @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
            gap: 32px;
            padding: 0px 16px;
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 60px;
            .SideTab{
                text-decoration: none;
                box-sizing: border-box;
                gap: 8px;
                width: 100%;
                @include flex-box($align: center, $justify: flex-start);
                .SideTabIcon{
                    width: 24px;
                    height: 24px;
                }
                p{
                    color: var(--CoolGray-90, #21272A);
                    font-family: $first-font;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 22px */
                }
            }
    
        }
    }
}