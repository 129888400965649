@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CultureContainer {
  border: solid 1px #9ba6bc;
  .CultureInnerContainer {
    @include container($width: 95.5%);
    @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 2rem;
    .CultureHeader {
      h3 {
        @include text(
          $font-size: 24px,
          $color: $first-text,
          $font-weight: 600,
          $font-family: $first-font,
          $text-align: justify,
          $line-height: normal
        );
      }
    }
    .CultureTextField {
      .CultureTextArea {
        width: 1090px;
        height: 200px;
        flex-direction: column;
        gap: 5px;
        border-radius: 4px;
        border: 0.5px solid#D8E4FC;
        margin-top: 10px;
        padding-top: 7px;
        padding-left: 10px;
        font-size: 14px;
        color: black;
        font-weight: 400;
        font-family: $first-font;
        resize: none;
      }
      button {
        display: flex;
        margin-left: 90%;
        margin-top: 15px;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        background-color: white;
        position: relative;
        border-radius: 4px;
        color: #2a53a9;
        border: 2px solid #2a53a9;
        width: 100px;
        height: 35px;
        @include text(
          $font-size: 14px,
          $color: #9ba6bc,
          $font-weight: 700,
          $font-family: $first-font,
          $text-align: justify,
          $line-height: normal
          
        );
      }
    }
    .CultureDivider {
      width: 100%;
      border: solid 1px #d1d4db;
      margin-left: -1rem;
      margin-bottom: 30px;
    }
    .CultureProceedButton {
      button {
        width: 1100px;
        height: 40px;
        border-radius: 4px;
        border: 2px solid #2a53a9;
        color: white;
        background: #2a53a9;
        margin-bottom: 35px;
      }
    }
  }
}
