@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.OptionsContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .Options {
        position: fixed;
        left: 83%;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 4px;
        background: var(--Dark-Base-Base-White, #FFF);
        box-shadow: 0px 2px 8px 0px rgba(41, 82, 225, 0.15);
        p {
            color: var(--Dark-Base-Base-Black, #2C2C2C);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            margin: 0;
            cursor: pointer;
        }
        p:hover {
            background: var(--Primary-100, #ECF1FE);
        }
    }
}

.RecruiterJobContainer{
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .RecruiterJobContent{
        width: 100%;
        @include flex-box($direction: column, $align: flex-start);
        .JobSearchInputField{
            width: 100%;
            height: 57px;
            box-sizing: border-box;
            gap: 8px;
            padding: 16px 24px;
            margin-bottom: 40px;
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            @include flex-box($justify: flex-start);
            .JobSearchIcon{
                width: 24px;
                height: 24px;
                color: #9BA6BC;
            }
            .JobSearchTextField{
                border: none;
                outline: none;
                height: auto;
                width: 100%;
            }
            .JobSearchTextField::placeholder{
                color: var(--Secondary-200, #9BA6BC);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
            }
        }
        .JobTopSection{
            width: 100%;
            @include flex-box($justify: space-between);
            margin-bottom: 24px;
            h3{
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
            }
            .JobButtonTabs{
                gap: 24px;
                @include flex-box($justify: flex-start);
                button{
                    border: none;
                    background: transparent;
                    padding: 16px 0px;
                    height: 48px;
                    box-sizing: border-box;
                    color: var(--CoolGray-90, #21272A);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 22.4px */
                }
                button:nth-child(1){
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    border-bottom: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
                }
            }
            .JobSelectButtons{
                gap: 20px;
                @include flex-box($justify: flex-start);
                button:first-child{
                    border-radius: 4px;
                    border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                    padding: 8px 16px;
                    box-sizing: border-box;
                    height: 48px;
                    background: transparent;
                    select{
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                        border: none;
                        outline: none;
                    }
                }
                .RecruiterJobCreate{
                    border-radius: 4px;
                    border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                    background: var(--Foundation-PRI-pri-500, #2A53A9);
                    padding: 8px 16px;
                    height: 48px;
                    box-sizing: border-box;
                    color: var(--Black-And-White-White, #FFF);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    text-decoration: none;
                }
            }
        }
        .JobTablePaper{
            width: 100%;
            box-sizing: border-box;
            .JobTableContainer{
                width: 100%;
                .JobTable{
                    width: 100%;
                    .JobTableHead{
                        width: 100%;
                        border-radius: 4px 4px 0px 0px;
                        background: var(--Primary-100, #ECF1FE);
                        .JobTableHeadRow{
                            width: 100%;
                            .JobTableHeadCell{
                                color: var(--CoolGray-100, #121619);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 140%; /* 19.6px */
                            }
                        }
                    }
                    .JobTableBody{
                        width: 100%;
                        .JobTableBodyRow{
                            width: 100%;
                            .JobTableBodyCell{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; /* 22.4px */
                                .SeeMore {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}