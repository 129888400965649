@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.AddQuizContainer {
	@include container($width: 99%);
	position: relative;
	margin-top: 1.5rem;
	margin-left: 1%;
	flex-grow: 2;
	overflow-x: hidden;
	.CourseQuizArea {
		box-sizing: border-box;
		padding: 16px;
		width: 100%;
		border-radius: 4px;
		border: 1px solid var(--Primary-200, #d8e4fc);
		@include flex-box(
			$display: flex,
			$direction: column,
			$align: flex-start,
			$justify: flex-start
		);
		.CourseQuizAccordionWrapper {
			@include flex-box(
				$display: flex,
				$direction: column,
				$align: flex-start,
				$justify: flex-start
			);
			width: 100%;
			gap: 23px;
			.CourseQuizAccordion {
				padding: 16px;
				width: 100%;
				box-sizing: border-box;
				border-radius: 4px;
				border: 1px solid
					var(--Primary-200, #d8e4fc);
				background: var(--Default-White, #fff);
				.CourseQuizHeading {
					@include flex-box(
						$align: center,
						$justify: space-between
					);
					width: 100%;
					height: 24px;
					box-sizing: border-box;
					cursor: pointer;
					h3 {
						color: var(
							--Foundation-PRI-pri-500,
							#2a53a9
						);
						font-family: $first-font;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 110%; /* 17.6px */
					}
					.CourseQuizAccordionIcon {
						height: 24px;
						width: 24px;
						color: #2a53a9;
					}
					.rotate {
						transform: rotate(180deg);
					}
				}
				.CourseQuizDetails {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					width: 100%;
					max-height: 0;
					overflow: hidden;
					transition: all 0.5s
						cubic-bezier(0, 1, 0, 1);
					.CourseQuizQuestion {
						width: 100%;
						p {
							color: var(
								--Foundation-Neutral-Black,
								#2e2c2c
							);
							font-family: $first-font;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 140%; /* 22.4px */
						}
						.CourseQuizQuestionInput {
							width: 100%;
							height: 48px;
							padding: 12px 16px;
							box-sizing: border-box;
							border-radius: 4px;
							border: 0.5px solid
								var(--Primary-200, #d8e4fc);
						}
						.CourseQuizQuestionInput::placeholder {
							color: var(
								--Secondary-200,
								#9ba6bc
							);
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 140%; /* 22.4px */
						}
					}
					.CourseQuizMultipleChoices {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						width: 100%;
						.MultipleChoicesHeading {
							@include flex-box(
								$align: center,
								$justify: space-between
							);
							width: 100%;
							p {
								color: var(
									--Foundation-Neutral-Black,
									#2e2c2c
								);
								font-family: $first-font;
								font-size: 16px;
								font-style: normal;
								font-weight: 400;
								line-height: 140%; /* 22.4px */
							}
							.AddOptionsIcon {
								height: 24px;
								width: 24px;
								color: #2a53a9;
								cursor: pointer;
							}
						}
						.CourseQuizAnswerOptions {
							width: 100%;
							gap: 24px;
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							.CourseQuizAnswerOption {
								@include flex-box(
									$align: center,
									$justify: space-between
								);
								width: 100%;
								.CourseQuizAnswerInput {
									width: 90%;
									height: 48px;
									padding: 12px 16px;
									box-sizing: border-box;
									border-radius: 4px;
									border: 0.5px solid
										var(--Primary-200, #d8e4fc);
								}
								.CourseQuizAnswerInput::placeholder {
									color: var(
										--Secondary-200,
										#9ba6bc
									);
									font-family: $first-font;
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
									line-height: 140%; /* 22.4px */
								}
								.RemoveOptionsIcon {
									height: 24px;
									width: 24px;
									color: #ff4040;
									cursor: pointer;
								}
							}
						}
					}
					.CourseQuizSubmit {
						@include flex-box(
							$justify: space-between
						);
						width: 100%;
						margin-top: 24px;
						.CourseQuizPoint {
							@include flex-box();
							height: 48px;
							box-sizing: border-box;
							padding: 12px 16px;
							border-radius: 4px;
							border: 0.5px solid
								var(--Primary-200, #d8e4fc);
							span {
								color: var(
									--Secondary-200,
									#9ba6bc
								);
								font-family: $first-font;
								font-size: 16px;
								font-style: normal;
								font-weight: 500;
								line-height: 26px; /* 162.5% */
								letter-spacing: 0.2px;
							}
							.PointInput {
								height: 32px;
								box-sizing: border-box;
								width: 56px;
								margin-left: 8px;
								padding: 12px 16px;
								border-radius: 4px;
								border: 0.5px solid
									var(--Primary-200, #d8e4fc);
							}
						}
						.CourseQuizSaveButton {
							@include flex-box(
								$justify: space-between
							);
							.CourseQuizToggle {
								height: 28px;
								width: 48px;
							}
							button {
								color: var(
									--Black-And-White-White,
									#fff
								);
								display: flex;
								justify-content: center;
								align-items: center;
								height: 40px;
								font-family: $first-font;
								font-size: 16px;
								font-style: normal;
								font-weight: 500;
								line-height: 26px; /* 162.5% */
								letter-spacing: 0.2px;
								margin-left: 8px;
								border-radius: 4px;
								padding: 16px 24px;
								border: 0.5px solid
									var(--Primary-200, #d8e4fc);
								background: var(
									--Foundation-PRI-pri-500,
									#2a53a9
								);
								cursor: pointer;
							}
						}
					}
				}
				.CourseQuizDetails.show {
					margin-top: 20px;
					height: auto;
					max-height: 9999px;
					transition: all 0.5s
						cubic-bezier(0, 1, 0, 1);
					padding: 16px;
					box-sizing: border-box;
				}
			}
		}
		.CourseQuizButton {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 40px;
			margin-top: 68px;
			align-items: flex-end;
			margin-bottom: 40px;
			a {
				width: 200px;
				height: 100%;
				text-decoration: none;
				button {
					width: 100%;
					height: 100%;
					padding: 24px;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 4px;
					border: 0.5px solid
						var(--Primary-200, #d8e4fc);
					background: var(
						--Foundation-PRI-pri-500,
						#2a53a9
					);
					color: var(
						--Black-And-White-White,
						#fff
					);
					font-family: $first-font;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 26px; /* 162.5% */
					letter-spacing: 0.2px;
				}
			}
		}
	}
}
