@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.ApplicantDetailsContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    .ApplicantDetailsBio {
        @include flex-box($direction: column, $align: flex-start);
        width: 100%;
        gap: 16px;
        padding: 16px;
        border-radius: 4px;
        border: 1px solid var(--Primary-200, #D8E4FC);
        background: #FFF;
        box-sizing: border-box;
        h6 {
            padding: 0;
            margin: 0;
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 22px */
        }
        p {
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            padding: 0;
            margin: 0;
        }
    }
    .ApplicantDetailsPort {
        @include flex-box($direction: column, $align: flex-start);
        width: 100%;
        gap: 16px;
        padding: 16px;
        border-radius: 4px;
        border: 1px solid var(--Primary-200, #D8E4FC);
        background: #FFF;
        box-sizing: border-box;
        h6 {
            padding: 0;
            margin: 0;
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 22px */
        }
        span {
            display: flex;
            flex-direction: row;
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 26px;
                padding: 8px 16px;
                box-sizing: border-box;
                margin-left: 9px;
                border-radius: 4px;
                border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
                color: var(--Black-And-White-White, #FFF);
                font-family: $first-font;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 216.667% */
                letter-spacing: 0.2px;
                cursor: pointer;
            }
        }
    }
    .ApplicationDetailsTopSection{
        border-radius: 4px;
        border: 1px solid var(--Primary-200, #D8E4FC);
        background: #FFF;
        gap: 16px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        h3{
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 22px */
            margin: 0;
            padding: 0;
        }
        .ApplicantCoverLetter{
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            padding: 0;
            margin: 0;
        }
        .ApplicantCoverLetter br:after {
        content: '\00a0'; /* Non-breaking space character */
        display: block;
        margin-top: 10px;
        } 
    }  
    .ApplicantDetailsLink{
        @include flex-box($justify: flex-start);
        gap: 24px;
        span{
            @include flex-box($justify: flex-start);
            gap: 9px;
            .LinkIcon{
                width: 24px;
                height: 24px;
                color: #9BA6BC;
            }
            .LinkTitle{
                color: var(--Secondary-200, #9BA6BC);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
            }
            .LinkFile{
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 166.667% */
                padding: 0 12px;
                border-radius: 4px;
                background: rgba(42, 83, 169, 0.05);
            }

        }
    }
    .ApplicationDetail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: auto;
        padding: 16px;
        gap: 16px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid var(--Primary-200, #D8E4FC);
        background: #FFF;
        h6 {
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 22px */
            padding: 0;
            margin: 0;
        }
    }
    .RelevanceScore {
        @include flex-box($direction: column, $align: flex-start);
        width: 100%;
        h2 {
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 22px */
            padding: 0;
            margin: 0;
        }
    }
    .AddNote {
        @include flex-box($direction: column, $align: flex-start);
        width: 100%;
        gap: 16px;
        .AddNewNote {
            @include flex-box($justify: space-between, $align: flex-start);
            width: 100%;
            h6 {
                color: var(--Foundation-PRI-pri-500, #2A53A9);

                /* Heading/H7 */
                font-family: $first-font;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 22px */
                padding: 0;
                margin: 0;
            }
            span {
                @include flex-box($justify: flex-end, $align: flex-start);
                gap: 16px;
                cursor: pointer;
                p {
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 22.4px */
                    padding: 0;
                    margin: 0;
                }
                .NewNoteIcon {
                    width: 24px;
                    height: 24px;
                    color: #2A53A9;
                }
            }
        }
        .NewNoteInputArea {
            width: 100%;
            @include flex-box($direction: column, $align: flex-end);
            gap: 16px;
            textarea {
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                outline: none;
            }
            textarea::placeholder {
                color: var(--Secondary-200, #9BA6BC);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
            button {
                @include flex-box();
                color: var(--Black-And-White-White, #FFF);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
                height: 32px;
                padding: 16px;
                box-sizing: border-box;
                cursor: pointer;
            }
        }
        .NewNoteArea {
            @include flex-box($direction: column, $align: flex-start);
            gap: 16px;
            padding: 16px;
            box-sizing: border-box;
            width: 100%;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            background: #FFF;
            .Note {
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid #AFAFAF;
            }
        }
    }
    .Button {
        @include flex-box($justify: flex-end);
        width: 100%;
        button {
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            letter-spacing: 0.5px;
            display: flex;
            height: 48px;
            padding: 16px 12px;
            box-sizing: border-box;
            background-color: transpasrent;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border-radius: 4px;
            border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
            cursor: pointer;
        }
    }
}