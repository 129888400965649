@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.AllContainer {
    @include flex-box($direction: column);
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    gap: 40px;
    margin-bottom: 40px;
}
.RecContainer {
    @include flex-box($direction: column);
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    gap: 40px;
    margin-bottom: 40px;
    .RecPaper {
        width: 100%;
        .RecTable {
            width: 100%;
            .RecTableHead {
                width: 100%;
                .RecTableRow {
                    width: 100%;
                    border-radius: 4px 4px 0px 0px;
                    background: var(--Primary-100, #ECF1FE);
                    .RecTableCell {
                        color: var(--CoolGray-100, #121619);

                        /* Caption/Medium */
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 19.6px */
                    }
                }
            }
            .RecTableBody {
                width: 100%;
                .RecTableBodyRow {
                    width: 100%;
                    .RecTableBodyCell {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);

                        /* Body/Regular */
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                        .ActionContainer {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.BreakdownContent {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .Breakdown {
        position: fixed;
        padding: 45px 16px;
        box-sizing: border-box;
        width: 80%;
        height: 100vh;
        overflow-x: hidden;
        gap: 32px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 4px;
        background: var(--Dark-Base-Base-White, #FFF);
        box-shadow: 0px 2px 8px 0px rgba(41, 82, 225, 0.15);
        overflow-y: auto;
        h6 {
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
            padding: 0;
            margin: 0;
        }
        .ApplicantDetails {
            @include flex-box($justify: space-between);
            width: 100%;
            span {
                @include flex-box($justify: flex-start);
                gap: 16px;
                p {
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 22.4px */
                    padding: 0;
                    margin: 0;
                }
                .Name {
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-weight: 500;
                    height: 48px;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    @include flex-box($justify: center, $align: center);
                    border-radius: 4px;
                    border: 0.5px solid var(--Secondary-200, #9BA6BC);
                    background: rgba(236, 241, 254, 0.50);
                }
                .Date {
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 22.4px */
                    @include flex-box($justify: center, $align: center);
                    border-radius: 4px;
                    border: 0.5px solid var(--Secondary-200, #9BA6BC);
                    background: rgba(236, 241, 254, 0.50);
                    height: 48px;
                    padding: 12px 16px;
                    box-sizing: border-box;
                }
            }
        }
        .BreakdownActivityButtons {
            width: 100%;
            @include flex-box($justify: space-between);
            padding: 0px 16px;
            box-sizing: border-box;
            button:first-child {
                display: flex;
                height: 57px;
                padding: 16px 24px;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                text-align: center;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                background-color: transparent;
                cursor: pointer;
            }
            button:last-child {
                display: flex;
                height: 57px;
                padding: 16px 24px;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
                color: var(--Black-And-White-White, #FFF);
                text-align: center;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                cursor: pointer;
            }
        }
    }
}
.ApplicantScoreDistribution {
    width: 100%;
    @include flex-box($direction: column, $align: flex-start);  
    gap: 40px;
    border-radius: 4px;
    border: 1px solid var(--Primary-200, #D8E4FC);
    background: var(--Default-White, #FFF);
    .ApplicantPercentScores {
        width: 100%;
        @include flex-box($direction: column, $align: flex-start);
        gap: 32px;
        padding: 24px 16px;
        .ApplicantPercentScore {
            @include flex-box($justify: flex-start);
            width: 100%;
            gap: 18px;
            p {
                padding: 0;
                margin: 0;
                width: 92px;
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 22.4px */
            }
            .ApplicantScoreWrapper {
                width: 87%;
                height: 32px;
                border-radius: 8px;
                background: var(--Primary-200, #D8E4FC);
                .ApplicantScore {
                    height: 100%;
                    border-radius: 8px;
                    @include flex-box($justify: flex-end);
                    span {
                        width: 32px;
                        height: 32px;
                        border-radius: 100%;
                        @include flex-box();
                        color: var(--Black-And-White-White, #FFF);
                        text-align: center;
                        font-family: $first-font;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%; /* 16.8px */
                        margin-right: -10px;
                    }
                }
            }
        }
    }
    .ApplicantDistributionTable {
        width: 100%;
        .Paper {
            width: 100%;
            .Table {
                width: 100%;
                .TableHead {
                    width: 100%;
                    .TableRow {
                        width: 100%;
                        border-radius: 4px 4px 0px 0px;
                        background: var(--Primary-100, #ECF1FE);
                        .TableCell {
                            color: var(--CoolGray-100, #121619);
                            font-family: $first-font;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 140%; /* 19.6px */
                        }
                    }
                }
                .TableBody {
                    width: 100%;
                    .TableBodyRow {
                        width: 100%;
                        .TableBodyCell {
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                        }
                    }
                }
            }
        }
        .ApplicantScoreTotal {
            width: 100%;
            @include flex-box($justify: space-between);
            // padding:  0 15% 0 25%;
            box-sizing: border-box;
            span {
                // width: 49%;
                @include flex-box($justify: flex-start);
                .Score {
                    height: 48px;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 0.5px solid var(--Secondary-200, #9BA6BC);
                    background: rgba(236, 241, 254, 0.50);
                }
                .Scores {
                    height: 48px;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 0.5px solid var(--Secondary-200, #9BA6BC);
                    background: rgba(236, 241, 254, 0.50);
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    text-align: center;
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 22.4px */
                }
            }
            .Max {
                padding-left: 13%;
                width: 50%;
                justify-content: space-between;
            }
            .App {
                padding-left: 15%;
                gap: 40%;
                width: 50%;
            }
        }
    }
}