@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CultureContainer{
    border: none;
    padding: 48px;
    .CultureWrapper{
        width: 100%;
        margin: 0;
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        h3{
            margin-bottom: 40px;
            color: var(--foundation-neutral-black, #2E2C2C);
            font-family: TT Wellingtons Trl;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%;
        }
        .StudyStrategy{
           display: flex;
           flex-direction: column;
           width: 100%;
           margin: 0;
           .StudyTextArea{
                display: flex;
                gap: 8px;
                width: 100%;
                height: 183px;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid var(--primary-200, #D8E4FC);
                color: #9ba6bc;
                font-family: $first-font;
           }
           #StudyTextArea{
                display: flex;
                flex-direction: column;
                gap: 8px;
                justify-content: center;
                align-items: flex-start;
                label{
                    color: var(--foundation-neutral-black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
           }
           button{
            width: 100%;
            height: 35px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 16px;
            border: none;
            background: transparent;
            padding: 0;
            box-sizing: border-box;
            p{
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                margin: 0;
                padding: 0px 12px;
                height: 35px;
                border: 2px solid var(--foundation-pri-pri-500, #2A53A9);
                color: var(--foundation-pri-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 16px */
                letter-spacing: 0.5px;
            }
           }

        }
        .SuccessMeasure{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0;
            .MeasureTextArea{
                 display: flex;
                 gap: 8px;
                 width: 100%;
                 height: 183px;
                 padding: 16px;
                 box-sizing: border-box;
                 border-radius: 4px;
                 border: 0.5px solid var(--primary-200, #D8E4FC);
                 color: #9ba6bc;
                 font-family: $first-font;
            }
            #MeasureTextArea{
                 display: flex;
                 flex-direction: column;
                 gap: 8px;
                 justify-content: center;
                 align-items: flex-start;
                 label{
                     color: var(--foundation-neutral-black, #2E2C2C);
                     font-family: $first-font;
                     font-size: 16px;
                     font-style: normal;
                     font-weight: 400;
                     line-height: 140%;
                 }
            }
            button{
             width: 100%;
             height: 35px;
             display: flex;
             justify-content: flex-end;
             align-items: center;
             margin-top: 16px;
             border: none;
             background: transparent;
             padding: 0;
             box-sizing: border-box;
             p{
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 border-radius: 4px;
                 margin: 0;
                 padding: 0px 12px;
                 height: 35px;
                 border: 2px solid var(--foundation-pri-pri-500, #2A53A9);
                 color: var(--foundation-pri-pri-500, #2A53A9);
                 font-family: $first-font;
                 font-size: 16px;
                 font-style: normal;
                 font-weight: 500;
                 line-height: 100%; /* 16px */
                 letter-spacing: 0.5px;
             }
            }
 
        }
        button{
            cursor: pointer;
            margin-top: 16px;
            width: 100%;
            height: 35px;
            border-radius: 4px;
            border: 2px solid var(--foundation-pri-pri-500, #2A53A9);
            background: var(--foundation-pri-pri-500, #2A53A9);
            color: var(--default-white, #FFF);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
        }
    }
}