@import "../../static//styles/variables.styles.scss";
@import "../../static/styles/shared.scss";

.AdminSettingContainer {
    @include container($width: 90%);
	position: relative;
	margin-top: 1.5rem;
	margin-left: 9%;
	margin-bottom: 3%;
	flex-grow: 2;
	overflow-x: hidden;
    .AdminSettingContainerWrapper {
        width: 100%;
        @include flex-box($justify: space-between);
        .AdminSettingContent {
            @include flex-box($direction: column, $justify: center, $align: center);
            gap: 10px;
            width: 32%;
            padding: 84px 71px;
            border: 1px solid var(--Primary-100, #ECF1FE);
            background: var(--Black-And-White-White, linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #FFF);
            box-shadow: -6px 6px 8px -2px rgba(108, 107, 107, 0.20);
            cursor: pointer;
            .AdminSettingIcon {
                width: 40px;
                height: 40px;
                color: #2A53A9;
            }
            h6{
                padding: 0;
                margin: 0;
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: "TT Wellingtons Trl";
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
            }
        }
    }
}