@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CoachSubPagesContainer {
  @include container($width: 90%);
  position: relative;
  margin-top: 1.5rem;
  margin-left: 9%;
  flex-grow: 2;
  overflow-x: hidden;
}

#CoachMessagesContainer {
  @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
  .JsMessageContacts {
    height: 91.2vh;
    width: 20%;
    position: fixed;
    top: 7.5%;
    left: 7%;
    background-color: white;
    border-radius: 0px 4px;
    border-bottom: 0.5px solid var(--primary-200, #d8e4fc);
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    .JsSearchBar {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .JsProfileSearch {
        color: #9ba6bc;
        width: 24px;
        height: 24px;

        margin-left: 15px;
      }
      .JsProfileInputSearch {
        border-radius: 4px 0px 0px 4px;
        border: 0.5px solid #d8e4fc;
        width: 110%;
        height: 40px;
        background-color: transparent;
        color: var(--secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding-left: 45px;
        margin-left: -33px;
      }
    }
    .CoachLinks {
      display: grid;
      grid-template-columns: 33.33% 33.33% 33.34%;
      margin-top: 15px;
      a {
        color: var(--secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-decoration: none;
        padding-bottom: 10px;
      }
    }
  }
  .JsChatArea {
    margin-left: 18.5%;
    width: 71%;
    height: 91.2vh;
    top: 7.5%;
    position: fixed;
    background-color: rgba(42, 83, 169, 0.05);
    display: flex;
    flex-direction: column;
    .custom-emoji-picker {
      position: fixed;
      z-index: 14;
      top: 41%;
      left: 80%;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
    .JsChatRoom {
      height: 80.4vh;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .JsTextArea {
      display: flex;
      flex-direction: row;
      margin: 0.1rem;
      margin-top: 0px;
      background-color: white;
      textarea {
        color: var(--primary-200, #0c2e74);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        padding: 10px;
        border: none;
        outline: none;
      }
      div {
        display: flex;
        flex-direction: row;
        align-items: last baseline;
        height: 11vh;
        #smileySet {
          color: #9ba6bc;
          width: 40px;
          height: 30px;
          background-color: white;
          border: none;
          #smileyIcon {
            width: 20px;
            height: 20px;
            color: #9ba6bc;
          }
        }
        #groupButton {
          color: #9ba6bc;
          width: 40px;
          height: 30px;
          background-color: white;
          border: none;
          #dotIcon {
            width: 20px;
            height: 20px;
            color: #9ba6bc;
          }
        }
        #sendButton {
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--foundation-pri-pri-500, #2a53a9);
          background: var(--foundation-pri-pri-500, #2a53a9);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          width: 40px;
          height: 30px;
          border: none;
          #sendIcon {
            width: 20px;
            height: 20px;
            color: white;
          }
        }
      }
    }
  }
  .CoachChatList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .EtherWelcomeChat {
      margin-left: 2%;
      width: 96%;
      border-bottom: 1px solid var(--primary-200, #d8e4fc);
      display: flex;
      flex-direction: column;
      cursor: pointer;
      div {
        display: grid;
        grid-template-columns: 80% 20%;
        h4 {
          margin-left: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 3px;
          color: var(--foundation-neutral-black, #2e2c2c);
          font-family: $first-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }
        p {
          color: var(--foundation-neutral-black, #2e2c2c);
          font-family: $first-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.6px;
          transform: translateY(30px);
        }
      }
      p {
        color: var(--foundation-neutral-black, #2e2c2c);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        width: 90%;
        margin-left: 10px;
        margin-top: -10px;
      }
    }
  }
  .GeneralChatPickedContainer {
    position: absolute;
    left: 20%;
    top: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: var(--foundation-neutral-black, #2e2c2c);
      font-family: $first-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .ChatPageContainer {
    .userSection {
    }
    .chatSection {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;
      width: 70%;
      margin-left: 30px;
      margin-top: 40%;
      img {
        transform: translateY(5px);
      }
      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        span {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          p {
            color: var(--secondary-200, #9ba6bc);
            font-family: $first-font;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            background-color: transparent;
            margin-left: 65%;
          }
          h2 {
            color: var(--foundation-neutral-black, #2e2c2c);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            margin-top: -2px;
          }
        }

        p {
          color: var(--gray-900, #101828);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin-top: -8px;
          border-radius: 0px 8px 4px 4px;
          background: var(--primary-100, #ecf1fe);
          width: 500px;
          padding: 10px;
        }
      }
    }
  }
}

.CoachRoadmap {
  @include container($width: 64vw);
  position: static;
  margin-left: 20vw;
  overflow-x: hidden;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  .SubjectRoadmap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: #2a53a9;
      font-family: $first-font;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
    }
    a{
      color: #2A53A9;
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
    }
  }
  .coachRoadmapImage{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.roadMapList{
    display: flex;
    flex-direction: column;
    gap: 40px;
  .roadMapItem{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
    #weekNum{
      color: #2E2C2C;
      font-family: $first-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
    .roadmapOthers{
      display: flex;
      gap: 10px;
      margin-top: -20px;
      p{
        color: #2E2C2C;
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .roadmapBreaker{
    border: 1px solid var(--primary-200, #D8E4FC);
    width: 100%;
    margin-bottom: -35px;
    margin-left: -10px;
  }
  }
}
.CoachProgress {
  @include container($width: 64vw);
  position: static;
  margin-left: 20vw;
  overflow-x: hidden;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p{
    color: var(--foundation-pri-pri-500, #2A53A9);
    font-family: $first-font;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
}
.ProgressListContainer{
  .ProgressListInnerContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
}