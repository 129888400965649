@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.FilledProfileContainer{
    @include container($width: 92%);
    position: relative;
    margin-top: 49px;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .ContainerWrapper{
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        .CoverPhotoSection{
            @include flex-box($display: flex, $direction: row, $align: flex-start, $justify: flex-start);
            width: 100%;
            height: 294px;
            border-radius: 4px;
            border: 1px solid #DDE1E6;
            background-image: url("../../../images/backgroundImage.png"), lightgray 50% / cover no-repeat, rgba(197, 214, 251, 0.10);
            .TopNavigation{
                @include flex-box($display: flex, $direction: row, $align: center, $justify: flex-start);
                gap: 92%;
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                .EditIcon{
                    cursor: pointer;
                }
                .Navigation{
                    cursor: pointer;
                    @include flex-box($display: flex, $direction: row, $align: center, $justify: flex-start);
                    .NavigationIcon{
                        width: 24px;
                        height: 24px;
                        color: #2A53A9;
                    }
                    p{
                        color: var(--Opacity-Opacity-80, rgba(0, 0, 0, 0.80));
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                    }
                }
            }
        }
        .ProfileDetails{
            gap: 2%;
            width: 100%;
            margin-top: -10px;
            height: 346px;
            @include flex-box($display: flex, $direction: row, $align: center, $justify: flex-start);
            img{
                width: 40%;
                height: 100%;
                border-radius: 4.667px;
            }
            .AboutInfo{
                width: 70%;
                height: 100%;
                box-sizing: border-box;
                padding: 16px;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                h3{
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 26px; /* 108.333% */
                    letter-spacing: 0.2px;
                    margin: 0;
                }
                .ProfileRating{
                    gap: 12px;
                    width: 99%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    span:nth-child(1){
                        font-family: $first-font;
                        display: flex;
                        padding: 0px 12px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        background: rgba(60, 118, 241, 0.10);
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                    }
                    span:nth-child(3){
                        display: flex;
                        padding: 0px 12px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        background: rgba(255, 187, 56, 0.10);
                        color: var(--alert-warning-500-base, #FFBB38);
                        font-family: $first-font;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        margin-right: 9px;
                    }
                    span:nth-child(4){
                        gap: 10px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        span{
                            color: #000;
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                        }
                    }
                }
                .BioDetails{
                    margin-top: 16px;
                    p{
                        margin: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */

                    }
                }
            }
        }
        .MiddleSection{
            @include flex-box($display: flex, $direction: row, $align: flex-start, $justify: flex-start);
            width: 99%;
            gap: 1%;
            margin-top: 32px;
            .CoachPerks{
                width: 60%;
                @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
                .ProfilePreview{
                    @include flex-box($display: flex, $direction: row, $align: flex-start, $justify: flex-start);
                    width: 100%;
                    height: 244px;
                    margin-bottom: 48px;
                    gap: 2%;
                    .ProfileStrength{
                        background: #FFF;
                        width: 49%;
                        height: 100%;
                        padding: 16px;
                        box-sizing: border-box;
                        box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
                        .Heading{
                            display: flex;
                            width: 100%;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            margin: 0;
                            h6{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 140%; /* 22.4px */
                                margin: 0;
                                margin-right: 35%;
                            }
                            .ThreeDots{
                                cursor: pointer;
                            }
                        }
                        .Visibility{
                            display: flex;
                            width: 100%;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            margin-top: 10px;
                            p{
                                color: var(--Secondary-200, #9BA6BC);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%;
                                margin: 0;
                                margin-left: 9px;
                            }
                        }
                        .ExperienceLevel{
                            width: 100%;
                            margin: 0;
                            margin-top: 24px;
                            @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
                            h6{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 140%; /* 22.4px */
                                margin: 0;
                            }
                            .ProgressBar{
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 9px;
                                .PercentBar{
                                    background: var(--Primary-100, #ECF1FE);
                                    height: 10px;
                                    width: 80%;
                                    padding: 0;
                                    .InnerPercentBar{
                                        width: 90%;
                                        height: 100%;
                                        margin: 0;
                                        border-radius: 0 4px 4px 0;
                                        background: var(--primary-500-base, #3C76F1);
                                    }
                                }
                                span{
                                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                                    font-family: $first-font;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 140%; /* 22.4px */
                                }
                            }
                            p{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; /* 22.4px */
                                margin: 0;
                                margin-top: 32px;
                            }
                        }
                    }
                    .ProfileView{
                        background: #FFF;
                        width: 49%;
                        height: 100%;
                        padding: 16px 0px 30px 16px;
                        box-sizing: border-box;
                        box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
                        .ViewHeading{
                            display: flex;
                            width: 100%;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            margin: 0;
                            h6{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 140%; /* 22.4px */
                                margin: 0;
                                margin-right: 40%;
                            }
                        }
                        .ViewVisibility{
                            display: flex;
                            width: 100%;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            margin-top: 10px;
                            margin-bottom: 30px;
                            p{
                                color: var(--Secondary-200, #9BA6BC);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%;
                                margin: 0;
                                margin-left: 9px;
                            }
                        }
                        .ProfileViews{
                            display: flex;
                            width: 100%;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            margin: 0;
                            margin-bottom: 16px;
                            p{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 140%;
                                margin: 0;
                                margin-left: 9px;
                            }
                        }
                        .SearchAppearances{
                            display: flex;
                            width: 100%;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            margin-bottom: 20px;
                            p{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 140%;
                                margin: 0;
                                margin-left: 9px;
                            }
                        }
                        p{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                            margin: 0;
                        }

                    }
                }
                .AchievementSection{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    height: 466px;
                    h3{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%;
                        margin: 0;
                        margin-bottom: 10px;
                        padding: 0;
                    }
                    .PageDivider{
                        height: 0.5px;
                        width: 100%;
                        background: #9BA6BC;
                        margin-bottom: 10px;
                    }
                    .Verifications{
                        display: flex;
                        flex-direction: row;
                        gap: 16px;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 16px;
                        border-radius: 4px;
                        border: 1px solid var(--Primary-200, #D8E4FC);
                        background: #FFF;
                        margin-bottom: 24px;
                        .IconWrapper{
                            display: flex;
                            width: 48px;
                            height: 48px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 24px;
                            background: var(--Primary-100, #ECF1FE);
                        }
                        p{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                        }
                    }
                }


            }
            .CoachPlan{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 38%;
                margin-top: 0;
                .MentorServices{
                    margin: 0;
                    height: 546px;
                    width: 100%;
                    padding-left: 16px;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    .AreaOfExpertise{
                        h6{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                        }
                        ul{
                            li{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 26px; /* 162.5% */
                                letter-spacing: 0.2px;
                            }
                        }
                    }
                    .MentorshipRoadmap{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        h6{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                            margin: 0;
                        }
                        .RoadMaps{
                            gap: 16px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            .RoadmapsIcons{
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 8px;
                                padding: 0px 8px;
                                .RoadmapIcon{
                                    height: 24px;
                                    width: 24px;
                                    color: #9BA6BC;
                                }
                                p{
                                    color: var(--CoolGray-90, #21272A);
                                    font-family: $first-font;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 140%; /* 22.4px */
                                }
                            }
                        }
                    }
                }
                .ReviewSlide{
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    height: 204px;
                    padding: 12px;
                    background: #FFF;
                    box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
                    .SlideButtons{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        .PrevSlideIcon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #fff;
                            border-radius: 48px;
                            border: #fff 1px solid;
                            cursor: pointer;
                            box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
                            margin-right: 24px;
                        }
                        .NextSlideIcon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #fff;
                            border-radius: 48px;
                            border: #fff 1px solid;
                            cursor: pointer;
                            box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
                        }
                        .Review{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%;
                            margin-right: 173px;
                        }
                        .Count{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 19.6px */
                            margin-right: 32px;
                        }
                    }
                    .ReviewDivider{
                        width: 100%;
                        height: 0.5px;
                        background: var(--Primary-200, #D8E4FC);
                    }
                    .Slide{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        padding: 10px;
                        height: 133px;
                        box-shadow: -4px 6px 6px -4px rgba(108, 107, 107, 0.23);
                        width: 90%;
                        border-radius: 30px;
                        .StarRating{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 8px;
                            margin-bottom: 13px;
                        }
                        p{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 14px;
                            font-style: normal;
                            margin: 0;
                            padding: 0;
                            margin-bottom: 14px;
                            font-weight: 400;
                            line-height: 140%; /* 19.6px */
                        }
                        .Rater{
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            gap: 8px;
                            margin: 0;
                            p{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px; /* 125% */
                            }
                        }
                    }
                }    
                
            }
        }
        .WorkExperience{
            width: 96%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0;
            margin-top: 48px;
            padding: 16px 28px;
            background: #FFF;
            box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
            h3{
                margin: 0;
                padding: 0;
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
            }
            .ExperienceData{
                margin-top: 25px;
                width: 99%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                padding: 16px;
                img{
                    width: 56px;
                    height: 56px;
                    border-radius: 4.667px;
                    margin-right: 9px;
                }
                .WorkDetails{
                    margin: 0;
                    h6{
                        margin: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                        margin-bottom: 7px;
                    }
                    .WorkInfo{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        span:nth-child(1){
                            color: var(--Secondary-200, #9BA6BC);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                        }
                        span:nth-child(3){
                            display: flex;
                            padding: 0px 12px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            border-radius: 4px;
                            background: rgba(60, 118, 241, 0.10);
                            color: var(--Foundation-PRI-pri-500, #2A53A9);
                            font-family: $first-font;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 166.667% */
                        }
                        span:nth-child(5){
                            display: flex;
                            padding: 0px 12px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: var(--Alert-Succes-600, #00B374);
                            font-family: $first-font;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 166.667% */
                            border-radius: 4px;
                            background: rgba(0, 179, 116, 0.10);
                        }
                    }
                }
            }
        }
        .Education{
            width: 96%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0;
            margin-top: 48px;
            padding: 16px 28px;
            background: #FFF;
            box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
            h3{
                margin: 0;
                padding: 0;
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
            }
            .EducationData{
                margin-top: 25px;
                width: 99%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                padding: 16px;
                img{
                    width: 56px;
                    height: 56px;
                    border-radius: 4.667px;
                    margin-right: 9px;
                }
                .EducationDetails{
                    margin: 0;
                    h6{
                        margin: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                        margin-bottom: 7px;
                    }
                    .EducationInfo{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        span:nth-child(1){
                            color: var(--Secondary-200, #9BA6BC);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                        }
                        span:nth-child(3){
                            display: flex;
                            padding: 0px 12px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            border-radius: 4px;
                            background: rgba(60, 118, 241, 0.10);
                            color: var(--Foundation-PRI-pri-500, #2A53A9);
                            font-family: $first-font;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 166.667% */
                        }
                        span:nth-child(5){
                            display: flex;
                            padding: 0px 12px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: var(--Alert-Succes-600, #00B374);
                            font-family: $first-font;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 166.667% */
                            border-radius: 4px;
                            background: rgba(0, 179, 116, 0.10);

                        }
                    }
                }
            }
        }
        .CoachCertification{
            width: 96%;
            margin-top: 32px;
            background: #FFF;
            box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
            padding: 16px;
            box-sizing: border-box;
            @include flex-box($display: flex, $direction: column, $align: flex-start);
            h3{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                margin: 0;
                margin-bottom: 24px;
            }
            .CertificationData{
                border-radius: 4px;
                width: 100%;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                margin-bottom: 16px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 27px;
                .LicenseParticulars{
                    display: flex;
                    flex-direction: row;
                    gap: 24px;
                    img{
                        height: 56px;
                        width: 56px;
                        border-radius: 4.667px;
                    }
                    .CertInfo{
                        h6{
                            margin: 0;
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                        }
                        .CertDetails{
                            span:first-child{
                                color: var(--Secondary-200, #9BA6BC);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 26px; /* 162.5% */
                                letter-spacing: 0.2px;
                                margin-right: 10px;
                            }
                            span:last-child{
                                color: var(--Foundation-PRI-pri-500, #2A53A9);
                                font-family: $first-font;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px; /* 166.667% */
                                border-radius: 4px;
                                padding: 0px 12px;
                                background: rgba(60, 118, 241, 0.10);
                            }
                        }
                    }
                }
                .ShowButton{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 25%;
                    height: 48px;
                    padding: 8px 16px;
                    border-radius: 4px;
                    box-sizing: border-box;
                    gap: 8px;
                    border: 1px solid var(--Primary-700, #305EC1);
                    margin-left: 80px;
                    cursor: pointer;
                    p{
                        color: var(--primary-500-base, #3C76F1);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 125% */
                        margin: 0;
                        width: 145px;
                    }
                    .ButtonIcon{
                        width: 16px;
                        height: 16px;
                        color: #2A53A9;
                    }
                }
            }

        }
    }
}