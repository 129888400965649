@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.JobDetailsContainer{
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .JobDetailsContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .JobDetailsHeading{
            width: 100%;
            @include flex-box($justify: space-between);
            .JobDetailsNavigation{
                @include flex-box();
                gap: 18px;
                cursor: pointer;
                .JobBackIcon{
                    height: 24px;
                    width: 24px;
                    color: #21272A;
                }
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%; /* 26.4px */
                }
            }
            .JobDetailsEdit{
                @include flex-box();
                gap: 5px;
                text-decoration: none;
                cursor: pointer;
                .JobDetailsEditIcon{
                    height: 24px;
                    width: 24px;
                    color: #2E2C2C;
                }
                p{
                    color: var(--Opacity-Opacity-80, rgba(0, 0, 0, 0.80));
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                }
            }
        }
        .JobDetailsCompanyInfo{
            width: 100%;
            @include flex-box($direction: column, $align: flex-start);
            padding: 16px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            background: #FFF;
            h2{
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 108.333% */
                letter-spacing: 0.2px;
                margin: 0;
                padding: 0;
                margin-bottom: 16px;
            }
            .JobDetailsIconInfo{
                @include flex-box();
                gap: 71px;
                span{
                    @include flex-box();
                    gap: 10px;
                    .JobDetailsIcon{
                        height: 24px;
                        width: 24px;
                        color: #9BA6BC;
                    }
                    p{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                    }
                }
            }
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
        }
        .JobDetailsJobInfo{
            width: 100%;
            padding: 16px;
            h4{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 33.6px */
                margin: 0;
                padding: 0;
            }
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
            ul{
                width: 100%;
                li{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }
        }
    }
}