@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CourseDetailsSection{
    @include flex-box($display: flex, $direction: column, $justify: flex-start, $align: flex-start);
    width: 100%;
    gap: 16px;
    padding: 16px;
    box-sizing: border-box;
    margin-top: 42px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid var(--Primary-200, #D8E4FC);
    .CourseDescription{
        width: 100%;
        gap: 16px;
        p:first-child{
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;                
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
        p:last-child{
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
    }
    .CourseDetailsDivider{
        width: 100%;
        height: 0.5px;
        background: #9BA6BC;
    }
    .CourseExpectations{
        width: 100%;
        gap: 16px;
        p{
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            font-family: $first-font;
            box-sizing: border-box;
            li{
                display: flex;
                align-items: baseline;
                margin-bottom: 16px;
            }
        }
        ul li:before {
            content: '✓';
            color: #25A249;
            margin-right: 10px;
        }
    }
    .CourseModules{
        @include flex-box($display: flex, $direction: column, $justify: flex-start, $align: flex-start);
        gap: 16px;
        width: 100%;
        p{
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
        .ModuleContainer{
            @include flex-box($justify: space-between);
            width: 100%;
            border-radius: 4px;
            padding: 16px;
            box-sizing: border-box;
            border: 1px solid var(--Primary-200, #D8E4FC);
            background: var(--Default-White, #FFF);
            .ModuleWrapper{
                span{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 110%; /* 17.6px */
                    margin: 0;
                    padding: 0;
                }
            }
            .CourseModuleIcon{
                height: 24px;
                width: 24px;
                color: #2A53A9;
            }
        }
    }
    .CourseInstructor{
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        width: 30%;
        border-radius: 4px;
        padding: 24px;
        border: 1px solid var(--Primary-200, #D8E4FC);
        .CourseInstructorHeading{
            @include flex-box($justify: space-between);
            width: 100%;
            p{
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
            }
            .InstructorIcon{
                height: 24px;
                width: 24px;
                color: #2A53A9;
                cursor: pointer;
            }
        }
        .CourseInstructorProfile{
            @include flex-box($align: center, $justify: center);
            gap: 9px;
            box-sizing: border-box;
            height: 70px;
            img{
                height: 70px;
                width: 70px;
            }
            .InstructorProfileDetails{
                @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: center);
                box-sizing: border-box;
                h5{
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    padding: 0;
                    margin: 0;
                }
                h6{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px; /* 185.714% */
                    letter-spacing: 0.2px;
                    padding: 0;
                    margin: 0;
                }
                .InstructorRating{
                    span{
                        color: #000;
                        font-family: $first-font;
                        font-size: 10.24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16.64px; /* 162.5% */
                        letter-spacing: 0.128px;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }

}