@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.JsAppliedContainer {
  @include container($width: 90%);
  position: relative;
  margin-top: 1.5rem;
  margin-left: 9%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: hidden;
  .JsAppliedPopOvers{
    display: flex;
    .JsAppliedSkin{
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 30%;
      background-color: rgba(191,191,191, 0.3);
      z-index: 12;
    }
    .JsAppliedPopOver{
    position: fixed;
    top: 0;
    left: 30%;
    height: 100vh;
    width: 70%;
    background-color: white;
    z-index: 12;
    padding: 0;
    margin: 0;
  }

  }
  
  .JsAppliedFindCourse {
    width: 100%;
    display: grid;
    grid-template-columns: 7fr 1fr;
    gap: 30px;
    .JsSearchBar {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .JsProfileSearch {
        color: #9ba6bc;
        width: 24px;
        height: 24px;
        transform: translate(10px);
      }
      .JsProfileInputSearch {
        border-radius: 4px 0px 0px 4px;
        border: 0.5px solid #d8e4fc;
        width: 100%;
        height: 40px;
        background-color: transparent;
        color: var(--secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding-left: 45px;
        transform: translate(-22px);
      }
    }
    .AppliedFindCourse {
      border-radius: 4px;
      background: #2a53a9;
      border: none;
      color: white;
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
  }
  .AppliedLinks {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 13fr;
    a {
      color: var(--primary-90, #9ba6bc);
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
      padding-bottom: 6px;
      border-bottom: solid 0.5px #d8e4fc;
    }
    a:last-child {
      margin-right: 200px;
    }
  }
  .JsAppliedCategory {
    
    margin-top: 30px;
    .JsAppliedTabs {
      width: 99.5%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      align-items: flex-start;
      .JsAppliedTabsData {
        text-decoration: none;
        width: 99.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        border: 1px solid var(--primary-200, #d8e4fc);
        .ExperienceData {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          .JsAppliedPic {
            margin-left: 13px;
          }
          .JsAppliedDataInfo {
            height: auto;
            h5 {
              color: var(--foundation-neutral-black, #2e2c2c);
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
            }
            #JsAppliedDataParagOne {
              color: #2a53a9;
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              margin-top: -1rem;
            }
            #JsAppliedDataParagTwo {
              color: var(--secondary-200, #9ba6bc);
              font-family: TT Wellingtons Trl;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              margin-top: -0.5rem;
              span {
                border-radius: 4px;
                background: rgba(255, 187, 56, 0.1);
                color: var(--alert-warning-500-base, #ffbb38);
                font-family: $first-font;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                padding: 3px;
              }
            }
          }
        }
        .EditApplied {
          margin-right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          a {
            color: var(--secondary-200, #9ba6bc);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            text-decoration: none;
          }
          .EditAppliedIcon {
            transform: translateY(0.2rem);
          }
        }
      }
    }
    .JsJobsPagination {
      @include flex-box($display: flex, $direction: row, $justify: space-between, $align: center);
      width: 99%;
      margin-bottom: 3rem;
      border-radius: 4px;
      // position: abosolute;
      margin-top: 20vh;
      border: 1px solid var(--primary-200, #d8e4fc);
      background: var(
        --black-and-white-white,
        linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
        #fff
      );
      p {
        color: var(--secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-left: 40px;
      }
      div {
        margin-right: 1rem;
      }
    }
  }
}
