@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.ProfileDetailContainer {
	width: 100%;
	display: flex;
	box-sizing: content-box;
	.ProfileDetailInnerContainer {
		@include container($width: 100%);
		overflow-y: scroll;
		padding: 0;
		margin: 0;
		@include flex-box(
			$justify: flex-start,
			$direction: column,
			$align: flex-start
		);
		.ProfilePhotoSection {
			@include container($width: auto);
			position: relative;
			margin-left: 3rem;
			@include flex-box(
				$justify: flex-start,
				$direction: column,
				$align: flex-start
			);
			.ProfilePhotoHeader {
				p {
					font-family: $first-font;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					line-height: 110%;
					color: #2e2c2c;
				}
			}
			.ProfilePhotoHandler {
				@include flex-box(
					$justify: space-between,
					$direction: row,
					$align: center
				);
				gap: 80px;
				.ProfileImageLoader {
					@include flex-box(
						$justify: flex-start,
						$direction: row,
						$align: center
					);
					gap: 20px;
					.ProfileImageSpace {
						margin-left: 20px;
						width: 120px;
						height: 120px;
						img {
							border-radius: 100%;
							border: none;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.ProfileImageType {
						@include flex-box(
							$justify: center,
							$direction: row,
							$align: center
						);
						input[type="file"] {
							background-color: white;
							display: flex;
							height: 38px;
							width: 120px;
							justify-content: center;
							align-items: center;
							border-radius: 4px;
							border: 1px solid #2a53a9;
							position: relative;
							z-index: 5;
							color: transparent;
							opacity: 0;
							cursor: pointer;
						}
						.ProfileImageUpload {
							position: relative;
							display: flex;
							height: 38px;
							width: 120px;
							padding: 8px 12px;
							justify-content: center;
							align-items: center;
							border-radius: 4px;
							border: 1px solid #2a53a9;
							background-color: white;
							font-family: $first-font;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							color: #2a53a9;
							transform: translateX(-121px);
							cursor: pointer;
							z-index: 2;
						}
						.ProfileImageRemove {
							color: #2a53a9;
							font-family: $first-font;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							background-color: white;
							border: none;
							cursor: pointer;
							transform: translateX(-121px);
						}
					}
				}
				.ProfileImageRequirement {
					border-left: solid 1px #80a0e4;
					padding-left: 35px;
					padding-top: 0px;
					p {
						color: var(
							--foundation-neutral-black,
							#2e2c2c
						);
						font-family: $first-font;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 22.4px */
					}
					ol {
						li {
							color: var(
								--foundation-neutral-black,
								#2e2c2c
							);
							font-family: $first-font;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							text-transform: capitalize;
						}
					}
				}
			}
		}
		.ProfileLineBreaker {
			width: 99%;
			border: solid 0.5px #dae0ee;
			margin-top: 1.5rem;
		}
		.ProfileDetailSection {
			@include container($width: 98%);
			margin-left: 2%;
			overflow-x: hidden;
			display: flex;
			flex-direction: column;
			.ProfileDetailHeader {
				h3 {
					font-family: $first-font;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					color: #2e2c2c;
				}
			}
			.ProfileDetailForm {
				@include container($width: 97.5%);
				@include flex-box(
					$justify: flex-start,
					$direction: row,
					$align: flex-start
				);
				margin-bottom: 30px;
				gap: 2%;
				flex-wrap: wrap;
				#ProfileTextField {
					display: flex;
					flex-direction: column;
					width: 48%;
					gap: 5px;
					justify-content: flex-start;
					align-items: flex-start;
					.ProfileTextField {
						display: flex;
						flex-direction: column;
						height: 45px;
						align-items: center;
						gap: 3px;
						border-radius: 4px;
						border: 0.5px solid #d8e4fc;
						margin-top: 1px;
						padding-left: 10px;
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
					}
					label {
						color: var(--cool-gray-90, #21272a);
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
					}
				}
				#ProfileInputFieldOne {
					display: flex;
					flex-direction: column;
					gap: 5px;
					justify-content: flex-start;
					align-items: flex-start;
					width: 99%;
					.ProfileInputFieldOne {
						display: flex;
						height: 45px;
						width: 99.5%;
						justify-content: center;
						padding-left: 10px;
						align-items: center;
						border-radius: 4px;
						border: 0.5px solid #d8e4fc;
						color: #9ba6bc;
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
					}
					label {
						color: var(--cool-gray-90, #21272a);
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
					}
				}
				.ProfileSelectField {
					display: flex;
					flex-direction: row;
					gap: 1.5%;
					width: 100%;
					justify-content: flex-start;
					align-items: center;
					#ProfileSelectFieldTwo {
						display: flex;
						flex-direction: column;
						gap: 5px;
						justify-content: flex-start;
						align-items: flex-start;
						width: 49%;
						label {
							color: var(--cool-gray-90, #21272a);
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
						}
						.ProfileSelectFieldTwo {
							display: flex;
							height: 46px;
							width: 100%;
							align-items: center;
							flex-direction: column;
							border-radius: 4px;
							border: 0.5px solid #d8e4fc;
							padding: 10px;
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							color: #21272a;
							
						}
					}
				}

				// Suggestion Area
				.suggestionsList {
					display: flex;
					flex-direction: column;
					gap: 5px;
					background-color: white;
					width: 50%;
					height: 400px;
					overflow-y: scroll;
					position: absolute;
					margin-top: 14%;
					box-shadow: 2px 3px 3px
						rgba(0, 0, 0, 0.2);
					list-style: none;
					padding: 0 12px;
					li {
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						&:hover {
							color: white;
							padding: 10px 0;
							width: 100%;
							background-color: #2a53a9;
							cursor: pointer;
						}
					}
				}
				.TextArea{
					width: 100%;
					display: flex; 
					flex-direction: column;
					gap: 2px;
					.wordCount{
						span{
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							color: #21272a;
							transform: translateX(-15px);
						}
					}

				}
				#ProfileTextArea {
					display: flex;
					flex-direction: column;
					gap: 5px;
					width: 100%;
					justify-content: flex-start;
					align-items: flex-start;
					label {
						color: var(--cool-gray-90, #21272a);
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
					}
					.ProfileTextArea {
						width: 97.5%;
						height: 200px;
						flex-direction: column;
						gap: 5px;
						border-radius: 4px;
						border: 0.5px solid#D8E4FC;
						padding: 10px;
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						resize: none;
					}
				}
				.ProfileSaveButton {
					display: flex;
					margin-left: 99%;
					transform: translateX(30%);
					justify-content: center;
					align-items: center;
					background-color: white;
					position: relative;
					border-radius: 4px;
					color: #2a53a9;
					border: 2px solid #2a53a9;
					width: 100px;
					height: 35px;
					cursor: pointer;
					@include text(
						$font-size: 14px,
						$color: #9ba6bc,
						$font-weight: 700,
						$font-family: $first-font,
						$text-align: justify,
						$line-height: normal
					);
				}
				.ProfileDivider {
					width: 100%;
					border: solid 1px #d1d4db;
					margin-left: -1rem;
					margin-bottom: 20px;
					margin-top: 30px;
				}
				.ProfileExperiences {
					margin-top: 10px;
					width: 100%;
					.ProfileExperienceHeader {
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						h2 {
							color: var(
								--foundation-neutral-black,
								#2e2c2c
							);
							font-family: $first-font;
							font-size: 24px;
							font-style: normal;
							font-weight: 600;
						}
						a {
							display: flex;
							flex-direction: row;
							gap: 10px;
							justify-content: center;
							align-items: center;
							text-decoration: none;
							p {
								color: var(
									--foundation-pri-pri-500,
									#2a53a9
								);
								font-family: $first-font;
								font-size: 16px;
								font-style: normal;
								font-weight: 500;
							}
							.ProfileAddExperience {
								color: var(
									--foundation-pri-pri-500,
									#2a53a9
								);
								width: 20px;
								height: 20px;
							}
						}
					}
					.ProfileExperienceTagNames {
						width: 99.5;
						display: flex;
						flex-direction: column;
						gap: 20px;
						justify-content: flex-start;
						align-items: flex-start;
						.AddExperienceData {
							width: 99.5%;
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							border-radius: 4px;
							border: 1px solid
								var(--primary-200, #d8e4fc);
							.ExperienceData {
								display: flex;
								flex-direction: row;
								justify-content: center;
								align-items: center;
								gap: 10px;
								span:first-child {
									margin-left: 13px;
								}
								span:last-child {
									display: flex;

									flex-direction: column;
									justify-content: flex-start;
									align-items: flex-start;
									gap: 3px;
									border: none;
									margin-left: 0rem;
									h5 {
										color: var(
											--foundation-neutral-black,
											#2e2c2c
										);
										font-family: $first-font;
										font-size: 16px;
										font-style: normal;
										font-weight: 600;
										margin-bottom: -1rem;
									}
									div {
										display: flex;
										flex-direction: row;
										justify-content: center;
										align-items: center;
										gap: 15px;
										flex-wrap: nowrap;

										p:nth-child(1) {
											color: var(
												--secondary-200,
												#9ba6bc
											);
											font-family: $first-font;
											font-size: 16px;
											font-style: normal;
											font-weight: 500;
										}
										p:nth-child(2) {
											color: var(
												--foundation-pri-pri-500,
												#2a53a9
											);
											font-family: $first-font;
											font-size: 12px;
											font-style: normal;
											font-weight: 600;
											border-radius: 4px;
											background: rgba(
												60,
												118,
												241,
												0.1
											);
											padding: 5px;
											display: flex;
											justify-content: center;
											white-space: none;
											min-width: 70px;
										}
										p:nth-child(3) {
											color: var(
												--alert-succes-600,
												#00b374
											);
											font-family: $first-font;
											font-size: 12px;
											font-style: normal;
											font-weight: 600;
											border-radius: 4px;
											background: rgba(
												0,
												179,
												116,
												0.1
											);
											display: flex;
											justify-content: center;
											white-space: none;
											min-width: 70px;
											padding: 5px;
										}
									}
								}
							}
							div {
								margin-right: 20px;
							}
						}
					}
					.ProfileExperienceButton {
						button {
							display: flex;
							margin-left: 90.1%;
							margin-top: 20px;
							justify-content: center;
							align-items: center;
							background-color: white;
							position: relative;
							border-radius: 4px;
							color: #2a53a9;
							border: 2px solid #2a53a9;
							width: 100px;
							height: 35px;
							@include text(
								$font-size: 14px,
								$color: #9ba6bc,
								$font-weight: 700,
								$font-family: $first-font,
								$text-align: justify,
								$line-height: normal
							);
						}
					}
				}
				.ImportantSite {
					margin-top: 10px;
					width: 100%;
					display: flex;
					gap: 10px;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-end;
					.ImportantSiteHeader {
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						h2 {
							color: var(
								--foundation-neutral-black,
								#2e2c2c
							);
							font-family: $first-font;
							font-size: 24px;
							font-style: normal;
							font-weight: 600;
						}
						a {
							display: flex;
							flex-direction: row;
							gap: 10px;
							justify-content: center;
							align-items: center;
							text-decoration: none;
							p {
								color: var(
									--foundation-pri-pri-500,
									#2a53a9
								);
								font-family: $first-font;
								font-size: 16px;
								font-style: normal;
								font-weight: 500;
							}
							.ProfileAddExperience {
								color: var(
									--foundation-pri-pri-500,
									#2a53a9
								);
								width: 20px;
								height: 20px;
							}
						}
					}
					
					#AddLinksTextField {
						display: flex;
						flex-direction: column;
						width: 100%;
						gap: 5px;
						justify-content: flex-start;
						align-items: flex-start;
						label {
							color: var(--cool-gray-90, #21272a);
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
						}
						.AddLinksTextField {
							display: flex;
							flex-direction: column;
							gap: 5px;
							height: 42px;
							width: 97%;
							justify-content: center;
							padding-left: 10px;
							align-items: center;
							border-radius: 4px;
							border: 0.5px solid #d8e4fc;
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							color: #21272a;
						}
					}
					.HandleSaveLinksButton {
						display: flex;
						margin-left: 90.1%;
						margin-top: 20px;
						justify-content: center;
						align-items: center;
						background-color: white;
						position: relative;
						border-radius: 4px;
						color: #2a53a9;
						border: 2px solid #2a53a9;
						width: 100px;
						height: 35px;
						@include text(
							$font-size: 14px,
							$color: #9ba6bc,
							$font-weight: 700,
							$font-family: $first-font,
							$text-align: justify,
							$line-height: normal
						);
					}
				}
				.HandleProceedButton {
					margin-top: 30px;
					button {
						width: 1100px;
						height: 40px;
						border-radius: 4px;
						border: 2px solid #2a53a9;
						color: white;
						background: #2a53a9;
						margin-bottom: 35px;
					}
				}
			}
		}
	}
}

.JsWelcomeCard {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(144, 203, 232, 0.139);
	backdrop-filter: blur(10px);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 15;
	.WelcomeCardContainer {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		width: 33%;
		min-height: 260px;
		padding: 10px 20px 30px 20px;
		box-shadow: 3px 3px 3px rgb(247, 249, 252);
		justify-content: center;
		align-items: center;

		.WelcomeIcon {
			padding: 20px;
			width: 20px;
			height: 20px;
			background-color: #d8e4fc;
			border-radius: 100%;
			color: #2a53a9;
			margin-bottom: 20px;
		}
		h1 {
			color: #21272a;
			text-align: center;
			font-family: $first-font;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
		}
		p {
			color: #2e2c2c;
			text-align: center;
			font-family: $first-font;
			font-size: 16px;
			font-style: normal;
			margin-bottom: 30px;
			margin-top: -15px;
		}
		button {
			border-radius: 6.242px;
			border: 3.121px solid #2a53a9;
			background: #2a53a9;
			width: 94%;
			height: 48px;
			color: var(--Default-White, #fff);
			font-family: $first-font;
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			cursor: pointer;
		}
	}
}
