@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.SettingsNavBarContainer{
    @include container;
    @include flex-box($justify: flex-start, $align: center);
    position: fixed;
    z-index: 10;
    margin-bottom: 1rem;
    padding: 10px 0;
    overflow-y: visible;
    box-shadow: -4px 4px 6px -4px rgba(42, 83, 169, 0.05);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%), #fff;
    .NavBarLogo{
        margin-left: 48px;
        @include flex-box($justify: flex-start);
        width: 30%;
    }
    .NavBarSearchArea{
        width: 70%;
        @include flex-box($justify: flex-start);
        margin-right: 25px;
        height: 100%;
        .NavBarSearchIcon{
            position: absolute;
            width: 24px;
            height: 24px;
            margin-left: 1rem;
            color: #9BA6BC;
        }
        .NavBarInputField{
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            padding: 16px 24px;
            height: 100%;
            width: 615px;
            box-sizing: border-box;
            padding-left: 50px;    
        }   
        .NavBarInputField::placeholder{
            color: var(--Secondary-200, #9BA6BC);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
    }
}