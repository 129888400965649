@import "../styles/variables.styles.scss";

@mixin container($width: 100%, $overflowY: hidden, $height: auto, $padding-left: 0, $padding-right: 0  ) {
  width: $width;
  overflow-y: $overflowY;
  height: $height;
  padding-left: $padding-left;
  padding-right: $padding-right;
}
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-box($display: flex, $direction: row, $justify: center, $align: center) {
  display: $display;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin grid-box(
    $column: auto,
    $row: auto,
    $column-gap: 1rem,
    $row-gap: 1rem,
    $justify: stretch,
    $align: stretch,
){
    display: grid;
    grid-template-columns: $column;
    grid-template-rows: $row;
    grid-column-gap: $column-gap;
    grid-row-gap: $row-gap;
    justify-content: $justify;
    align-items: $align;

}

@mixin text(
  $font-size: 24px,
  $color: $first-text,
  $font-weight: 20px,
  $font-family: "Helvetica Neue",
  $text-align: justify,
  $line-height: normal,
) {
  font-size: $font-size;
  color: $color;
  font-weight: $font-weight;
  font-family: $font-family;
  text-align: $text-align;
  line-height: $line-height;
}

@mixin border($style, $size, $color, $radius){
    border: $style $size $color;
    border-radius: $radius;
}

@mixin box-shadow($x-offset, $y-offset, $blur, $color){
    box-shadow: $x-offset $y-offset $blur $color;
}

@mixin responsive($min-width, $max-width){
    @media ($min-width) and (max-width) {
        @content;
    }
}
@mixin small-breakpoint{
  @media (max-width: $breakpoint-small) {
    @content
    
  }
}
@mixin medium-breakpoint{
  @media (min-width: $breakpoint-small-middle) and (max-width: $breakpoint-medium) {
    @content
  }
};