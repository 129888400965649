@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.FilledQuizSection{
    margin-top: 42px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--Primary-200, #D8E4FC);
    @include flex-box($display: flex, $direction: column, $justify: flex-start, $align: flex-start);
    margin-bottom: 15px;
    .FilledQuizAccordionWrapper{
        width: 100%;
        gap: 16px;
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        .FilledQuizAccordion{
            width: 100%;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            background: var(--Default-White, #FFF);
            padding: 16px;
            box-sizing: border-box;
            .FilledQuizModuleName{
                @include flex-box($justify: space-between);
                width: 100%;
                height: 24px;
                box-sizing: border-box;
                cursor: pointer;
                h3{
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                }
                .FilledQuizAccordionIcon{
                    height: 24px;
                    width: 24px;
                    color: #2A53A9;
                }
                .rotate{
                    transform: rotate(180deg);
                }
            }
            .FilledQuizDetails{
                @include flex-box($display: flex, $direction: column, $justify: flex-start, $align: flex-start);
                width: 100%;
                gap: 23px;
                max-height: 0;
                overflow: hidden;
                transition: all 0.5s cubic-bezier(0,1,0,1);
                .FilledQuizArea{
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid var(--Primary-200, #D8E4FC);
                    background: #FFF;
                    padding: 16px;
                    gap: 4px;
                    @include flex-box($display: flex, $direction: column, $justify: flex-start, $align: flex-start);
                    h3{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                        margin: 0;
                        padding: 0;
                    }
                    .FilledQuizOptions{
                        gap: 4px;
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        box-sizing: border-box;
                        li{
                            display: flex;
                            justify-content: flex-start;
                            align-items: baseline;
                            label{
                                color: var(--Secondary-200, #9BA6BC);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 26px; /* 162.5% */
                                letter-spacing: 0.2px;
                                input{
                                    margin-right: 10px;
                                }
                                input[type="radio"]:checked + label{
                                    border-color: #2A53A9;
                                    background-color: #2A53A9;
                                    color: #2A53A9;
                                }
                            }
                        }
                    }
                }
            }
            .FilledQuizDetails.show{
                margin-top: 20px;
                height: auto;
                max-height: 9999px;
                transition: all 0.5s cubic-bezier(0,1,0,1);
                padding: 16px;
                box-sizing: border-box;
            }
        }
    }
}