@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.GradingModalContainer{
    margin: 0;
    margin-top: 25px;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .GradingModal{
        @include flex-box($display: flex, $direction: column, $justify: flex-start);
        background: #FFF;
        width: 714px;
        height: 900px;
        .GradingTopModalSection{
            @include flex-box;
            width: 100%;
            gap: 445px;
            padding: 80px 40px 28px 40px;
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%; /* 22px */
            }
            .GradingModalCloseIcon{
                height: 24px;
                width: 24px;
                color: #CC3333;
                cursor: pointer;
            }
        }
        .GradingBottomModalSection{
            @include flex-box($display: flex, $direction: row, $justify: center, $align: flex-start);
            gap: 24px;
            width: 100%;
            margin-bottom: 24px;
            .ModalAvatarWrapper{
                height: 200px;
                width: 200px;
                border-radius: 200px;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .MenteeModalDetails{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 32px;
                width: 410px;
                h3{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 26.4px */
                    margin: 0;
                    padding: 0;
                }
                .MenteeParticulars{
                    @include flex-box($display: flex, $direction: row, $justify: center, $align: flex-start);
                    width: 100%;
                    gap: 24px;
                    .ParticularsKeys{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 16px;
                        p{
                            color: var(--CoolGray-100, #121619);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 140%; /* 22.4px */
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .ParticularsValues{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 16px;
                        p{
                            margin: 0;
                            padding: 0;
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                            span{
                                color: var(--alert-succes-500-base, #00C781);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%;
                            }
                        }
                        .MenteeRecommendations{
                            width: 202px;
                            height: 64px;
                        }
                    }
                }
            }
        }
        .ModalFormArea{
            width: 100%;
            margin-bottom: 70px;
            form{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 32px;
                margin-left: 40px;
                margin-right: 40px;
                .GradingTextField{
                    width: 100%;
                    height: 48px;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 0.5px solid #D8E4FC;
                }
                #GradingTextField{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    label{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
                .RecommendationTextField{
                    width: 100%;
                    height: 48px;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 0.5px solid #D8E4FC;
                }
                #RecommendationTextField{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    label{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
                .FormButtons{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .GradingModalButton{
                        border-radius: 4px;
                        border: 1px solid var(--Black-And-White-White, #FFF);
                        background: var(--Foundation-PRI-pri-500, #2A53A9);
                        width: 148px;
                        height: 57px;
                        padding: 16px;
                        color: var(--Black-And-White-White, #FFF);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                    }
                    .CancelButton{
                        background-color: transparent;
                        border-radius: 4px;
                        border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                        width: 148px;
                        height: 57px;
                        padding: 16px;
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                    }
                }
            }
        }
    }
}