@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.FilledResourcesSection{
    margin-top: 42px;
    width: 100%;
    @include flex-box($display: flex, $direction: column, $justify: flex-start, $align: flex-start);
    padding: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--Primary-200, #D8E4FC);
    .FilledResourcesAccordionWrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;
        width: 100%;
        .FilledResourcesAccordion{
            width: 100%;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            background: var(--Default-White, #FFF);
            .FilledModuleHeading{
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                @include flex-box($justify: space-between);
                h3{
                    margin: 0;
                    padding: 0;
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                }
                .FilledModuleAccordionIcon{
                    height: 24px;
                    width: 24px;
                    color: #2A53A9;
                }
                .rotate{
                    transform: rotate(180deg);
                }
            }
            .FilledModuleDetails{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                gap: 16px;
                max-height: 0;
                overflow: hidden;
                transition: all 0.5s cubic-bezier(0,1,0,1);
                .FilledModuleResource{
                    @include flex-box($justify: space-between);
                    .IconArea{
                        @include flex-box($justify: flex-start);
                        gap: 9px;
                        .IconWrapper{
                            @include flex-box();
                            width: 56px;
                            height: 56px;
                            border-radius: 28px;
                            background: var(--Primary-100, #ECF1FE);
                            .FilledIcon{
                                width: 24px;
                                height: 24px;
                                color: #2A53A9;
                            }
                        }
                        .ModuleDetails{
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            h6{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 26px; /* 162.5% */
                                letter-spacing: 0.2px;
                                padding: 0;
                                margin: 0;
                            }
                            p{
                                color: var(--Secondary-200, #9BA6BC);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 26px; /* 162.5% */
                                letter-spacing: 0.2px;
                                padding: 0;
                                margin: 0;
                            }
                        }
                    }
                }
                .FilledResourcesDivider{
                    width: 100%;
                    height: 0.5px;
                    background: #9BA6BC;
                }
            }
            .show.FilledModuleDetails{
                height: auto;
                max-height: 9999px;
                transition: all 0.5s cubic-bezier(0,1,0,1);
                padding: 16px;
                box-sizing: border-box;
            }
        }
    }
}