.EventCard{
  height: fit-content;
  width: 96%;
  EventHeader{
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      color: var(--Dark-Base-Base-Black, #2C2C2C);
      font-family: "TT Wellingtons Trl";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    .Accordion{
      width: 20px;
      height: 20px;
      color: var(--Dark-Base-Base-Black, #2C2C2C);
    }
  }
}
// Example SCSS code for customizing FullCalendar

.myCustomCalendar {
  border: 1px solid #ccc;
  border-radius: 5px;

  // Customize the header
  .fc-header-toolbar {
    background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;
  }

  // Customize the day cells
  .fc-daygrid-day {
    background-color: #221e95;
  }

}
