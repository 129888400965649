@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.MenteesModalContainer{
    margin: 0;
    padding: 0;
    position: fixed;
    top: - 20%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .MenteesModal{
        width: 2102px;
        background: var(--Default-White, #FFF);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 40px;
        padding: 20px;
        margin: 10px;
        border-radius: 4px;
        .MenteeModalIconWrapper{
            padding: 0;
            display: flex;
            justify-content: flex-start;
            cursor: pointer;
            .ModalBackIcon{
                height: 24px;
                width: 24px;
                color: #21272A;
            }
        }
        .MenteeModalPaper{
            width: 100%;
            .MenteeModalTableContainer{
                width: 100%;
                .MenteeModalTable{
                    width: 100%;
                    .MenteeModalTableHead{
                        width: 100%;
                        border-radius: 4px 4px 0px 0px;
                        background: var(--Primary-100, #ECF1FE);
                        padding: 16px 0px;
                        .MenteeModalTableRow{
                            width: 100%;
                            .MenteeModalTableCell{
                                color: var(--CoolGray-100, #121619);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 140%; /* 19.6px */
                            }
                        }
                    }
                    .MenteeModalTableBody{
                        width: 100%;
                        .MenteeModalTableBodyrow{
                            width: 100%;
                            border: 0.5px solid var(--Secondary-200, #9BA6BC);
                            background: rgba(255, 255, 255, 0.00);
                            padding: 16px 0px;
                            .MenteeModalTableBodyCell{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; /* 22.4px */
                                .MessageButton{
                                    button{
                                        border-radius: 4px;
                                        border: 2px solid var(--Foundation-PRI-pri-300, #708CC5);
                                        padding: 16px;
                                        background-color: transparent;
                                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                                        font-family: $first-font;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 26px; /* 162.5% */
                                        letter-spacing: 0.2px;
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }

    }
}
