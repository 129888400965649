@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.RecruiterHomePageContainer{
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .RecruitmentHomePageContent{
        width: 100%;
        .EmptyDataSection{
            width: 100%;
            @include flex-box($direction: column, $align: flex-start);
            gap: 54px;
            .HomeTopSection{
                @include flex-box($justify: space-between);
                width: 100%;
                .TopSectionBox{
                    @include flex-box($direction: column, $align: flex-start);
                    padding: 16px;
                    box-sizing: border-box;
                    width: 24%;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    background: var(--Default-White, #FFF);
                    p:first-child{
                        color: var(--CoolGray-60, #697077);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                        padding: 0;
                        margin: 0;
                    }
                    p:last-child{
                        color: var(--CoolGray-90, #21272A);
                        font-family: $first-font;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%; /* 26.4px */
                        padding: 0;
                        margin: 0;
                    }
                }
            }
            .HomeBottomSection{
                @include flex-box($direction: column);
                width: 100%;
                height: 652px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                gap: 16px;
                p{
                    padding: 0;
                    margin: 0;
                    color: #000;
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                }
                button{
                    color: var(--Black-And-White-White, #FFF);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    padding: 8px 16px;
                    border: none;
                    border-radius: 4px;
                    background: var(--Foundation-PRI-pri-500, #2A53A9);
                }
            }
        }
        .FilledDataSection{
            width: 100%;
            @include flex-box($direction: column, $align: flex-start);
            gap: 34px;
            .HomeTopSection{
                @include flex-box($justify: space-between);
                width: 100%;
                .TopSectionBox{
                    @include flex-box($direction: column, $align: flex-start);
                    padding: 16px;
                    box-sizing: border-box;
                    width: 24%;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    background: var(--Default-White, #FFF);
                    p:first-child{
                        color: var(--CoolGray-60, #697077);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                        padding: 0;
                        margin: 0;
                    }
                    p:last-child{
                        color: var(--CoolGray-90, #21272A);
                        font-family: $first-font;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%; /* 26.4px */
                        padding: 0;
                        margin: 0;
                    }
                }
            }
            .LineChartSection{
                width: 100%;
                gap: 32px;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05);
                @include flex-box($direction: column, $align: flex-start);
                .LineChartHeading{
                    width: 100%;
                    @include flex-box($justify: space-between);
                    h6{
                        padding: 0;
                        margin: 0;
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-family: $first-font;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%; /* 22px */
                    }
                    .LineChartButtons{
                        width: 55%;
                        @include flex-box($justify: space-between);
                        .LineChartKeys{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            span:nth-child(2){
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; /* 19.6px */
                            }
                            span:nth-child(4){
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; /* 19.6px */
                            }
                        }
                        button{
                            padding: 12px 16px;
                            height: 48px;
                            width: 213px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            background: transparent;
                            border: 0.5px solid var(--Foundation-PRI-pri-500, #2A53A9);
                            select{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; /* 19.6px */
                                width: 100%;
                                outline: none;
                                border: none;
                            }
                        }
                    }
                }
                .HomeLineChart{
                    width: 100%;
                }
            }
            .HomeBottomSection{
                width: 100%;
                @include flex-box($justify: space-between);
                .HomeJobBox{
                    @include flex-box($direction: column, $align: flex-start);
                    width: 32.5%;
                    gap: 16px;
                    padding: 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 1px solid var(--Primary-200, #D8E4FC);
                    p:nth-child(1){
                        color: var(--CoolGray-90, #21272A);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                        padding: 0;
                        margin: 0;
                    }
                    h2{
                        color: var(--CoolGray-90, #21272A);
                        font-family: $first-font;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%; /* 22px */
                        padding: 0;
                        margin: 0;
                    }
                    .Location, .Type{
                        color: var(--Secondary-200, #9BA6BC);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                        margin: 0;
                        padding: 0;
                    }
                    .Status{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 19.6px */
                        padding: 2px 12px;
                        margin: 0;
                        border-radius: 4px;
                        background: var(--CoolGray-10, #F2F4F8);

                    }
                    .TotalApplicants{
                        @include flex-box($justify: space-between);
                        width: 100%;
                        p{
                            color: var(--CoolGray-60, #697077);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                            padding: 0;
                            margin: 0;
                        }
                        span{
                            color: var(--CoolGray-90, #21272A);
                            font-family: $first-font;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%; /* 26.4px */
                        }
                    }
                    button{
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 22.4px */
                        height: 48px;
                        width: 100%;
                        padding: 16px 12px;
                        background: transparent;
                        border-radius: 4px;
                        border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                    }
                }

            }
        }
    }
}