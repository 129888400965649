@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.AddResourceContainer {
	margin-top: 42px;
	width: 100%;
	padding: 16px;
	box-sizing: border-box;
	border-radius: 4px;
	border: 1px solid var(--Primary-200, #d8e4fc);
	@include flex-box(
		$display: flex,
		$direction: column,
		$justify: flex-start,
		$align: flex-start
	);
	margin-bottom: 15px;
	.CourseDetails {
		width: 98%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		justify-content: flex-start;
		align-items: flex-start;
		h1 {
			color: #2a53a9;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
		}
		.TextField {
			width: 99%;
			height: 200px;
			border-radius: 4px;
			border: 1px solid
				var(--Primary-200, #d8e4fc);
			display: flex;
			justify-content: flex-start;
			padding-left: 12px;
			padding-top: 12px;
			word-wrap: break-word;
			color: #9ba6bc;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			resize: none;
		}
	}
	.LineBreaker {
		border: solid 0.5px #d8e4fc;
		width: 98%;
		margin: 10px 0 10px 0;
	}
	.CourseExpectation {
		width: 98%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		justify-content: flex-start;
		align-items: flex-start;
		h1 {
			color: #2a53a9;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			margin-bottom: -18px;
		}
		h3 {
			color: var(--Secondary-200, #9ba6bc);
			font-family: "TT Wellingtons Trl";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
		}
		.TextField {
			width: 99%;
			height: 200px;
			border-radius: 4px;
			border: 1px solid
				var(--Primary-200, #d8e4fc);
			display: flex;
			justify-content: flex-start;
			padding-left: 12px;
			padding-top: 12px;
			word-wrap: break-word;
			color: #9ba6bc;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			resize: none;
		}
	}
	.CourseModule {
		width: 98%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		justify-content: flex-start;
		align-items: flex-start;
		h1 {
			color: #2a53a9;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
		}
		.CourseModuleWrapper {
			width: 98%;
			padding: 0 12px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 4px;
			border: 1px solid
				var(--Primary-200, #d8e4fc);
			p {
				color: var(
					--Foundation-Neutral-Black,
					#2e2c2c
				);
				font-family: "TT Wellingtons Trl";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
			}
			.CourseModuleIcon {
				color: #2a53a9;
				width: 20px;
				height: 20px;
			}
		}
	}
	.CourseInstructor {
		width: 95.3%;
		display: flex;
		flex-direction: column;
		border-radius: 4px;
		border: 1px solid var(--Primary-200, #d8e4fc);
		gap: 12px;
		padding: 16px;
		margin-top: 40px;
		h1 {
			color: #2a53a9;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
		}
		.CourseInstructorProfileWrapper {
			width: 98%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 2%;
			.CourseInstructorProfile {
				width: 43%;
				border-right: solid 1px #f2f4f8;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.CourseImage {
					background-color: #f2f4f8;
					border-radius: 100%;
					padding: 40px;
					width: 20px;
					height: 20px;
					color: #9ba6bc;
				}
				.CourseImageInput {
					width: 120px;
					height: 40px;
					transform: translateX(35px);
					border: solid 1px;
					z-index: 15;
					position: relative;
					margin-left: -30px;
					opacity: 0;
				}
				#UploadPhoto {
					border-radius: 4px;
					border: 1px solid #2a53a9;
					color: #2a53a9;
					font-family: "TT Wellingtons Trl";
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					height: 40px;
					background-color: transparent;
					margin-left: -85px;
					padding: 0 20px;
				}
				.RemovePhoto {
					color: #2a53a9;
					font-family: "TT Wellingtons Trl";
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					border: none;
					height: 40px;
					background-color: transparent;
				}
			}
			.CourseInstructorCondition {
				padding-left: 40px;
				display: flex;
				flex-direction: column;
				gap: 10px;
				justify-content: flex-start;
				align-items: flex-start;
				p {
					color: #2e2c2c;
					font-family: "TT Wellingtons Trl";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					margin-bottom: -15px;
					margin-top: 10px;
				}
				ol {
					li {
						color: var(
							--Foundation-Neutral-Black,
							#2e2c2c
						);
						font-family: "TT Wellingtons Trl";
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
					}
				}
			}
		}
		.InstructorName {
			width: 98%;
			border: solid 1px #f2f4f8;
			border-radius: 4px;
			padding: 12px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-top: 40px;
			gap: 4%;
			.InputField {
				width: 47%;
				display: flex;
				flex-direction: column;
				gap: 10px;
				justify-content: flex-start;
				align-items: flex-start;
				label {
					color: #2e2c2c;
					font-family: "TT Wellingtons Trl";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
				}
				#InputField {
					width: 100%;
					height: 35px;
					padding-left: 12px;
					padding-top: 12px;
					border-radius: 4px;
					border: 0.5px solid
						var(--Primary-200, #d8e4fc);
					color: var(--Secondary-200, #9ba6bc);
					font-family: "TT Wellingtons Trl";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
				}
			}
		}
	}
	.NextButton {
		width: 97%;
		display: flex;
		justify-content: flex-end;
		padding: 10px 0 10px 10px;
		margin-top: 10px;
		button {
			width: 150px;
			height: 40px;
			border-radius: 4px;
			border: 0.5px solid #d8e4fc;
			background: #2a53a9;
			color: var(--Black-And-White-White, #fff);
			text-align: center;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			margin-left: 10px;
		}
	}
}
