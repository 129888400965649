@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.RecruiterApplicantsContainer {
  width: 90%;
  position: relative;
  margin-top: 1.5rem;
  margin-left: 9%;
  flex-grow: 2;
  overflow-x: hidden;
  .RecruiterApplicantsContent {
    width: 100%;
    @include flex-box($direction: column, $align: flex-start);
    .ApplicantSearchInputField {
      width: 100%;
      height: 57px;
      box-sizing: border-box;
      gap: 8px;
      padding: 16px 24px;
      margin-bottom: 40px;
      border-radius: 4px;
      border: 0.5px solid var(--Primary-200, #d8e4fc);
      @include flex-box($justify: flex-start);
      .ApplicantSearchIcon {
        width: 24px;
        height: 24px;
        color: #9ba6bc;
      }
      .ApplicantSearchTextField {
        border: none;
        outline: none;
        height: auto;
        width: 100%;
      }
      .ApplicantSearchTextField::placeholder {
        color: var(--Secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 162.5% */
        letter-spacing: 0.2px;
      }
    }
    .ApplicantsTopSection {
      width: 100%;
      @include flex-box($justify: flex-end);
      margin-bottom: 24px;
      .ApplicantSelectButtons {
        gap: 20px;
        @include flex-box($justify: flex-start);
        button:first-child {
          border-radius: 4px;
          border: 1px solid var(--Foundation-PRI-pri-500, #2a53a9);
          padding: 8px 16px;
          box-sizing: border-box;
          height: 48px;
          background: transparent;
          cursor: pointer;
          select {
            color: var(--Foundation-PRI-pri-500, #2a53a9);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            border: none;
            outline: none;
          }
        }
        button:last-child {
          border-radius: 4px;
          border: 1px solid var(--Foundation-PRI-pri-500, #2a53a9);
          background: var(--Foundation-PRI-pri-500, #2a53a9);
          padding: 8px 16px;
          height: 48px;
          box-sizing: border-box;
          color: var(--Black-And-White-White, #fff);
          font-family: $first-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 162.5% */
          letter-spacing: 0.2px;
          cursor: pointer;
        }
      }
    }
    .ApplicantTablePaper {
      width: 100%;
      box-sizing: border-box;
      .ApplicantTableContainer {
        width: 100%;
        .ApplicantTable {
          width: 100%;
          .ApplicantTableHead {
            width: 100%;
            border-radius: 4px 4px 0px 0px;
            background: var(--Primary-100, #ecf1fe);
            .ApplicantTableHeadRow {
              width: 100%;
              .ApplicantTableHeadCell {
                color: var(--CoolGray-100, #121619);
                font-family: $first-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 19.6px */
              }
            }
          }
          .ApplicantTableBody {
            width: 100%;
            .ApplicantTableBodyRow {
              width: 100%;
              .ApplicantTableBodyCell {
                color: var(--Foundation-Neutral-Black, #2e2c2c);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
              }
            }
          }
        }
      }
    }
  }
}
