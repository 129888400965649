@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.JobApplicationRecent {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  width: 90.96%;
  margin-top: 0;
  overflow-y: scroll;
  height: 97vh;
  .AppliedLinks {
    position: fixed;
    top: 0;
    padding-top: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
    width: 63.5%;
    z-index: 12;
    background-color: white;
    border-bottom: solid 0.5px #d8e4fc;
    a {
      color: var(--foundation-pri-pri-500, #9ba6bc);
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
      padding-bottom: 6px;
      border-bottom: solid 0.5px #d8e4fc;
      padding: 5px;
      padding-left: 20px;
    }
  }
  .ApplicationPageContainer {
    z-index: 3;
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 97.5%;
    .ApplicationStatus {
      width: 99.8%;
      padding: 8px;
      display: flex;
      flex-direction: column;
      border: solid 0.5px #d8e4fc;
      h5 {
        color: var(--foundation-pri-pri-500, #2a53a9);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-top: 0.5rem;
      }
      #applicationInfo {
        margin-top: -1rem;
        color: var(--foundation-neutral-black, #2e2c2c);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      #applicationStatus {
        margin-top: -0.3rem;
        color: var(--secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        span {
          color: var(--alert-warning-500-base, #ffbb38);
          font-family: $first-font;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          border-radius: 4px;
          background: rgba(255, 187, 56, 0.1);
          padding: 5px;
        }
      }
    }
    .ApplicationLetter {
      width: 99.96%;
      padding: 8px;
      display: flex;
      flex-direction: column;
      border: solid 0.5px #d8e4fc;
      h5 {
        color: var(--foundation-pri-pri-500, #2a53a9);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-top: 0.5rem;
      }
      .ApplicationLetterBody {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        p {
          margin-top: -0.1rem;
          color: var(--foundation-neutral-black, #2e2c2c);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          width: 80%;
          text-align: justify;
          line-height: 26px;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  .ApplicationPageAttachment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      h6 {
        color: var(--secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      p {
        border-radius: 4px;
        background: rgba(42, 83, 169, 0.05);
        padding: 5px;
        color: var(--foundation-pri-pri-500, #2a53a9);
        font-family: $first-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
  .JobDescriptionContainer {
    z-index: 3;
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 97.5%;
    .JobDescriptionAbout {
      width: 99.8%;
      padding: 8px;
      display: flex;
      flex-direction: column;
      border: solid 0.5px #d8e4fc;
      h2 {
        color: var(--foundation-pri-pri-500, #2a53a9);
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.2px;
      }
      p {
        color: var(--foundation-neutral-black, #2e2c2c);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.2px;
        margin-top: -0.1rem;
      }
    }
    .JobDescriptionSections {
      width: 99.8%;
      padding: 8px;
      display: flex;
      flex-direction: column;
      border: solid 0.5px #d8e4fc;
      gap: 10px;
      .JobDescriptionSectionOne {
        h2 {
          color: var(--foundation-pri-pri-500, #2a53a9);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0.2px;
        }
        p {
          color: var(--foundation-neutral-black, #2e2c2c);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.2px;
          margin-top: -0.1rem;
        }
      }
      .JobDescriptionSectionTwo {
        h2 {
          color: var(--foundation-pri-pri-500, #2a53a9);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0.2px;
        }
        .ResponsibilityList {
          ul {
            list-style: circle;
            li {
              color: var(--foundation-neutral-black, #2e2c2c);
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              letter-spacing: 0.2px;
              margin-top: -0.1rem;
            }
          }
        }
      }
      .JobDescriptionSectionThree {
        h2 {
          color: var(--foundation-pri-pri-500, #2a53a9);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0.2px;
        }
        .ResponsibilityList {
          ul {
            list-style: circle;
            li {
              color: var(--foundation-neutral-black, #2e2c2c);
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              letter-spacing: 0.2px;
              margin-top: -0.1rem;
            }
          }
        }
      }
      .JobDescriptionSectionFour {
        h2 {
          color: var(--foundation-pri-pri-500, #2a53a9);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0.2px;
        }
        .ResponsibilityList {
          ul {
            list-style: circle;
            li {
              color: var(--foundation-neutral-black, #2e2c2c);
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              letter-spacing: 0.2px;
              margin-top: -0.1rem;
            }
          }
        }
      }
    }
  }
  .CompanyInformationContainer {
    z-index: 3;
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 97.5%;
    .CompanyInfoAbout {
      width: 99.8%;
      padding: 8px;
      display: flex;
      flex-direction: column;
      border: solid 0.5px #d8e4fc;
      gap: 30px;
      h2 {
        color: var(--foundation-pri-pri-500, #2a53a9);
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.2px;
      }
      .CompanyInfo {
        display: flex;
        gap: 70px;
        justify-content: flex-start;
        align-items: center;
        span {
          display: flex;
          gap: 20px;
          justify-content: flex-start;
          align-items: center;
          color: var(--foundation-neutral-black, #2e2c2c);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          a {
            text-decoration: none;
            color: var(--foundation-neutral-black, #2e2c2c);
          }
        }
      }
      .CompanyInfoParag {
        p {
          color: var(--foundation-neutral-black, #2e2c2c);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.2px;
          margin-top: -0.1rem;
        }
      }
    }
    .CompanyFoundersSection {
      h2 {
        color: var(--foundation-pri-pri-500, #2a53a9);
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.2px;
      }
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          #founder {
            color: var(--foundation-neutral-black, #2e2c2c);
            font-family: $first-font;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            margin-left: 10px;
          }
          #founderPosition {
            margin-left: 10px;
            margin-top: -0.1rem;
            color: var(--secondary-200, #9ba6bc);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
          span {
            margin-left: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            a {
              width: 24px;
              height: 24px;
              color: #2a53a9;
            }
          }
        }
      }
    }
    .CompanyCultureSection {
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          color: var(--foundation-pri-pri-500, #2a53a9);
          font-family: $first-font;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0.2px;
        }
        span {
          margin-left: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
          
          a {
            width: 30px;
            height: 30px;
            color: #9BA6BC;
          }
        }
      }
      .CompanyCulture{
        
        p{
          flex-grow: 0;
          border-radius: 4px;
          border: 0.5px solid var(--primary-200, #D8E4FC);
          background: #FFF;
          padding: 16px;
          color: var(--foundation-neutral-black, #2E2C2C);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; 
        }
      }
      
    }
  }
}
