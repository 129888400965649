@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CourseSummaryContainer{
    @include container($width: 90%);
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .CourseSummaryContent{
        width: 100%;
        box-sizing: border-box;
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        .CourseSummaryHeading{
            @include flex-box($justify: space-between);
            width: 100%;
            h3{
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 108.333% */
                letter-spacing: 0.2px;
            }
            button{
                color: var(--Black-And-White-White, #FFF);
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                box-sizing: border-box;
                height: 50px;
                padding: 16px 24px;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
            }
        }
        .CourseSummaryTabs{
            @include flex-box($justify: flex-start, $align: flex-start);
            width: 100%;
            margin-bottom: 42px;
            border-bottom: 0.5px solid var(--Secondary-200, #9BA6BC);
            .CourseSummaryTab{
                width: 25%;
                gap: 8px;
                @include flex-box($justify: flex-start, $align: center);
                p{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 22.4px */
                }
            }
            .CourseSummaryActiveTab{
                width: 25%;
                gap: 8px;
                @include flex-box($justify: flex-start, $align: center);
                border-bottom: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
        }
        .CourseSummaryDetails{
            margin-bottom: 40px;
            width: 100%;
            height: 111px;
            padding: 16px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            .SummaryDetailsWrapper{
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: var(--Default-White, #FFF);
                @include flex-box($justify: space-between);
                h4{
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                    margin: 0;
                    padding: 0;
                }
                p{
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .CourseSummaryResources{
            margin-bottom: 40px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 16px;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            .SummaryResourcesHeading{
                @include flex-box($justify: space-between);
                width: 100%;
                box-sizing: border-box;
                padding: 16px;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: var(--Default-White, #FFF);
                h4{
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                }
                p{
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                }
            }
            .CourseResourcesWrapper{
                @include flex-box($justify: space-between);
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: var(--Default-White, #FFF);
                p{
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                }
            }
        }
        .CourseSummaryQuiz{
            margin-bottom: 40px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 16px;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            .SummaryQuizHeading{
                @include flex-box($justify: space-between);
                width: 100%;
                box-sizing: border-box;
                padding: 16px;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: var(--Default-White, #FFF);
                h4{
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                }
                p{
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                }
            }
            .SummaryQuizWrapper{
                @include flex-box($justify: space-between);
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: var(--Default-White, #FFF);
                p{
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 17.6px */
                }
            }
        }
    }
}