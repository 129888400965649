@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.JsCoachesContainer {
  @include container($width: 90%);
  position: relative;
  margin-top: 1.5rem;
  margin-left: 9%;
  flex-grow: 2;
  overflow-x: hidden;
  .JsCoaches {
    width: 100%;
    display: grid;
    grid-template-columns: 7fr 1fr;
    gap: 30px;
    .JsSearchBar {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .JsProfileSearch {
        color: #9ba6bc;
        width: 24px;
        height: 24px;
        transform: translate(10px);
      }
      .JsProfileInputSearch {
        border-radius: 4px 0px 0px 4px;
        border: 0.5px solid #d8e4fc;
        width: 100%;
        height: 40px;
        background-color: transparent;
        color: var(--secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding-left: 45px;
        transform: translate(-22px);
      }
    }
    .AppliedFindCoach {
      border-radius: 4px;
      background: #2a53a9;
      border: none;
      color: white;
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
  }
  .CoachLinks {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 10% 90%;
    a {
      color: var(--primary-90, #9ba6bc);
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
      padding-bottom: 6px;
      border-bottom: solid 0.5px #d8e4fc;
    }
    a:last-child {
      margin-right: 200px;
    }
  }
}
.AllCoachesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 20px 40px 30px;
  .AllCoachesInnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    flex-wrap: wrap;
    .AllCoachesClasses {
      min-height: 450px;
      border-radius: 4px;
      border: 1px solid var(--primary-200, #d8e4fc);
      .CoachesName {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 14px;
        margin-left: 10px;
        margin-top: -10px;

        h2 {
          color: #2e2c2c;
          font-family: $first-font;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          max-width: 75%;
        }
        p {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #2e2c2c;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0.2px;
          transform: translateX(-12px);
          span {
            .rating {
              width: 20px;
              height: 20px;
              color: #2a53a9;
            }
          }
        }
      }
      .coachesExperience {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;
        margin-left: 10px;
        margin-top: -15px;
        p {
          border-radius: 4px;
          background: rgba(60, 118, 241, 0.1);
          color: #2a53a9;
          font-family: $first-font;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          padding: 3px 4px;
        }
        span {
          border-radius: 4px;
          background: rgba(255, 187, 56, 0.1);
          color: #ffbb38;
          font-family: $first-font;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          padding: 3px 4px;
        }
      }
      button {
        border-radius: 4px;
        border: 2px solid #2a53a9;
        width: 280px;
        height: 35px;
        background-color: white;
        color: #2a53a9;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
        margin: 10px 10px 20px 10px;
      }
    }
  }
}
.MyCoachContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 20px 40px 30px;
  .MyCoachInnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    flex-wrap: wrap;
    .MyCoachEmptyContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 70vh;
      p {
        color: #000;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      button {
        color: $default;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        border-radius: 4px;
        background: #2a53a9;
        border: none;
        padding: 10px 20px;
      }
    }
    .AllCoachesClasses {
      min-height: 450px;
      border-radius: 4px;
      border: 1px solid var(--primary-200, #d8e4fc);
      .CoachesName {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 14px;
        margin-left: 10px;
        margin-top: -10px;

        h2 {
          color: #2e2c2c;
          font-family: $first-font;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          max-width: 75%;
        }
        p {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #2e2c2c;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0.2px;
          transform: translateX(-12px);
          span {
            .rating {
              width: 20px;
              height: 20px;
              color: #2a53a9;
            }
          }
        }
      }
      .coachesExperience {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;
        margin-left: 10px;
        margin-top: -15px;
        p {
          border-radius: 4px;
          background: rgba(60, 118, 241, 0.1);
          color: #2a53a9;
          font-family: $first-font;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          padding: 3px 4px;
        }
        span {
          border-radius: 4px;
          background: rgba(255, 187, 56, 0.1);
          color: #ffbb38;
          font-family: $first-font;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          padding: 3px 4px;
        }
      }
      a {
        text-decoration: none;
        margin: 10px 10px 20px 10px;

        button {
          border-radius: 4px;
          border: 2px solid #2a53a9;
          width: 280px;
          height: 35px;
          background-color: white;
          color: #2a53a9;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}
.CoachesPagination {
  @include flex-box($display: flex, $direction: row, $justify: space-between, $align: center);
  width: 99%;
  margin-bottom: 3rem;
  border-radius: 4px;
  margin-top: 20vh;
  border: 1px solid var(--primary-200, #d8e4fc);
  background: var(
    --black-and-white-white,
    linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
    #fff
  );
  p {
    color: var(--secondary-200, #9ba6bc);
    font-family: $first-font;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-left: 40px;
  }
  div {
    margin-right: 1rem;
  }
}
