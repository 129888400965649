@import "../../static/styles/shared.scss";
@import "../../static/styles/variables.styles.scss";

.CreateRoleContent {
    width: 98%;
    border-radius: 4px;
    border: 1px solid var(--Primary-200, #D8E4FC);
    box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05);
    padding: 16px;
    .CreateRoleHeader {
        @include flex-box($justify: flex-start);
        gap: 8px;
        width: 100%;
        margin-bottom: 32px;
        .ArrowHeader {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        h2 {
            color: var(--Foundation-PRI-pri-500, #2A53A9);
    
            /* Heading/H7 */
            font-family: $first-font;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 22px */
            padding: 0;
            margin: 0;
        }
    }
    .CreateRoleFields {
        @include flex-box($direction: column);
        width: 100%;
        gap: 24px;
        .CreateRoleTexts {
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            gap: 12px;
            h5 {
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 25.2px */
                padding: 0;
                margin: 0;
            }
            p {
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
                padding: 0;
                margin: 0;
            }
        }
        .InputFields {
            @include flex-box($justify: space-between);
            width: 100%;
            .InputField {
                width: 45%;
                display: flex;
                flex-direction: column;
                gap: 4px;
                label {
                    color: var(--Foundation-Neutral-Black, #2E2C2C);

                    /* Body/Regular */
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    padding: 0;
                    margin: 0;
                }
                input {
                    display: flex;
                    height: 48px;
                    padding: 12px 16px;
                    width: 100%;
                    box-sizing: border-box;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                }
                input::placeholder {
                    color: var(--Foundation-Neutral-Black, #2E2C2C);

                    /* Body/Regular */
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
        }
    
    }
    .Permissions {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        span {
            display: flex;
            flex-direction: column;
            gap: 12px;
            h5 {
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 25.2px */
                padding: 0;
                margin: 0;
            }
            p {
                margin: 0;
                padding: 0;
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
            }
        }
        .PermissionRole {
            width: 100%;
            @include flex-box($direction: column, $align: flex-start);
            gap: 16px;
            .Accordion {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .AccordionHeading {
                    @include flex-box($justify: space-between, $align: center);
                    width: 100%;
                    height: 48px;
                    padding: 13px 16px 13px 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    h4 {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);

                        /* Heading/H7 */
                        font-family: $first-font;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%; /* 22px */
                        padding: 0;
                        margin: 0;
                        cursor: pointer;
                        width: 80%;
                    }
                    span {
                        @include flex-box($align: center);
                        .ToggleText {
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 140%; /* 22.4px */
                        }
                        .AccordionArrow {
                            width: 24px;
                            height: 24px;
                        }
                        .rotate {
                            transform: rotate(180deg);
                        }
                    }
                }
                .AccordionContent {
                    @include flex-box($justify: space-between, $align: flex-start);
                    width: 100%;
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.5s cubic-bezier(0,1,0,1);
                    margin-top: 16px;
                    .ContentToggles {
                        width: 43%;
                        gap: 16px;
                        @include flex-box($direction: column, $align: flex-start, $justify: flex-start);
                        .ToggleDivider {
                            width: 100%;
                            height: 0.5px;
                            background: rgba(255, 255, 255, 0.50);
                            border: none;
                            box-shadow: 0px 1px 0px 0px rgba(17, 17, 17, 0.08);
                        }
                    }
                }
                .AccordionContent.show {
                    height: auto;
                    max-height: 9999px;
                    transition: all 0.5s cubic-bezier(0,1,0,1);
                }
            }
        }
    }
    .ActivityButtons {
        width: 100%;
        @include flex-box($justify: space-between);
        button {
            display: flex;
            width: 202px;
            height: 57px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 84px;
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            box-sizing: border-box;
            cursor: pointer;
        }
        button:first-child {
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            background: transparent;
            border-radius: 4px;
            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
        }
        button:last-child {
            color: var(--Black-And-White-White, #FFF);
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
  
    &.overlayOpen {
      opacity: 1;
      z-index: 0;
      visibility: visible;
    }
  
    &.overlayHidden {
      opacity: 0;
      z-index: -1;
      visibility: hidden;
      transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
  
  .drawer {
    top: 5%;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
  
    background-color: #ffffff;
    width: 85%;
    flex-shrink: 0;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--Primary-200, #D8E4FC);
    box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05);
  
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  
    &.hidden {
      visibility: hidden;
      width: 240px;
      transform: translateX(240px);
      flex-shrink: 0;
      transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
  
    &.animate {
      visibility: visible;
      transform: none;
      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
        0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    }
  }
  
  .left {
    left: 0;
    right: auto;
  }
  
  .right {
    right: 0;
    left: auto;
  }