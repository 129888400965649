@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.JobAddContainer{
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .JobAddTopSection {
        @include flex-box($display: flex, $direction: row, $align: flex-start, $justify: flex-start);
      width: 100%;
      padding: 0;
      gap: 75%;
      button:first-child{
        font-family: $first-font;
        font-size: 24px;
        font-weight: 600;
        color: var(--foundation-pri-pri-500, #2A53A9);
        width: auto;
        display: flex;
        justify-content: center;
        padding: 0;
        border: none;
        background: transparent;
      }
      button:last-child{
        font-family: $first-font;
        font-size: 16px;
        font-weight: 500;
        color: var(--foundation-pri-pri-500, #2A53A9);
        width: auto;
        display: flex;
        justify-content: center;
        padding: 0;
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }
    .JobAddLayout{
        width: 99.3%;
        @include flex-box($justify: flex-start, $direction: column,  $align: flex-start);
        .JobAddProgress{
          @include flex-box($justify: flex-start, $direction: row,  $align: flex-start);
          width: 100%;
          margin-top: 16px;
          .ProgressTab{
            width: 25%;
            a{
              text-decoration: none;
              button{
                width: 100%;
                padding: 16px 8px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                border: none;
                border-bottom: solid 1px #9BA6BC;
                background: transparent;
                cursor: pointer;
                .ProfileProgressTab{
                  @include flex-box($justify: flex-start, $direction: row,  $align: flex-start);
                  gap: 8px;
                  .ProfileProgressTabDetails{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 2px;
                    span:first-child{
                      font-family: $first-font;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      // color: var(--secondary-200, #9BA6BC);
                    }
                    span:last-child{
                      // color: var(--secondary-200, #9BA6BC);
                      font-family: $first-font;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;                
                    }
                  }
                  .ProgressTabIcon {
                    width: 24px;
                    height: 24px;
                    color: #25A249;
                  }
                }
              }
              .completedTab {
                border-bottom: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                .ProfileProgressTabDetails{
                  span:first-child{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                  }
                  span:last-child{
                    color: var(--Alert-Succes-600, #00B374);
                  }
                }
              }
              .activeTab {
                border-bottom: 1px solid var(--Primary-90, #001D6C);
                .ProfileProgressTabDetails {
                  span:first-child {
                    color: var(--CoolGray-90, var(--Cool-Grey, #21272A));
                  }
                  span:last-child {
                    color: var(--CoolGray-60, #697077);
                  }
                }
              }
              .incompleteTab {
                .ProfileProgressTabDetails {
                  span:first-child {
                    color: var(--secondary-200, #9BA6BC);
                  }
                  span:last-child {
                    color: var(--secondary-200, #9BA6BC);
                  }
                
                }
              }
            }
          }
        }
        .JobAddOverview{
          width: 100%;
          border: 1px solid var(--primary-200, #D8E4FC);
          border-radius: 4px;
          overflow-x: hidden;
          margin-top: 38px;
          padding: 16px;
          box-sizing: border-box;
        }
      }
}
.CompanyContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 24px;
    h4 {
        color: var(--Foundation-PRI-pri-500, #2A53A9);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 17.6px */ 
        padding: 0;
        margin: 0;
    }
    .JobAddSelectFields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        #JobAddSelectField {
            width: 49%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;
            label {
                color: var(--CoolGray-90, #21272A);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
        }
        .JobAddSelectField {
            width: 100%;
            height: 48px;
            border-radius: 4px;
            padding: 12px 16px;
            box-sizing: border-box;
            border: 0.5px solid var(--Secondary-200, #9BA6BC);
            outline: none;
            option {
                color: var(--Secondary-200, #9BA6BC);
                font-family: $first-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
            }
        }
        
    }
    .PageDivider {
        background: #9BA6BC;
        width: 100%;
        height: 0.5px;
    }
    #JobAddTextArea {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 8px;
        label {
            color: var(--Foundation-Neutral-Black, #2E2C2C);

            /* Body/Regular */
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
        }
    }
    .JobAddTextArea {
        border-radius: 4px;
        border: 0.5px solid var(--Primary-200, #D8E4FC);
        padding: 12px 16px;
        box-sizing: border-box;
        width: 100%;
    }
    .JobAddButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 91px;
        button:first-child {
            display: flex;
            width: 202px;
            height: 57px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            cursor: pointer;
        }
        button:last-child {
            display: flex;
            width: 202px;
            height: 57px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Black-And-White-White, #FFF);
            text-align: center;
            font-family: "TT Wellingtons Trl";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            cursor: pointer;
        }
    }
}
.DetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    h4 {
        color: var(--Foundation-PRI-pri-500, #2A53A9);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 17.6px */
    }
    .DetailsSelectFields {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        #DetailsSelectField {
            display: flex;
            flex-direction: column;
            width: 49%;
            gap: 8px;
            align-items: flex-start;
            label {
                color: var(--CoolGray-90, #21272A);

                /* Body/Regular */
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
        }
        .DetailsSelectField {
            width: 100%;
            height: 48px;
            padding: 12px 16px;
            box-sizing: border-box;
            outline: none;
            border-radius: 4px;
            border: 0.5px solid var(--Secondary-200, #9BA6BC);
        }
        .DetailsSelectField::placeholder {
            color: var(--Secondary-200, #9BA6BC);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
        }
        span {
            width: 49%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            #DetailsSelectField {
                display: flex;
                flex-direction: column;
                width: 46%;
                gap: 8px;
                align-items: flex-start;
                label {
                    color: var(--CoolGray-90, #21272A);
    
                    /* Body/Regular */
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
            .DetailsSelectField {
                width: 100%;
                height: 48px;
                padding: 12px 16px;
                box-sizing: border-box;
                outline: none;
                border-radius: 4px;
                border: 0.5px solid var(--Secondary-200, #9BA6BC);
            }
            .DetailsSelectField::placeholder {
                color: var(--Secondary-200, #9BA6BC);
                font-family: $first-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
            }
        }
    }
    .DetailsDivider {
        width: 100%;
        background: #9BA6BC;
        height: 0.5px;
    }
    #DetailsTextArea {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;
        width: 100%;
        label {
            color: var(--Foundation-Neutral-Black, #2E2C2C);

            /* Body/Regular */
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
        }
    }
    .DetailsTextArea {
        width: 100%;
        padding: 12px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 0.5px solid var(--Primary-200, #D8E4FC);
    }
    .DetailsTextArea::placeholder {
        color: var(--Secondary-200, #9BA6BC);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }
    .DetailsButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 91px;
        button:first-child {
            display: flex;
            width: 202px;
            height: 57px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            cursor: pointer;
        }
        button:last-child {
            display: flex;
            width: 202px;
            height: 57px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Black-And-White-White, #FFF);
            text-align: center;
            font-family: "TT Wellingtons Trl";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            cursor: pointer;
        }
    }
}