@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.ProfileVerificationContainer{
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    .ProfileVerificationContent{
        @include flex-box($direction: column, $justify: flex-start, $align: flex-start);
        width: 100%;
        gap: 40px;
        .ProfileVerificationTop{
            padding: 48px;
            box-sizing: border-box;
            gap: 40px;
            width: 100%;
            @include flex-box($direction: column, $justify: flex-start, $align: flex-start);
            h3{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                margin: 0;
                padding: 0;
            }
            .VerificationProfile{
                width: 100%;
                gap: 2%;
                @include flex-box();
                .ProfileDetails{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    width: 39%;
                    gap: 24px;
                    padding-right: 48px;
                    border-right: 1px solid var(--cool-gray-20, #DDE1E6);
                    img{
                        width: 96px;
                        height: 96px;
                        border-radius: 100px;
                        margin: 0;
                    }
                    .ProfileParticulars{
                        p:first-child{
                            color: var(--foundation-neutral-black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            padding: 0;
                            margin: 0;
                        }
                        p:last-child{
                            color: var(--secondary-200, #9BA6BC);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 125% */
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
                .AboutMe{
                    width: 59%;
                    p{
                        color: var(--foundation-neutral-black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
        .RecruiterVerificationDivider{
            width: 100%;
            height: 0.5px;
            background: #9BA6BC;
        }
        .RecruiterBusinessVerification{
            width: 100%;
            @include flex-box($direction: column, $align: flex-start);
            padding: 0px 48px;
            box-sizing: border-box;
            gap: 24px;
            h3{
                margin: 0;
                padding: 0;
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
            }
            .BusinessVerificationInput{
                width: 100%;
                gap: 2%;
                @include flex-box($align: flex-start);
                #BusinessVerificationTextField{
                    width: 49%;
                    gap: 8px;
                    @include flex-box($direction: column, $align: flex-start);
                    label{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
                .BusinessVerificationTextField{
                    width: 100%;
                    height: 48px;
                    box-sizing: border-box;
                    padding: 12px 16px;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                }
                .BusinessVerificationTextField::placeholder{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
            .RecruiterAddressVerification{
                width: 100%;
                @include flex-box($direction: column, $align: flex-start);
                gap: 8px;
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    margin: 0;
                    padding: 0;
                }
                .RecruiterAddressUpload{
                    width: 100%;
                    height: 196px;
                    @include flex-box($direction: column);
                    border-radius: 4px;
                    border: 1px dashed var(--Secondary-200, #9BA6BC);
                    background: var(--Background-Primary-Bg, #F5F8FA);
                    p{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                        margin: 0;
                        padding: 0;
                    }
                    button{
                        background-color: transparent;
                        border: none;
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                    }
                }
            }

        }
        .RecruiterBankVerification{
            width: 100%;
            padding: 0px 48px;
            box-sizing: border-box;
            gap: 24px;
            @include flex-box($direction: column, $align: flex-start);
            h3{
                margin: 0;
                padding: 0;
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
            }
            .BankVerificationInput{
                @include flex-box($justify: flex-start);
                width: 100%;
                gap: 2%;
                #BankVerificationTextField{
                    @include flex-box($direction: column, $align: flex-start);
                    width: 50%;
                    gap: 8px;
                    label{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
                .BankVerificationTextField{
                    width: 100%;
                    height: 48px;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                }
                .BankVerificationTextField::placeholder{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
            .RecruiterIdentityVerification{
                width: 100%;
                gap: 8px;
                @include flex-box($direction: column, $align: flex-start);
                p{
                    margin: 0;
                    padding: 0;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
                .RecruiterIdentityUpload{
                    width: 100%;
                    @include flex-box($direction: column);
                    height: 196px;
                    border-radius: 4px;
                    border: 1px dashed var(--Secondary-200, #9BA6BC);
                    background: var(--Background-Primary-Bg, #F5F8FA);
                    p{
                        padding: 0;
                        margin: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                    }
                    button{
                        background: transparent;
                        border: none;
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                    }
                }
            }
        }
        .RecruiterVerifyButton{
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            @include flex-box();
            button{
                width: 100%;
                height: 48px;
                border-radius: 4px;
                border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
                color: var(--Default-White, #FFF);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
            }
        }
        .SuccessModalOverlay{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            .SuccessModal{
                width: 380px;
                padding: 16px;
                box-sizing: border-box;
                gap: 16px;
                @include flex-box($direction: column);
                border: 1px solid var(--CoolGray-20, #DDE1E6);
                background: var(--Default-White, #FFF);
                .SuccessIconWrapper{
                    @include flex-box();
                    height: 48px;
                    width: 48px;
                    border-radius: 24px;
                    background: #00C781;
                    .SuccessIcon{
                        color: #E6EBF1;
                        height: 24px;
                        width: 24px;
                    }
                }
                h2{
                    padding: 0;
                    margin: 0;
                    color: var(--CoolGray-90, #21272A);
                    font-family: $first-font;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 35.2px */
                }
                p{
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    text-align: center;
                }
                button{
                    color: var(--Default-White, #FFF);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 22.4px */
                    width: 100%;
                    height: 48px;
                    border-radius: 4px;
                    border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
                    background: var(--Foundation-PRI-pri-500, #2A53A9);
                }
            }
        }
    }
}