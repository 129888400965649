@import "../../static/styles/shared.scss";
@import "../../static/styles/variables.styles.scss";

.RoleTableContain {
    @include container($width: 90%);
	position: relative;
	margin-top: 1.5rem;
	margin-left: 9%;
	margin-bottom: 3%;
	flex-grow: 2;
	overflow-x: hidden;
    .EmptyTableWrapper{
        @include flex-box($direction: column, $align: flex-start, $justify: flex-start);
        width: 100%;
        height: 395px;
        padding: 16px;
        box-sizing: border-box;
        gap: 32px;
        border-radius: 4px;
        border: 1px solid var(--Primary-200, #D8E4FC);
        box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05);
        .EmptyTopSection{
            @include flex-box($justify: flex-start);
            width: 100%;
            gap: 8px;
            .BackIcon{
                width: 24px;
                height: 24px;
            }
            h2{
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 22px */
                padding: 0;
                margin: 0;
            }
        }
        .EmptyBox{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .EmptyContent{
                @include flex-box($direction: column, $justify: center, $align: center);
                width: 400px;
                height: 100%;
                p {
                    text-align: center;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 140%; /* 16.8px */
                    padding: 0;
                }
                button {
                    display: flex;
                    width: 202px;
                    height: 48px;
                    padding: 16px 24px;
                    box-sizing: border-box;
                    justify-content: center;
                    align-items: center;
                    gap: 84px;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    background: var(--Foundation-PRI-pri-500, #2A53A9);
                    color: var(--Black-And-White-White, #FFF);
                    text-align: center;
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    cursor: pointer;
                }
            }
        }
    }
    .RoleTableContent {
        @include flex-box($direction: column, $align: flex-start, $justify: flex-start);
        width: 100%;
        gap: 30px;
        .RoleTableTop{
            @include flex-box($justify: flex-start);
            width: 100%;
            gap: 8px;
            .BackIcon{
                width: 24px;
                height: 24px;
            }
            h2{
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 22px */
                padding: 0;
                margin: 0;
            }
        }
        .RoleTableSearchArea {
            @include flex-box($justify: space-between);
            width: 100%;
            .RoleTableInputField {
                @include flex-box($justify: flex-start);
                height: 48px;
                gap: 8px;
                padding: 16px 24px;
                box-sizing: border-box;
                width: 70%;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                .RoleSearchIcon {
                    height: 24px;
                    width: 24px;
                    color: #9BA6BC;
                }
                .SearchInputField {
                    width: 80%;
                    border: none;
                    height: 100%;
                    outline: none;
                    padding: 16px;
                    font-size: 16px;
                }
                .SearchInputField::placeholder{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                }
            }
            .RoleTableSelect{
                display: flex;
                height: 48px;
                box-sizing: border-box;
                padding: 16px;
                justify-content: center;
                align-items: center;
                gap: 24px;
                background: transparent;
                flex-shrink: 0;
                border-radius: 4px;
                border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                select {
                    background: transparent;
                    border: none;
                    outline: none;
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                }
            }
            .RoleTableCreate {
                height: 48px;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
                color: var(--Black-And-White-White, #FFF);
                text-align: center;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

        }
        .RoleTablePaper{
            width: 100%;
            box-sizing: border-box;
            .RoleTableContainer{
                width: 100%;
                .RoleTable{
                    width: 100%;
                    .RoleTableHead{
                        width: 100%;
                        border-radius: 4px 4px 0px 0px;
                        background: var(--Primary-100, #ECF1FE);
                        .RoleTableHeadRow{
                            width: 100%;
                            .RoleTableHeadCell{
                                color: var(--CoolGray-100, #121619);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 140%; /* 19.6px */
                            }
                        }
                    }
                    .RoleTableBody{
                        width: 100%;
                        .RoleTableBodyRow{
                            width: 100%;
                            .RoleTableBodyCell{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; /* 22.4px */
                                .OptionsContainer{
                                    .OptionsIcon{
                                        height: 16px;
                                        width: 16px;
                                        color: #697077;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}