@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.VerificationContainer {
  border: solid 1px #9ba6bc;
  .VerificationInnerContainer {
    @include container($width: 95.5%);
    @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 2rem;
    .VerificationHeader {
      @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
      h3 {
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        color: #2e2c2c;
      }
    }
    .VerificationProfile {
      @include flex-box($justify: space-between, $direction: row, $align: center);
      .VerificationProfileChecker {
        @include flex-box($justify: space-between, $direction: row, $align: center);
        gap: 20px;
        .VerificationImageSpace {
          img {
            border: none;
            border-radius: 100%;
          }
        }
        .VerificationDetails {
          .VerificationName {
            font-family: $first-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            color: #2e2c2c;
          }
          .VerificationJob {
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            transform: translateY(-1.4rem);
          }
        }
      }
      .VerificationPersonRole {
        border-left: solid 1px #9ba6bc;
        padding: 0px;
        padding-left: 40px;
        width: 40%;
        min-height: 110px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        p {
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          color: #2e2c2c;
        }
      }
    }
    .PreferenceDivider {
      width: 100%;
      border: solid 1px #d1d4db;
      margin-left: -1rem;
      margin-bottom: 30px;
      margin-top: 25px;
    }
    .VerificationProfCert {
      margin-bottom: 20px;
      .VerificationProfHeader {
        h2 {
          font-family: $first-font;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          color: #2e2c2c;
        }
      }
      .VerificationUploader {
        @include flex-box($justify: center, $direction: column, $align: center);
        width: 1092px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px dashed #9ba6bc;
        background: #f5f8fa;
        height: 196px;
        div {
          input[type="file"] {
            border: solid 1px;
            height: 196px;
            opacity: 0;
          }
          position: absolute;
          z-index: 5;
        }
        .UploaderDetails {
          @include flex-box($justify: center, $direction: column, $align: center);
          position: absolute;
          z-index: 1;
          p:first-child {
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
          p:last-child {
            color: #2a53a9;
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            transform: translateY(-1.5rem);
          }
        }
      }
    }
    .VerificationEduCert {
      margin-top: 40px;
      .VerificationEduHeader {
        h2 {
          font-family: $first-font;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          color: #2e2c2c;
        }
      }
      .VerificationUploader {
        @include flex-box($justify: center, $direction: column, $align: center);
        width: 1092px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px dashed #9ba6bc;
        background: #f5f8fa;
        height: 196px;
        div {
          input[type="file"] {
            border: solid 1px;
            height: 196px;
            opacity: 0;
          }
          position: absolute;
          z-index: 5;
        }
        .UploaderDetails {
          @include flex-box($justify: center, $direction: column, $align: center);
          position: absolute;
          z-index: 1;
          p:first-child {
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
          p:last-child {
            color: #2a53a9;
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            transform: translateY(-1.5rem);
          }
        }
      }
    }
    .VerificationButton {
      button{
        width: 1100px;
        height: 40px;
        border-radius: 4px;
        border: 2px solid #2A53A9;
        color: white;
        background: #2A53A9;
        margin-bottom: 35px;
        margin-top: 30px;
      }
    }
    .VerificationInfo{
      width: 1100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      p{
        text-align: center;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        span{
          a{
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            color: #2a53a9;
          }
        }
      }
    }
  }
}
