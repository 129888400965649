@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.VerificationContainer{
    border: none;
    padding: 48px;
    .VerificationWrapper{
        width: 100%;
        margin: 0;
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        .VerificationTopSection{
            display: flex;
            flex-direction: column;
            h3{
                margin: 0;
                padding: 0;
                margin-bottom: 40px;
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                color: var(--foundation-neutral-black, #2E2C2C);
           }
           .VerificationProfile{
                width: 100%;
                gap: 2%;
                @include flex-box($display: flex, $direction: row);
                .ProfileDetails{
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    width: 49%;
                    border-right: 1px solid var(--cool-gray-20, #DDE1E6);
                    img{
                        width: 96px;
                        height: 96px;
                        border-radius: 100px;
                        margin: 0;
                        margin-right: 24px;
                    }
                    .ProfileParticulars{
                        p:first-child{
                            color: var(--foundation-neutral-black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            padding: 0;
                            margin: 0;
                        }
                        p:last-child{
                            color: var(--secondary-200, #9BA6BC);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 125% */
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
                .AboutMe{
                    width: 49%;
                    p{
                        color: var(--foundation-neutral-black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }
            }
        }
        .VerificationDivider{
            width: 100%;
            height: 0.5px;
            background: #9BA6BC;
            margin-bottom: 40px;
        }
        .ProfessionalSection{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 40px;
            h3{
                color: var(--foundation-neutral-black, #2E2C2C);
                font-family: TT Wellingtons Trl;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                margin: 0;
                margin-bottom: 24px;
            }
            .CertificateUpload{
                display: flex;
                flex-direction: column;
                padding: 16px;
                height: 196px;
                width: 100%;
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                border: 3px dashed var(--secondary-200, #9BA6BC);
                background: var(--background-primary-bg, #F5F8FA);
                p{
                    color: var(--foundation-neutral-black, #2E2C2C);
                    text-align: center;
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                }
                button{
                    color: var(--foundation-pri-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    border: none;
                    background: transparent;
                }
            }
        }
        .EducationSection{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 40px;
            h3{
                color: var(--foundation-neutral-black, #2E2C2C);
                font-family: TT Wellingtons Trl;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                margin: 0;
                margin-bottom: 24px;
            }
            .CertificateUpload{
                display: flex;
                flex-direction: column;
                padding: 16px;
                height: 196px;
                width: 100%;
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                border: 3px dashed var(--secondary-200, #9BA6BC);
                background: var(--background-primary-bg, #F5F8FA);
                p{
                    color: var(--foundation-neutral-black, #2E2C2C);
                    text-align: center;
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                }
                button{
                    color: var(--foundation-pri-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    border: none;
                    background: transparent;
                }
            }
        }
        button{
            width: 100%;
            height: 35px;
            border-radius: 4px;
            border: 2px solid var(--foundation-pri-pri-500, #2A53A9);
            background: var(--foundation-pri-pri-500, #2A53A9);
            color: var(--default-white, #FFF);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            cursor: pointer;
        }

    }
}