@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.DetailsModalContainer{
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .DetailModal{
        @include flex-box($display: flex, $direction: column, $justify: flex-start);
        background: #FFF;
        width: 714px;
        padding-bottom: 184px;
        .TopModalSection{
            @include flex-box();
            padding: 80px 40px 28px 40px;
            width: 100%;
            gap: 445px;
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%; /* 22px */
            }
            .ModalCloseIcon{
                height: 24px;
                width: 24px;
                color: #CC3333;
                cursor: pointer;
            }
        }
        .BottomModalSection{
            @include flex-box($display: flex, $direction: row, $justify: center, $align: flex-start);
            gap: 24px;
            width: 100%;
            .ModalAvatarWrapper{
                height: 200px;
                width: 200px;
                border-radius: 200px;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .MenteeModalDetails{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 32px;
                width: 410px;
                h3{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 26.4px */
                    margin: 0;
                    padding: 0;
                }
                .MenteeParticulars{
                    @include flex-box($display: flex, $direction: row, $justify: center, $align: flex-start);
                    width: 100%;
                    gap: 24px;
                    .ParticularsKeys{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 16px;
                        p{
                            color: var(--CoolGray-100, #121619);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 140%; /* 22.4px */
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .ParticularsValues{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 16px;
                        p{
                            margin: 0;
                            padding: 0;
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                            span{
                                color: var(--alert-succes-500-base, #00C781);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%;
                            }
                        }
                    }
                }
            }
        }
    }
}