@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CoachMenteeContainer{
    @include container($width: 90%);
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .ContainerContentWrapper{
        width: 99%;
        gap: 34px;
        @include flex-box($display: flex, $direction: column, $justify: flex-start, $align: flex-start);
        .MenteeTopSection{
            @include flex-box();
            width: 100%;
            gap: 1%;
            .MenteeTopSectionBox{
                width: 24.25%;
                padding: 16px;
                gap: 16px;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: var(--Default-White, #FFF);
                p:first-child{
                    color: var(--CoolGray-60, #697077);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    margin: 0;
                    padding: 0;
                }
                p:last-child{
                    color: var(--CoolGray-90, #21272A);
                    font-family: $first-font;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 26.4px */
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .MenteeSearchArea{
            width: 100%;
            height: 57px;
            padding: 0;
            margin: 0;
            @include flex-box($align: flex-start, $justify: flex-start);
            button{
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                background-color: transparent;
                border: none;
                cursor: pointer;
                margin-right: 77px;
                width: 200px;
                height: 100%;
            }
            .MenteeSearchInputField{
                @include flex-box($display: flex, $direction: row, $justify: flex-start, $align: center);
                margin-right: 24px;
                height: 100%;
                .MenteeSearchIcon{
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    margin-left: 1rem;
                    color: #9BA6BC;
                }
                .SearchInputField{
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    padding: 16px 24px;
                    width: 800px;
                    height: 100%;
                    padding-left: 50px;
                    box-sizing: border-box;
                }
                .SearchInputField::placeholder{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                }
            }
            .MenteeSelectField{
                color: var(--Black-And-White-White, #FFF);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                padding: 16px;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
                box-sizing: border-box;
            }
        }
        .MenteePaper{
            width: 100%;
            overflow: hidden;
            .MenteeTableContainer{
                width: 100%;
                .MenteeTable{
                    width: 100%;
                    .MenteeTableHead{
                        width: 100%;
                        border-radius: 4px 4px 0px 0px;
                        background: var(--Primary-100, #ECF1FE);
                        .MenteeTableRow{
                            width: 100%;
                            .MenteeTableCell{
                                color: var(--CoolGray-100, #121619);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 140%; /* 19.6px */
                            }
                        }
                    }
                    .MenteeTableBody{
                        width: 100%;
                        .MenteeTableBodyRow{
                            width: 100%;
                            .MenteeTableBodyCell{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; /* 22.4px */
                                .SeeMoreIconContainer{
                                    .SeeMoreIcon{
                                        height: 16px;
                                        width: 16px;
                                        color: #697077;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }    
}