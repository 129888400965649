@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.RecruiterPreferencesContainer{
    margin-top: -40px;
    display: flex;
    align-items: flex-start;
    padding: 48px;
    .RecruiterPreferencesContent{
        @include flex-box($direction: column, $justify: flex-start, $align: flex-start);
        width: 100%;
        gap: 40px;
        h2{
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 26.4px */
        }
        .RecruiterPreferencesWrapper{
            width: 100%;
            gap: 8px;
            @include flex-box($direction: column, $justify: flex-start, $align: flex-start);
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
            button{
                width: 100%;
                box-sizing: border-box;
                height: 48px;
                padding: 12px 16px;
                background: transparent;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                .RecruiterSelectField{
                    width: 100%;
                    border: none;
                    outline: none;
                    color: #9BA6BC;
                    height: 100%;
                }
            }
            
        }
        .RecruiterPreferencesButton{
            @include flex-box();
            color: var(--Default-White, #FFF);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            width: 100%;
            height: 48px;
            padding: 16px 12px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
        }
    }
}