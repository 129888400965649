@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.PreferencesContainer{
    padding: 48px;
    border: none;
    .PreferencesWrapper{
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        width: 100%;
        gap: 40px;
        .PreferencesDivider{
            width: 100%;
            height: 0.5px;
            background: #9BA6BC;
        }
        .PreferencesHeader{
            margin: 0;
            width: 100%;
            h3{
                font-family: $first-font;
                color: var(--foundation-neutral-black, #2E2C2C);
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                margin: 0;
            }
        }
        .PreferencesFormArea{
            width: 100%;
            margin: 0;
            .PreferencesField{
                display: flex;
                gap: 5px;
                width: 100%;
                justify-content: center;
                padding: 12px 16px;
                align-items: center;
                border-radius: 4px;
                border: 0.5px solid #d8e4fc;
                color: #9ba6bc;
            }
            #PreferencesField{
                display: flex;
                flex-direction: column;
                gap: 5px;
                justify-content: center;
                align-items: flex-start;
                label {
                    color: var(--cool-gray-90, #21272a);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
        }
        .SessionHeader{
            width: 100%;
            margin: 0;
            h3{
                font-family: $first-font;
                color: var(--foundation-neutral-black, #2E2C2C);
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                margin: 0;
                margin-bottom: 24px;
            }
            .SessionSelectAreas{
                width: 100%;
                gap: 2%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                .SessionSelectFieldOne{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 12px 16px;
                    align-items: center;
                    border-radius: 4px;
                    border: 0.5px solid #d8e4fc;
                    color: #9ba6bc;
                }
                #SessionSelectFieldOne{
                    display: flex;
                    width: 49%;
                    flex-direction: column;
                    gap: 5px;
                    justify-content: center;
                    align-items: flex-start;
                    label {
                        color: var(--cool-gray-90, #21272a);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
                .SessionSelectFieldTwo{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 12px 16px;
                    align-items: center;
                    border-radius: 4px;
                    border: 0.5px solid #d8e4fc;
                    color: #9ba6bc;
                }
                #SessionSelectFieldTwo{
                    display: flex;
                    width: 49%;
                    flex-direction: column;
                    gap: 5px;
                    justify-content: center;
                    align-items: flex-start;
                    label {
                        color: var(--cool-gray-90, #21272a);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }
            button{
                margin-top: 16px;
                padding: 0;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                border: none;
                background: transparent;
                p{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    padding: 16px 12px;
                    height: 35px;
                    border-radius: 4px;
                    border: 2px solid var(--foundation-pri-pri-500, #2A53A9);
                    color: var(--foundation-pri-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 16px */
                    letter-spacing: 0.5px;
                    box-sizing: border-box;
                }
            }
        }
        .PreferencesIncomeRange{
            width: 100%;
            h3{
                color: var(--foundation-neutral-black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%;
            }
            .IncomeFieldAreas{
                width: 100%;
                gap: 2%;
                @include flex-box($display: flex, $direction: row, $align: flex-start, $justify:flex-start);
                .IncomeSelectFieldOne{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 12px 16px;
                    align-items: center;
                    border-radius: 4px;
                    border: 0.5px solid #d8e4fc;
                    color: #9ba6bc;
                }
                #IncomeSelectFieldOne{
                    display: flex;
                    width: 49%;
                    flex-direction: column;
                    gap: 5px;
                    justify-content: center;
                    align-items: flex-start;
                    label {
                        color: var(--cool-gray-90, #21272a);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
                .IncomeSelectFieldTwo{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 12px 16px;
                    align-items: center;
                    border-radius: 4px;
                    border: 0.5px solid #d8e4fc;
                    color: #9ba6bc;
                }
                #IncomeSelectFieldTwo{
                    display: flex;
                    width: 49%;
                    flex-direction: column;
                    gap: 5px;
                    justify-content: center;
                    align-items: flex-start;
                    label {
                        color: var(--cool-gray-90, #21272a);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }
            .IncomeRangeSlider{
                width: 100%;
                h6{
                    color: var(--foundation-neutral-black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
                .SliderContainer{
                    width: 100%;
                    gap: 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    .RangeSlider{
                        width: 100%;
                        padding: auto;
                    }
                    .SliderSpanOne{
                        color: var(--secondary-200, #9BA6BC);
                        text-align: center;
                        font-family: $first-font;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 8.633px;
                    }
                    .SliderSpanTwo{
                        color: var(--secondary-200, #9BA6BC);
                        text-align: center;
                        font-family: $first-font;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 8.633px;
                    }
                    
                }
            }
            button{
                margin-top: 16px;
                padding: 0;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                border: none;
                background: transparent;
                p{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    padding: 16px 12px;
                    height: 40px;
                    border-radius: 4px;
                    border: 2px solid var(--foundation-pri-pri-500, #2A53A9);
                    color: var(--foundation-pri-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 16px */
                    letter-spacing: 0.5px;
                    box-sizing: border-box;
                }
            }
        }
        .PreferencesRoadMap{
            width: 100%;
            h3{
                color: var(--foundation-neutral-black, #2E2C2C);
                font-family: TT Wellingtons Trl;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
            }
            .RoadMapUpload{
                width: 100%;
                height: 196px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 3px dashed var(--secondary-200, #9BA6BC);
                background: var(--background-primary-bg, #F5F8FA);
                p{
                    color: var(--foundation-neutral-black, #2E2C2C);
                    text-align: center;
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                }
                button{
                    border: none;
                    background: transparent;
                    color: var(--foundation-pri-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    cursor: pointer;
                }
            }
        }
        button{
            width: 100%;
            height: 40px;
            border-radius: 4px;
            font-family: $first-font;
            font-style: normal;
            color: var(--default-white, #FFF);
            font-size: 16px;
            font-weight: 500;
            border: 2px solid var(--foundation-pri-pri-500, #2A53A9);
            background: var(--foundation-pri-pri-500, #2A53A9);
            cursor: pointer;
        }
    }
}