@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.ConfirmVerificationContainer {
  border: solid 1px #9ba6bc;
  .ConfirmVerificationInnerContainer {
    @include container($width: 95.5%);
    @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 2rem;
    .ConfirmVerificationHeader {
      @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
      h3 {
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        color: #2e2c2c;
      }
    }
    .ConfirmVerificationProfile {
      @include flex-box($justify: space-between, $direction: row, $align: center);
      .ConfirmVerificationProfileChecker {
        @include flex-box($justify: space-between, $direction: row, $align: center);
        gap: 20px;
        .ConfirmVerificationImageSpace {
          img {
            border: none;
            border-radius: 100%;
          }
        }
        .ConfirmVerificationDetails {
          .ConfirmVerificationName {
            font-family: $first-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            color: #2e2c2c;
          }
          .ConfirmVerificationJob {
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            transform: translateY(-1.4rem);
          }
        }
      }
      .ConfirmVerificationPersonRole {
        border-left: solid 1px #9ba6bc;
        padding: 0px;
        padding-left: 40px;
        width: 40%;
        min-height: 110px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        p {
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          color: #2e2c2c;
        }
      }
    }
    .PreferenceDivider {
      width: 100%;
      border: solid 1px #d1d4db;
      margin-left: -1rem;
      margin-bottom: 30px;
      margin-top: 25px;
    }
    .ConfirmVerificationSecondHeader{
      width: 98%;
      @include flex-box($display: flex, $direction: row, $justify: space-between, $align: center);
      p{
        @include text(
          $font-size: 24px,
          $color: $first-text,
          $font-weight: 600,
          $font-family: $first-font,
          $text-align: justify,
          $line-height: normal,
        );
        font-style: normal;
      }
      a{
        @include text(
          $font-size: 16px,
          $color: #2A53A9,
          $font-weight: 500,
          $font-family: $first-font,
          $text-align: justify,
          $line-height: normal,
          
        );
        text-decoration: none;
        display: flex;
        span{
          transform: translate(0.3rem, 0.20rem);
        }
      }
    }
    .ConfirmVerificationSecondSection{
      width: 98%;
      @include flex-box($display: flex, $direction: row, $justify: space-between, $align: center);
      .ConfirmVerificationProcessor{
        width: 35%;
        border:solid 0.5px #D8E4FC;
        border-radius: 5.313px;
        @include flex-box($display: flex, $direction: column, $justify: center, $align: center);
        height: 300px;
        div{
          width: 80%;
          position: absolute;
          height: 80%;
          .CircularProgressiveBar{
            font-family: 'Nanum Myeongjo', serif;
            color: #808080;
          }
        }
        p{
          @include text(
            $font-size: 26.566px,
            $color: #2E2C2C ,
            $font-weight: 600,
            $font-family: $first-font,
            $text-align: justify,
            $line-height: normal,  
          );
          transform: translate(0rem, 3rem);
        }
      }
      .ConfirmVerificationCategories{
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 23px;
        a{
          text-decoration: none;
          div{
            border:solid 0.5px #D8E4FC;
            border-radius: 5.313px;
            padding: 15px 0px 15px 10px;
            display: flex;
            gap: 0.5rem;
            span{
              transform: translate(0rem, 0rem);
              background: #ECF1FE;
              border-radius: 100%;
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            color: var(--foundation-neutral-black, #2E2C2C);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }

    .ConfirmVerificationButton {
      width: 100%;
      button:first-child{
        width: 99%;
        height: 40px;
        border-radius: 4px;
        border: 2px solid #2A53A9;
        color: white;
        background: #2A53A9;
        margin-bottom: 20px;
        margin-top: 30px;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      button:last-child{
        width: 99%;
        height: 40px;
        border-radius: 4px;
        border: 2px solid #2A53A9;
        color: #2A53A9;
        background: white;
        margin-bottom: 35px;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
    }
    .ConfirmVerificationInfo{
      width: 96%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 9px;
      border:solid 0.5px #D8E4FC;
      border-radius: 5.313px;
      margin-top: 20px;
      
      span{
        transform: translate(0rem, 0rem);
        background: #ECF1FE;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p{
        text-align: left;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        
      }
    }
  }
}
