@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.RecruiterHiringStatusContainer {
  width: 90%;
  position: relative;
  margin-top: 1.5rem;
  margin-left: 9%;
  flex-grow: 2;
  overflow-x: hidden;
  .RecruiterHiringStatusContent {
    width: 100%;
    .HiringStatusHeading {
      width: 100%;
      @include flex-box($justify: space-between);
      .HiringStatusNavigation {
        @include flex-box();
        gap: 18px;
        cursor: pointer;
        .HiringStatusBackIcon {
          height: 24px;
          width: 24px;
          color: #21272a;
        }
        p {
          color: var(--Foundation-Neutral-Black, #2e2c2c);
          font-family: $first-font;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 110%; /* 26.4px */
        }
      }
      .JobDetailsEdit {
        @include flex-box();
        gap: 5px;
        text-decoration: none;
        cursor: pointer;
        .JobDetailsEditIcon {
          height: 24px;
          width: 24px;
          color: #2e2c2c;
        }
        p {
          color: var(--Opacity-Opacity-80, rgba(0, 0, 0, 0.8));
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 162.5% */
          letter-spacing: 0.2px;
        }
      }
    }
    .HiringStatusSearchInputField {
      width: 100%;
      height: 57px;
      box-sizing: border-box;
      gap: 8px;
      padding: 16px 24px;
      margin-bottom: 40px;
      border-radius: 4px;
      border: 0.5px solid var(--Primary-200, #d8e4fc);
      @include flex-box($justify: flex-start);
      .HiringStatusSearchIcon {
        width: 24px;
        height: 24px;
        color: #9ba6bc;
      }
      .HiringStatusSearchTextField {
        border: none;
        outline: none;
        height: auto;
        width: 100%;
      }
      .HiringStatusSearchTextField::placeholder {
        color: var(--Secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 162.5% */
        letter-spacing: 0.2px;
      }
    }
    .HiringStatusTopSection {
      width: 100%;
      @include flex-box($justify: space-between);
      margin-bottom: 24px;
      .HiringStatusButtonTabs {
        gap: 24px;
        @include flex-box($justify: flex-start);
        button {
          border: none;
          background: transparent;
          padding: 16px 0px;
          height: 48px;
          box-sizing: border-box;
          color: var(--CoolGray-90, #21272a);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
        }
        button:nth-child(1) {
          color: var(--Foundation-PRI-pri-500, #2a53a9);
          border-bottom: 2px solid var(--Foundation-PRI-pri-500, #2a53a9);
        }
      }
      .HiringStatusSelectButtons {
        gap: 20px;
        @include flex-box($justify: flex-start);
        button:first-child {
          border-radius: 4px;
          border: 1px solid var(--Foundation-PRI-pri-500, #2a53a9);
          padding: 8px 16px;
          box-sizing: border-box;
          height: 48px;
          background: transparent;
          select {
            color: var(--Foundation-PRI-pri-500, #2a53a9);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            border: none;
            outline: none;
          }
        }
        button:last-child {
          border-radius: 4px;
          border: 1px solid var(--Foundation-PRI-pri-500, #2a53a9);
          background: var(--Foundation-PRI-pri-500, #2a53a9);
          padding: 8px 16px;
          height: 48px;
          box-sizing: border-box;
          color: var(--Black-And-White-White, #fff);
          font-family: $first-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 162.5% */
          letter-spacing: 0.2px;
        }
      }
    }
    .HiringStatusStats {
      padding: 16px 14px 6px 24px;
      border: 0.5px solid #9db0d7;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      h3 {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 21;
      }
      p {
        color: (--foundation-PRI-pri-500, #2a53a9);
        font-size: 24px;
        font-weight: 600;
        line-height: 26.46px;
        margin-top: 21;
      }
      .blueStat {
        color: var(--foundation-PRI-pri-500, #2a53a9);
      }
      .greenStat {
        color: var(--Alert-Success-600, #00b374);
      }
      .yellowStat {
        color: var(--Alert-Warning-500, #ffbb38);
      }
      .redStat {
        color: var(--Alert-Danger-700, #cc3333);
      }
      .greyStat {
        color: var(--Secondary-200, #9ba6bc);
      }
    }
    .HiringStatusDetails {
      @include flex-box($direction: column, $align: flex-start);
      gap: 10px;
      width: 100%;
      padding: 32px 16px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid var(--Primary-200, #d8e4fc);
      background: var(--Default-White, #fff);
      .HiringStatusInputs {
        @include flex-box($justify: space-between);
        width: 100%;
        #HiringStatusInput {
          width: 32%;
          @include flex-box($direction: column, $align: flex-start);
          gap: 8px;
          label {
            color: var(--CoolGray-90, #21272a);

            /* Body/Regular */
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
          }
        }
        .HiringStatusInput {
          width: 100%;
          height: 48px;
          padding: 12px 16px;
          box-sizing: border-box;
          border-radius: 4px;
          border: 0.5px solid var(--Secondary-200, #9ba6bc);
          background: var(--Primary-100, #ecf1fe);
        }
        .HiringStatusInput::placeholder {
          color: var(--Foundation-Neutral-Black, #2e2c2c);
          font-family: $first-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }
      }
    }
    .AppPaper {
      width: 100%;
      .AppTable {
        width: 100%;
        margin-top: 32px;
        border: 0.5px solid 0.5px solid #9db0d7;
        .AppTableHead {
          width: 100%;
          .AppTableRow {
            width: 100%;
            border-radius: 4px 4px 0px 0px;
            background: var(--Primary-100, #ecf1fe);
            .AppTableCell {
              // margin-top: 32px;
              color: var(--CoolGray-100, #121619);

              /* Caption/Medium */
              font-family: $first-font;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            }
          }
        }
        .AppTableBody {
          width: 100%;
          .AppTableBodyRow {
            width: 100%;
            .AppTableBodyCell {
              color: var(--Foundation-Neutral-Black, #2e2c2c);

              /* Body/Regular */
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 22.4px */
              .ActionContainer {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
