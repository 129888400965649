@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.RecruiterMessageContainer{
    width: 90%;
    position: relative;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .RecruiterMessageContent{
        width: 100%;
        @include flex-box($direction: column, $align: flex-start);
        .RecruiterMessageTop{
            @include flex-box($justify: space-between);
            width: 100%;
            .RecruiterMessageSearchArea{
                width: 30%;
                border-radius: 4px 0px 0px 0px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                height: 57px;
                padding: 16px 24px;
                box-sizing: border-box;
                @include flex-box($justify: flex-start);
                gap: 8px;
                .MessageSearchIcon{
                    width: 24px;
                    height: 24px;
                    color: #9BA6BC;
                }
                .MessageSearchTextField{
                    width: 100%;
                    border: none;
                    outline: none;
                }
                .MessageSearchTextField::placeholder{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                }
            }
            button{
                background: transparent;
                border: none;
                padding: 8px 16px;
                overflow: hidden;
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                text-overflow: ellipsis;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
                cursor: pointer;
            }
        }
        .RecruiterMessageChatSection{
            width: 100%;
            @include flex-box($justify: flex-start, $align: flex-start);
            .RecruiterChats{
                width: 30%;
                @include flex-box($direction: column);
                border-radius: 0px 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                padding: 16px;
                box-sizing: border-box;
                .RecruiterChat{
                    border-bottom: 1px solid var(--Primary-200, #D8E4FC);
                    background: #FFF;
                    padding: 16px;
                    box-sizing: border-box;
                    border-radius: 3px;
                    width: 100%;
                    gap: 8px;
                    cursor: pointer;
                    @include flex-box($direction: column, $align: flex-start);
                    .RecruiterChatTop{
                        width: 100%;
                        @include flex-box($justify: space-between);
                        .RecruiterChatProfile{
                            @include flex-box($justify: flex-start);
                            gap: 4px;
                            img {
                                height: 24px;
                                width: 24px;
                                border-radius: 100%;
                            }
                            p{
                                margin: 0;
                                padding: 0;
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 26px; /* 185.714% */
                                letter-spacing: 0.2px;
                            }
                        }
                        p{
                            padding: 0;
                            margin: 0;
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px; /* 185.714% */
                            letter-spacing: 0.2px;
                        }
                        
                    }
                    p{
                        margin: 0;
                        padding: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px; /* 185.714% */
                        letter-spacing: 0.2px;
                    }
                }
                .RecruiterChat:hover{
                    background: #D8E4FC;
                }
            }
            .RecruiterMessageChat{
                width: 70%;
                padding: 0px 24px 32px 24px;
                box-sizing: border-box;
                gap: 24px;
                @include flex-box($direction: column, $align: flex-start);
                .SelectedChatDetails{
                    width: 100%;
                    gap: 16px;
                    @include flex-box($direction: column, $align: flex-start);
                    .ReceivedMessage{
                        @include flex-box($direction: row, $justify: flex-start, $align: flex-start);
                        gap: 12px;
                        width: 100%;
                        img{
                            height: 40px;
                            width: 40px;
                            border-radius: 100%;
                        }
                        .RecruiterChatProfile{
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 6px;
                            p:first-child{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 20px; /* 142.857% */
                                padding: 0;
                                margin: 0;
                            }
                            p:last-child{
                                color: var(--Gray-900, #101828);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px; /* 150% */
                                border-radius: 0px 8px 4px 4px;
                                margin: 0;
                                padding: 0;
                                padding: 10px 14px;
                                width: 80%;
                                box-sizing: border-box;
                                background: var(--Primary-100, #ECF1FE);
                            }
                        }
                        .SentMessage{
                            @include flex-box($direction: column, $align: flex-start);
                            width: 80%;
                            margin-top: 150px;
                            span{
                                width: 100%;
                                gap: 6px;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                p:first-child{
                                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                                    font-family: $first-font;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 20px; /* 142.857% */
                                    padding: 0;
                                    margin: 0;
                                }
                                p:last-child{
                                    padding: 0;
                                    margin: 0;
                                    width: 100%;
                                    color: var(--Base-White, #FFF);
                                    font-family: $first-font;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px; /* 150% */
                                    border-radius: 8px 0px 4px 4px;
                                    background: var(--Foundation-PRI-pri-500, #2A53A9);
                                    padding: 10px 14px;
                                    box-sizing: border-box;
                                }
                            }
                        }
                    }
                }
                .RecruiterChatArea{
                    height: 104px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 6px;
                    padding: 12px 14px;
                    box-sizing: border-box;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    background: var(--Base-White, #FFF);
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    .RecruiterChatInput{
                        width: 100%;
                        height: 50%;
                        border: none;
                        outline: none;
                    }
                    .RecruiterChatInput::placeholder{
                        color: var(--Primary-200, #D8E4FC);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px; /* 150% */
                    }
                    .RecruiterChatIcons{
                        @include flex-box($justify: flex-end);
                        gap: 8px;
                        width: 100%;
                        .RecruiterChatIcon{
                            width: 20px;
                            height: 20px;
                            color: #9BA6BC;
                        }
                        button{
                            display: flex;
                            padding: 10px 16px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                            background: var(--Foundation-PRI-pri-500, #2A53A9);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            .ButtonIcon{
                                width: 20px;
                                height: 20px;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }
    }
}