@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.JsGetProfile{
  position: absolute;
  top: 20%;
  left: 40%;
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 0px 0px 4px 4px;
  background: #fff;
  justify-content: center;
  align-items: center;
  gap: 5px;
  box-shadow: -4px 5px 8px 2px rgba(42, 83, 169, 0.15);
  z-index: 5;
  padding: 15px;
}

.JsProfileCard {
  position: absolute;
  top: 30%;
  left: 40%;
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 0px 0px 4px 4px;
  background: #fff;
  justify-content: center;
  align-items: center;
  gap: 5px;
  box-shadow: -4px 5px 8px 2px rgba(42, 83, 169, 0.15);
  z-index: 5;
  padding: 15px;
  .CardSectionOne {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0px 5px;
    box-shadow: -2px 2px 1px 1px rgba(42, 83, 169, 0.05);
    border-bottom: solid 2px #9ba6bc;
    p {
      color: #2e2c2c;
      font-family: $first-font;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
    }
    & > :not(p) {
      width: 24px;
      height: 24px;
      cursor: pointer;
      color: #9ba6bc;
    }
  }
  .CardSectionTwo {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: #2e2c2c;
      font-family: $first-font;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
    }
  }
  .CardSectionThree {
    width: 300px;
    display: flex;
    gap: 20px;
    .ProfileLogo {
      width: 42px;
      height: 42px;
    }
    .ProfileAddresses {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px;
      h3 {
        color: #21272a;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        margin-top: -0.5rem;
      }
      a,
      p {
        color: var(--primary-500-base, #3c76f1);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        text-decoration: none;
        margin-top: -0.7rem;
      }
    }
  }
}
.JsProfileContainer {
  @include container($width: 91.8%);
  position: relative;
  margin-top: 1rem;
  margin-left: 9%;
  flex-grow: 2;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .JsProfileCover {
    border-radius: 4px;
    border: 1px solid var(--primary-200, #d8e4fc);
    background: var(--primary-200, #d8e4fc);
    min-height: 250px;
    width: 99%;
    display: grid;
    grid-template-columns: 8fr 1fr;
    justify-content: center;
    .JsSearchBar {
      position: relative;
      margin-top: 20px;
      left: 40%;
      .JsProfileSearch {
        color: #9ba6bc;
        width: 24px;
        height: 24px;
        transform: translateY(0.5rem) translateX(2.3rem);
      }
      .JsProfileInputSearch {
        border-radius: 4px 0px 0px 4px;
        border: 0.5px solid #6391f4;
        width: 230px;
        height: 40px;
        background-color: transparent;
        color: var(--secondary-200, #9ba6bc);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding-left: 45px;
      }
    }
    .JsEditCover {
      margin-top: 30px;
      margin-left: 80px;
      .JsProfileAddCover {
        position: absolute;
        opacity: 0;
        width: 25px;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .JsProfileImage {
    display: grid;
    grid-template-columns: 7fr 1fr;
    z-index: 6;
    img {
      margin-top: -100px;
      margin-left: 40px;
    }
  }
  .JsProfileInformation {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    .JsProfileDetailsOne {
      background: #fff;
      box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
      min-height: 100px;
      margin-left: 3px;
      margin-bottom: 20px;
      padding-top: 100px;
      margin-top: -100px;
      padding-left: 10px;
      h2 {
        color: #2e2c2c;
        font-family: $first-font;
        font-size: 31px;
        font-style: normal;
        font-weight: 700;
      }
      h3 {
        color: #2e2c2c;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #2e2c2c;
        font-family: $first-font;
        span {
          color: #2a53a9;
          cursor: pointer;
        }
      }
      p:last-child {
        color: #2a53a9;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
    .JsProfileDetailsTwo {
      background: #fff;
      box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
      min-height: 100px;
      margin-left: 20px;
      margin-bottom: 20px;
      padding-top: 100px;
      margin-top: -100px;
      padding-left: 30px;
      #EditIcon {
        display: absolute;
        transform: translate(50vw, -3rem);
        width: 26px;
        height: 26px;
      }
      div {
        transform: translateY(-2.3rem);
        h2 {
          color: #2e2c2c;
          font-family: $first-font;
          font-size: 31px;
          font-style: normal;
          font-weight: 700;
        }
        p {
          color: #2e2c2c;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          text-align: justify;
          width: 95%;
          line-height: 30px;
        }
      }
    }
  }

  .ProfileSectionThree {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    align-items: center;
    margin-top: 14px;
    #ProfileSectionThree {
      background: #fff;
      box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
      min-height: 100px;
      margin-left: 10px;
      margin-bottom: 20px;
      padding-left: 30px;
      height: 250px;
      margin-bottom: 20px;
    }
    .ProfileStrength {
      .ProfileStrengthHeadings {
        color: #2e2c2c;
        font-family: $first-font;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        margin-top: 1rem;
      }
      .ProfileStrengthVisibility {
        margin-top: -1rem;
        color: #9ba6bc;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 162.5% */
        letter-spacing: 0.2px;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
        & > :not(p) {
          width: 24px;
          height: 24px;
        }
      }
      .ProfileStrengthLevel {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: #2e2c2c;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        div {
          display: flex;
          gap: 10px;
          justify-content: flex-start;
          align-items: center;
          margin-top: -2.3rem;
        }
      }
      .ProfileStrengthInfo {
        color: #2e2c2c;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .ProfileView {
      .ProfileViewHeadings {
        color: #2e2c2c;
        font-family: $first-font;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        margin-top: 1rem;
      }
      .ProfileViewVisibility {
        margin-top: -1rem;
        color: #9ba6bc;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.2px;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
        & > :not(p) {
          width: 24px;
          height: 24px;
        }
      }
      .ProfileViewsNumber {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
        margin-top: -1rem;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        & > :not(p) {
          width: 20px;
          height: 20px;
          color: #9ba6bc;
        }
      }
      .ProfileSearchApps {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-top: -0.5rem;
        & > :not(p) {
          width: 20px;
          height: 20px;
          color: #9ba6bc;
        }
      }
      .ProfileViewInfo {
        color: #2e2c2c;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .Portfolio {
      .PortfolioHeadings {
        color: #2e2c2c;
        font-family: $first-font;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        margin-top: 1rem;
      }
      .PortfolioVisibility {
        margin-top: -1rem;
        color: #9ba6bc;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
      }
      .PortfolioSearch {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        p {
          color: #21272a;
          font-family: $first-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }
        input[type="text"] {
          border-radius: 4px;
          border: 0.5px solid #9ba6bc;
          width: 300px;
          height: 35px;
          background-color: transparent;
          color: #9ba6bc;
          font-family: $first-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          padding-left: 10px;
          margin-top: -0.2rem;
        }
      }
    }
  }
  .ProfileSectionFour {
    width: 95.5%;
    background: #fff;
    box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
    min-height: 100px;
    margin-left: 10px;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    .ProfileSectionAddress {
      display: grid;
      grid-template-columns: 85% 15%;
      align-items: center;
      .ProfileSectionHeader {
        color: var(--foundation-neutral-black, #2e2c2c);
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
      }
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        a {
          color: #2a53a9;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          text-decoration: none;
        }
        & > :not(a) {
          width: 24px;
          height: 24px;
          color: #2a53a9;
        }
      }
    }
    .ActivitySection {
      display: flex;
      flex-direction: column;
      gap: 20px;
      #ActivitySection {
        border-radius: 4px;
        border: 1px solid#D8E4FC;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: last baseline;
        .ActivityDisplay {
          margin: 20px 0 0 20px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          .ActivityInfomation {
            display: flex;
            flex-direction: column;
            gap: 10px;
            #ActivityName {
              margin-top: -0.2rem;
              color: #2e2c2c;
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 26px;
            }
            div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 12px;
              margin-top: -2rem;
              #ActivityNotification {
                color: #9ba6bc;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
              }
              #ActivityPeriod {
                color: #2a53a9;
                font-family: $first-font;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                border-radius: 4px;
                background: rgba(60, 118, 241, 0.1);
                padding: 4px 7px 4px 7px;
              }
              #ActivityIcon {
                color: #9ba6bc;
                cursor: pointer;
              }
              #ActivityReaction {
                color: #9ba6bc;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
              }
            }
          }
        }
        .ActivityShowMore {
          margin: 0 20px 15px 0;
          a {
            text-decoration: none;
            button {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              border: 1px solid var(--primary-500-base, #3c76f1);
              color: #3c76f1;
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              padding: 10px;
              background-color: white;
              gap: 10px;
            }
          }
        }
      }
    }
  }
  #ProfileGeneralSegment {
    width: 95.5%;
    background: #fff;
    box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
    min-height: 100px;
    margin-left: 10px;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    gap: 20px;
    .ProfileExperienceHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      h2 {
        color: var(--foundation-neutral-black, #2e2c2c);
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
      }
      a {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        p {
          color: var(--foundation-pri-pri-500, #2a53a9);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
        .ProfileAddExperience {
          color: var(--foundation-pri-pri-500, #2a53a9);
          width: 20px;
          height: 20px;
        }
      }
    }
    .ProfileExperienceTagNames {
      width: 99.5;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      align-items: flex-start;
      .AddExperienceData {
        width: 99.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        border: 1px solid var(--primary-200, #d8e4fc);
        .ExperienceData {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          span:first-child {
            margin-left: 13px;
          }
          span:last-child {
            display: flex;

            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 3px;
            border: none;
            margin-left: 0rem;
            h5 {
              color: var(--foundation-neutral-black, #2e2c2c);
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: -1rem;
            }
            div {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 15px;
              flex-wrap: nowrap;

              p:nth-child(1) {
                color: var(--secondary-200, #9ba6bc);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
              }
              p:nth-child(2) {
                color: var(--foundation-pri-pri-500, #2a53a9);
                font-family: $first-font;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                border-radius: 4px;
                background: rgba(60, 118, 241, 0.1);
                padding: 5px;
                display: flex;
                justify-content: center;
                white-space: none;
                min-width: 70px;
              }
              p:nth-child(3) {
                color: var(--alert-succes-600, #00b374);
                font-family: $first-font;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                border-radius: 4px;
                background: rgba(0, 179, 116, 0.1);
                display: flex;
                justify-content: center;
                white-space: none;
                min-width: 70px;
                padding: 5px;
              }
            }
          }
        }
        div {
          margin-right: 20px;
        }
      }
    }
  }
  .ProfileSectionSeven {
    width: 95.5%;
    background: #fff;
    box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
    min-height: 100px;
    margin-left: 10px;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    .ProfileSectionAddress {
      display: grid;
      grid-template-columns: 85% 15%;
      align-items: center;
      .ProfileSectionHeader {
        color: var(--foundation-neutral-black, #2e2c2c);
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
      }
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        a {
          color: #2a53a9;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          text-decoration: none;
        }
        & > :not(a) {
          width: 24px;
          height: 24px;
          color: #2a53a9;
        }
      }
    }
    .LicenceSection {
      display: flex;
      flex-direction: column;
      gap: 20px;
      #LicenceSection {
        border-radius: 4px;
        border: 1px solid#D8E4FC;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .LicenceDisplay {
          margin: 20px 0 0 20px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          .LicenceInfomation {
            display: flex;
            flex-direction: column;
            gap: 10px;
            #LicenceName {
              margin-top: -0.2rem;
              color: #2e2c2c;
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 26px;
            }
            div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 12px;
              margin-top: -2rem;
              #LicenceState {
                color: #9ba6bc;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
              }
              #LicencePeriod {
                color: #2a53a9;
                font-family: $first-font;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                border-radius: 4px;
                background: rgba(60, 118, 241, 0.1);
                padding: 4px 7px 4px 7px;
              }

              #LicenceIcon {
                color: #9ba6bc;
                cursor: pointer;
              }
              #LicenceReaction {
                color: #9ba6bc;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
              }
            }
            .LicenceShowMore {
              margin: -5px 20px 10px 0;
              a {
                text-decoration: none;
                button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 4px;
                  border: 1px solid var(--primary-500-base, #3c76f1);
                  color: #3c76f1;
                  font-family: $first-font;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  padding: 5px;
                  background-color: white;
                  gap: 10px;
                }
              }
            }
          }
        }
        .UpdateCertification {
          margin: 0 20px 15px 0;
          a {
            text-decoration: none;
            color: #3c76f1;
          }
        }
      }
    }
  }
}
