@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.AdminUserContainer{
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .AdminUserContent{
        width: 100%;
        gap: 34px;
        @include flex-box($direction: column, $align: flex-start);
        .AdminUserPreview{
            width: 100%;
            gap: 2%;
            @include flex-box();
            .AdminUserPreviewCard{
                width: 24.5%;
                border-radius: 4px;
                padding: 16px;
                box-sizing: border-box;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: var(--Default-White, #FFF);
                p:first-child{
                    padding: 0;
                    margin: 0;
                    color: var(--CoolGray-60, #697077);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
                p:last-child{
                    padding: 0;
                    margin: 0;
                    color: var(--CoolGray-90, #21272A);
                    font-family: $first-font;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 26.4px */
                }
            }
        }
        .AdminUserTableHeading{
            @include flex-box($justify: space-between);
            width: 100%;
            h4{
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                padding: 0;
                margin: 0;
            }
            .AdminUserSearchInput{
                width: 75%;
                @include flex-box($justify: flex-start, $align: center);
                padding: 16px 24px;
                box-sizing: border-box;
                border-radius: 4px;
                gap: 8px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                .AdminUserSearchIcon{
                    height: 24px;
                    width: 24px;
                    color: #9BA6BC;
                }
                .AdminUserSearchTextField{
                    border: none;
                    outline: none;
                    height: auto;
                    width: 100%;
                }
                .AdminUserSearchTextField::placeholder{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                }
            }
            button{
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
                height: 57px;
                select{
                    color: var(--Black-And-White-White, #FFF);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    background-color: transparent;
                    border: none;
                    outline: none;
                }
            }
        }
        .AdminUserTablePaper{
            width: 100%;
            box-sizing: border-box;
            .AdminUserTableContainer{
                width: 100%;
                .AdminUserTable{
                    width: 100%;
                    .AdminUserTableHead{
                        width: 100%;
                        border-radius: 4px 4px 0px 0px;
                        background: var(--Primary-100, #ECF1FE);
                        .AdminUserTableRow{
                            width: 100%;
                            .AdminUserTableCell{
                                color: var(--CoolGray-100, #121619);
                                font-family: $first-font;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 140%; /* 19.6px */
                            }
                        }
                    }
                    .AdminUserTableBody{
                        width: 100%;
                        .AdminUserTableBodyRow{
                            width: 100%;
                            .AdminUserTableBodyCell{
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%; /* 22.4px */
                            }
                        }
                    }
                }
            }
        }
    }
}