@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.ProfileDetailContainer {
  margin-top: -40px;
  display: flex;
  align-items: flex-start;
  padding: 48px;
  .ProfileDetailInnerContainer {
    width: 100%;
    margin: 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
    .ProfilePhotoSection {
      @include container($width: auto);
      position: relative;
      @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
      .ProfilePhotoHeader {
        p {
          font-family: $first-font;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%;
          color: #2e2c2c;
        }
      }
      .ProfilePhotoHandler {
        @include flex-box($justify: space-between, $direction: row, $align: center);
        gap: 80px;
        .ProfileImageLoader {
          @include flex-box($justify: flex-start, $direction: row, $align: center);
          gap: 20px;
          .ProfileImageSpace {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 96px;
            height: 96px;
            border-radius: 100px;
            background: var(--cool-gray-10, #F2F4F8);
          }
          .ProfileImageType {
            @include flex-box($justify: center, $direction: row, $align: center);
            input[type="file"] {
              background-color: white;
              display: flex;
              height: 38px;
              width: 120px;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              border: 1px solid #2a53a9;
              position: relative;
              z-index: 5;
              color: transparent;
              opacity: 0;
            }
            .ProfileImageUpload {
              position: relative;
              display: flex;
              height: 38px;
              width: 120px;
              padding: 8px 12px;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              border: 1px solid #2a53a9;
              background-color: white;
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              color: #2a53a9;
              transform: translateX(-121px);
              z-index: 2;
            }
            .ProfileImageRemove {
              color: #2a53a9;
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              background-color: white;
              border: none;
              transform: translateX(-121px);
            }
          }
        }
        .ProfileImageRequirement {
          border-left: solid 1px #80a0e4;
          padding-left: 35px;
          padding-top: 0px;
          p {
            color: var(--foundation-neutral-black, #2e2c2c);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
          }
          ol {
            li {
              color: var(--foundation-neutral-black, #2e2c2c);
              font-family: $first-font;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              text-transform: capitalize;
            }
          }
        }
      }
    }
    .ProfileLineBreaker {
      width: 100%;
      border: solid 0.5px #9BA6BC;;
      margin-top: 1.5rem;
    }
    .ProfileDetailSection {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
      align-items: flex-start;
      .ProfileDetailHeader {
        h3 {
          font-family: $first-font;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          color: #2e2c2c;
        }
      }
      .ProfileDetailForm {
        width: 100%;
        @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
        margin-bottom: 30px;
        gap: 10px;
        .ProfileTextFields{
          @include flex-box($direction: row);
          gap: 2%;
          width: 100%;
          .ProfileTextField {
            display: flex;
            flex-direction: column;
            height: 35px;
            width: 100%;
            align-items: center;
            gap: 3px;
            border-radius: 4px;
            border: 0.5px solid #d8e4fc;
            margin-top: 1px;
            padding-left: 10px;
          }
          #ProfileTextField {
            display: flex;
            flex-direction: column;
            width: 49%;
            gap: 5px;
            justify-content: flex-start;
            align-items: flex-start;
            label {
              color: var(--cool-gray-90, #21272a);
              font-family: $first-font;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
        .ProfileSelectFieldOne {
          display: flex;
          flex-direction: column;
          gap: 5px;
          height: 35px;
          width: 100%;
          justify-content: center;
          padding-left: 10px;
          align-items: center;
          border-radius: 4px;
          border: 0.5px solid #d8e4fc;
          color: #9ba6bc;
        }
        #ProfileSelectFieldOne {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 5px;
          justify-content: flex-start;
          align-items: flex-start;
          label {
            color: var(--cool-gray-90, #21272a);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }
        .ProfileSelectFields{
          width: 100%;
          gap: 2%;
          @include flex-box();
          .ProfileSelectField {
            display: flex;
            height: 35px;
            width: 100%;
            align-items: center;
            flex-direction: column;
            border-radius: 4px;
            border: 0.5px solid #d8e4fc;
            padding-left: 10px;
            color: #9ba6bc;
            padding-top: 7px;
          }
          #ProfileSelectField {
            display: flex;
            flex-direction: column;
            width: 49%;
            gap: 5px;
            justify-content: flex-start;
            align-items: flex-start;
            label {
              color: var(--cool-gray-90, #21272a);
              font-family: $first-font;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
        
        .ProfileBiography {
          width: 100%;
          height: 200px;
          border-radius: 4px;
          border: 0.5px solid #D8E4FC;
          box-sizing: border-box;
          padding: 12px 16px;
        }
        #ProfileBiography {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start;
          label {
            color: var(--cool-gray-90, #21272a);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .TopProfileSaveButton {
          width: 100%;
          height: 35px;
          display: flex;
          justify-content: flex-end;
          button{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            padding: 16px 12px;
            background-color: transparent;
            border-radius: 4px;
            border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            letter-spacing: 0.5px;
          }
        }
        .ProfileDivider {
          width: 100%;
          border: solid 1px #d1d4db;
          margin-left: -1rem;
          margin-bottom: 20px;
          margin-top: 30px;
        }
        .ProofOfIdentity{
            width: 95%;
            @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: center);
            h3{
                color: var(--foundation-neutral-black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
            }
            .ProofOfIdentityUpload{
                width: 100%;
                @include flex-box($display: flex, $direction: column, $align: center, $justify: center);
                height: 196px;
                padding: 16px;
                border-radius: 4px;
                border: 2px dashed var(--secondary-200, #9BA6BC);
                background: var(--background-primary-bg, #F5F8FA);
                p{
                    color: var(--foundation-neutral-black, #2E2C2C);
                    text-align: center;
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                }
                button{
                    color: var(--foundation-pri-pri-500, #2A53A9);
                    font-family: TT Wellingtons Trl;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    border: none;
                    background: transparent;
                    cursor: pointer;
                }
            }
        }
        .ProfileExperiences {
          margin-top: 10px;
          width: 100%;
          .ProfileExperienceHeader {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            h2 {
              color: var(--foundation-neutral-black, #2e2c2c);
              font-family: $first-font;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
            }
            a {
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: center;
              align-items: center;
              text-decoration: none;
              p {
                color: var(--foundation-pri-pri-500, #2a53a9);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
              }
              .ProfileAddExperience {
                color: var(--foundation-pri-pri-500, #2a53a9);
                width: 20px;
                height: 20px;
              }
            }
          }
          .ProfileExperienceTagNames {
            width: 99.5;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: flex-start;
            align-items: flex-start;
            .AddExperienceData {
              width: 99.5%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              border-radius: 4px;
              border: 1px solid var(--primary-200, #d8e4fc);
              .ExperienceData {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 10px;
                span:first-child {
                  margin-left: 13px;
                }
                span:last-child {
                  display: flex;

                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 3px;
                  border: none;
                  margin-left: 0rem;
                  h5 {
                    color: var(--foundation-neutral-black, #2e2c2c);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    margin-bottom: -1rem;
                  }
                  div {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    flex-wrap: nowrap;

                    p:nth-child(1) {
                      color: var(--secondary-200, #9ba6bc);
                      font-family: $first-font;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                    }
                    p:nth-child(2) {
                      color: var(--foundation-pri-pri-500, #2a53a9);
                      font-family: $first-font;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 600;
                      border-radius: 4px;
                      background: rgba(60, 118, 241, 0.1);
                      padding: 5px;
                      display: flex;
                      justify-content: center;
                      white-space: none;
                      min-width: 70px;
                    }
                    p:nth-child(3) {
                      color: var(--alert-succes-600, #00b374);
                      font-family: $first-font;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 600;
                      border-radius: 4px;
                      background: rgba(0, 179, 116, 0.1);
                      display: flex;
                      justify-content: center;
                      white-space: none;
                      min-width: 70px;
                      padding: 5px;
                    }
                  }
                }
              }
              div {
                margin-right: 20px;
              }
            }
          }
          .ProfileExperienceButton {
              width: 100%;
              height: 35px;
              display: flex;
              justify-content: flex-end;
              button{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding: 16px 12px;
                background-color: transparent;
                border-radius: 4px;
                border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 16px */
                letter-spacing: 0.5px;
              }
          }
        }
        .ImportantSite {
          margin-top: 10px;
          width: 100%;
          display: flex;
          gap: 10px;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .ImportantSiteHeader {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            h2 {
              color: var(--foundation-neutral-black, #2e2c2c);
              font-family: $first-font;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
            }
            a {
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: center;
              align-items: center;
              text-decoration: none;
              p {
                color: var(--foundation-pri-pri-500, #2a53a9);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
              }
              .ProfileAddExperience {
                color: var(--foundation-pri-pri-500, #2a53a9);
                width: 20px;
                height: 20px;
              }
            }
          }
          .LinkTextFields{
            width: 100%;
            box-sizing: border-box;
            @include flex-box($direction: column);
            gap: 16px;
            .LinkTextField {
              display: flex;
              flex-direction: column;
              gap: 5px;
              height: 35px;
              width: 100%;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              border: 0.5px solid #d8e4fc;
              color: #9ba6bc;
            }
            #LinkTextField {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 5px;
              justify-content: flex-start;
              align-items: flex-start;
              label {
                color: var(--cool-gray-90, #21272a);
                font-family: $first-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
              }
            }
          }
          .SaveLinkButton{
            width: 100%;
            height: 35px;
            display: flex;
            justify-content: flex-end;
            border-radius: 4px;
            border: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
          }
        }
        .HandleProceedButton {
          margin-top: 30px;
          button {
            width: 1100px;
            height: 40px;
            border-radius: 4px;
            border: 2px solid #2a53a9;
            color: white;
            background: #2a53a9;
            margin-bottom: 35px;
          }
        }
      }
    }
  }
}
