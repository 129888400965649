@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.JobEditContainer{
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .JobEditContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .JobEditNavigation{
            @include flex-box($justify: flex-start);
            gap: 18px;
            .JobBackIcon{
                height: 24px;
                width: 24px;
                color: #21272A;
            }
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%; /* 26.4px */
            }
        }
        .JobEditTitle{
            width: 100%;
            @include flex-box($justify: flex-start);
            gap: 2%;
            margin-bottom: 38px;
            #JobEditTextField{
                width: 49%;
                @include flex-box($direction: column, $align: flex-start);
                gap: 8px;
                label{
                    color: var(--CoolGray-90, #21272A);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
            .JobEditTextField{
                width: 100%;
                height: 48px;
                outline: none;
                padding: 12px 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid var(--Secondary-200, #9BA6BC);
            }
            .JobEditTextField::placeholder{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
            }
            #JobEditSelectField{
                width: 49%;
                @include flex-box($direction: column, $align: flex-start);
                gap: 8px;
                label{
                    color: var(--CoolGray-90, #21272A);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
            .JobEditSelectField{
                width: 100%;
                height: 48px;
                padding: 12px 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid var(--Secondary-200, #9BA6BC);
                outline: none;
                option{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                }
            }
        }
        .JobEditSelectFields{
            width: 100%;
            gap: 2%;
            @include flex-box($justify: flex-start);
            margin-bottom: 38px;
            #JobEditSelectField{
                @include flex-box($direction: column, $align: flex-start);
                width: 49%;
                gap: 8px;
                label{
                    color: var(--CoolGray-90, #21272A);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            } 
            .JobEditSelectField{
                padding: 12px 16px;
                height: 48px;
                width: 100%;
                border-radius: 4px;
                border: 0.5px solid var(--Secondary-200, #9BA6BC);
                outline: none;
            }
        }
        h2{
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            padding: 0;
            margin: 0;
            margin-bottom: 15px;
        }
        .JobDetailsJobInfo{
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 15px;
            padding: 32px 24px;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            h4{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 33.6px */
                margin: 0;
                padding: 0;
            }
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                margin: 0;
                padding: 0;
            }
            ul{
                width: 100%;
                li{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin: 0;
                    padding: 0;  
                }     
            }
        }
        .JobEditSaveButton{
            width: 100%;
            height: 48px;
            @include flex-box($justify: flex-end);
            button{
                color: var(--Black-And-White-White, #FFF);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                padding: 8px 16px;
                height: 48px;
                width: 20%;
                border: none;
                border-radius: 4px;
                background: var(--Foundation-PRI-pri-500, #2A53A9);
                cursor: pointer;
            }
        }
    }
}