@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.ReviewContainer {
    width: 100%;
    @include flex-box($direction: column, $align: flex-start);
    gap: 128px;
    .ReviewAccordionWrapper {
        width: 100%;
        @include flex-box($direction: column, $align: flex-start);
        gap: 24px;
        .AccordionDivider {
            width: 100%;
            height: 0.5px;
            background: #9BA6BC;
        }
        .ReviewAccordion {
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            .AccordionHeading {
                @include flex-box($justify: space-between);
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
                border-radius: 4px;
                background: var(--Opacity-Opacity-20, rgba(0, 0, 0, 0.05));
                border: 0.5px solid var(--Foundation-PRI-pri-100, #BDCAE4);
                span {
                    @include flex-box($justify: flex-start);
                    gap: 8px;
                    h3 {
                        color: var(--Grey-Grey-600, #4B4B4B);

                        /* Heading/H7 */
                        font-family: $first-font;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%; /* 22px */
                        padding: 0;
                        margin: 0;
                    }
                    p {
                        color: var(--Grey-Grey-600, #4B4B4B);

                        /* Body/Medium */
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 22.4px */
                        padding: 0;
                        margin: 0;
                    }
                    .AccordionIcon {
                        height: 24px;
                        width: 24px;
                    }
                    .rotate {
                        transform: rotate(180deg);
                    }
                }
            }
            .AccordionContent {
                @include flex-box($direction: column, $align: flex-start);
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
                max-height: 0;
                overflow: hidden;
                display: none;
                transition: all 0.5s;
                .CompanyInfo, .JobInfos {
                    width: 100%;
                    margin-top: 24px;
                    @include flex-box($justify: space-between);
                    .Info, .JobInfo {
                        width: 49%;
                        @include flex-box($direction: column, $align: flex-start);
                        gap: 8px;
                        p {
                            color: var(--Grey-Grey-600, #4B4B4B);

                            /* Body/Regular */
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                            margin: 0;
                            padding: 0;
                        }
                        span {
                            color: var(--Grey-Grey-600, #4B4B4B);
                            font-family: $first-font;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 19.6px */
                            width: 100%;
                            height: 48px;
                            padding: 12px 16px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            border: 1.5px solid var(--Secondary-100, #CED3DD);
                            background: var(--Opacity-Opacity-20, rgba(0, 0, 0, 0.05));
                        }
                    }

                }
                .PageDivider {
                    width: 100%;
                    background: #9BA6BC;
                    height: 0.5px;
                    margin-top: 24px;
                }
                .AboutCompany, .JobDesc {
                    @include flex-box($direction: column, $align: flex-start);
                    width: 100%;
                    margin-top: 24px;
                    gap: 8px;
                    p {
                        color: var(--Grey-Grey-600, #4B4B4B);

                            /* Body/Regular */
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                        margin: 0;
                        padding: 0;
                    }
                    span {
                        color: var(--Grey-Grey-600, #4B4B4B);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                        width: 100%;
                        padding: 12px 16px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        border: 1.5px solid var(--Secondary-100, #CED3DD);
                        background: var(--Opacity-Opacity-20, rgba(0, 0, 0, 0.05));
                    }
                }
                .TabContainer {
                    width: 100%;
                    margin-top: 24px;
                    @include flex-box($justify: space-between);
                    .Tabs {
                        gap: 48px;
                        @include flex-box($justify: flex-start);
                        width: 65%;
                        border-bottom: 1px solid var(--Primary-200, #D8E4FC);
                        .Tab {
                            padding: 16px 0px;
                            color: var(--CoolGray-40, #A2A9B0);

                            /* Body/Medium */
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 140%; /* 22.4px */
                            height: 48px;
                            box-sizing: border-box;
                            border: none;
                            background-color: transparent;
                        }
                        
                    }
                    .TabNav {
                        @include flex-box($justify: flex-end);
                        width: 20%;
                        gap: 20px;
                        height: 48px;
                        .IconWrapper {
                            display: flex;
                            width: 48px;
                            height: 100%;
                            padding: 12px;
                            box-sizing: border-box;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                            background: rgba(42, 83, 169, 0.05);
                            .TabIcon {
                                height: 24px;
                                width: 24px;
                                color: #2A53A9;
                            }
                        }
                    }
                }
                .CriteriaContent {
                    @include flex-box($direction: column, $align: flex-start);
                    width: 100%;
                    display: none;
                    h6 {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%; /* 25.2px */
                        padding: 0;
                        margin: 0;
                        margin-top: 16px;
                    }
                    p {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                        padding: 0;
                        margin: 0;
                        margin-top: 5px;
                    }
                    .SelectedFields {
                        width: 100%;
                        @include flex-box($justify: space-between);
                        margin-top: 87px;
                        .SelectedField {
                            @include flex-box($direction: column, $align: flex-start);
                            width: 48%;
                            gap: 8px;
                            p {
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 140%; /* 22.4px */
                                padding: 0;
                                margin: 0;
                            }
                            .SpanWrapper {
                                @include flex-box($direction: column, $align: flex-start);
                                width: 100%;
                                span {
                                    width: 100%;
                                    height: 47px;
                                    box-sizing: border-box;
                                    padding: 12px 16px;
                                    border-radius: 4px;
                                    border: 1.5px solid var(--Secondary-100, #CED3DD);
                                    background: var(--Opacity-Opacity-20, rgba(0, 0, 0, 0.05));
                                    color: var(--Grey-Grey-600, #4B4B4B);

                                    /* Body/Regular */
                                    font-family: $first-font;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 140%; /* 22.4px */
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                    .WeightTotal {
                        @include flex-box($justify: space-between);
                        width: 100%;
                        margin-top: 98px;
                        margin-bottom: 77px;
                        h4 {
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%; /* 19.8px */
                            padding: 0;
                            margin: 0;
                        }
                        span {
                            width: 50%;
                            height: 47px;
                            box-sizing: border-box;
                            padding: 12px 16px;
                            border-radius: 4px;
                            border: 0.5px solid var(--Grey-Grey-300, #C8C8C8);
                            background: var(--Grey-Grey-200, #E1E1E1);
                            color: var(--Grey-Grey-600, #4B4B4B);

                            /* Body/Regular */
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                        }
                    }
                }
                .display {
                    display: flex;
                }
                .TargetContent {
                    @include flex-box($direction: column, $align: flex-start);
                    width: 100%;
                    display: none;
                    h6 {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%; /* 25.2px */
                        padding: 0;
                        margin: 0;
                        margin-top: 16px;
                    }
                    p {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                        padding: 0;
                        margin: 0;
                        margin-top: 5px;
                        margin-bottom: 87px;
                    }
                    .FieldAreas {
                        @include flex-box($justify: space-between);
                        width: 100%;
                        margin-bottom: 32px;
                        .ScoreWrapper {
                            width: 48%;
                            @include flex-box($direction: column, $align: flex-start);
                            gap: 8px;
                            p {
                                padding: 0;
                                margin: 0;
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 140%; /* 22.4px */
                            }
                            span {
                                border-radius: 4px;
                                color: var(--CoolGray-90, #21272A);
                                font-family: $first-font;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 110%; /* 19.8px */
                                height: 48px;
                                box-sizing: border-box;
                                padding: 12px 16px;
                            }
                        }
                        .FieldArea {
                            width: 48%;
                            @include flex-box($direction: column, $align: flex-start);
                            gap: 8px;
                            p {
                                padding: 0;
                                margin: 0;
                                color: var(--Foundation-Neutral-Black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 140%; /* 22.4px */
                            }
                            span {
                                width: 100%;
                                padding: 12px 16px;
                                height: 48px;
                                box-sizing: border-box;
                                border-radius: 4px;
                                border: 1.5px solid var(--Secondary-100, #CED3DD);
                                background: var(--Opacity-Opacity-20, rgba(0, 0, 0, 0.05));
                            }
                        }
                    }
                }
                .render {
                    display: flex;
                }
                .QuantifyContent {
                    width: 100%;
                    @include flex-box($direction: column, $align: flex-start);
                    display: none;
                    h6 {
                        color: var(--Grey-Grey-600, #4B4B4B);
                        font-family: $first-font;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%; /* 25.2px */
                        padding: 0;
                        margin: 0;
                        margin-top: 16px;
                    }
                    p {
                        margin-top: 5px;
                        padding: 0;
                        color: var(--Grey-Grey-600, #4B4B4B);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                    }
                    .Text {
                        color: var(--Grey-Grey-600, #4B4B4B);
                        text-align: center;
                        font-family: $first-font;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%; /* 19.8px */
                        width: 95%;
                        margin-top: 24px;
                    }
                    .AccordionContainer {
                        width: 100%;
                        @include flex-box($direction: column, $align: flex-start);
                        gap: 24px;
                        margin-top: 32px;
                        .Accordion {
                            width: 100%;
                            @include flex-box($direction: column, $align: flex-start);
                            .AccordionHead {
                                @include flex-box($justify: space-between);
                                width: 100%;
                                height: 48px;
                                box-sizing: border-box;
                                padding: 12px;
                                border-radius: 4px;
                                background: var(--Opacity-Opacity-20, rgba(0, 0, 0, 0.05));
                                border: 0.5px solid var(--Foundation-PRI-pri-100, #BDCAE4);
                                h2 {
                                    color: var(--Foundation-Neutral-Black, #2E2C2C);

                                    /* Heading/H7 */
                                    font-family: $first-font;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 110%; /* 22px */
                                    padding: 0;
                                    margin: 0;
                                }
                                .IconWrapper {
                                    @include flex-box($justify: flex-start);
                                    gap: 8px;
                                    p {
                                        padding: 0;
                                        margin: 0;
                                        color: var(--Foundation-Neutral-Black, #2E2C2C);

                                        /* Body/Medium */
                                        font-family: $first-font;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 140%; /* 22.4px */
                                    }
                                    .Icon {
                                        height: 24px;
                                        width: 24px;
                                        color: #21272A;
                                    }
                                    .rotate {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                            .AccordionCont {
                                width: 100%;
                                @include flex-box($direction: column, $align: flex-start);
                                padding: 12px;
                                border-radius: 8px;
                                border: 1px solid var(--Foundation-PRI-pri-100, #BDCAE4);
                                background: var(--Opacity-Opacity-20, rgba(0, 0, 0, 0.05));
                                box-sizing: border-box;
                                margin-top: 16px;
                                display: none;
                                max-height: 0;
                                .DataAreas {
                                    width: 100%;
                                    gap: 8px;
                                    @include flex-box($direction: column, $align: flex-start);
                                    .Texts {
                                        @include flex-box($justify: space-between);
                                        width: 100%;
                                        p {
                                            width: 48%;
                                            padding: 0;
                                            margin: 0;
                                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                                            font-family: $first-font;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 140%; /* 22.4px */
                                        }
                                    }
                                    .SpanArea {
                                        @include flex-box($justify: space-between);
                                        width: 100%;
                                        gap: 24px;
                                        span {
                                            width: 48%;
                                            height: 48px;
                                            padding: 12px 16px;
                                            border-radius: 4px;
                                            border: 1.5px solid var(--Foundation-PRI-pri-100, #BDCAE4);
                                            box-sizing: border-box;
                                        }
                                    }
                                }
                                .TotalPoints {
                                    width: 100%;
                                    @include flex-box($justify: space-between);
                                    margin-top: 35px;
                                    h5 {
                                        width: 48%;
                                        padding: 0;margin: 0;
                                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                                        font-family: $first-font;
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 110%; /* 19.8px */
                                    }
                                    span {
                                        color: var(--Grey-Grey-600, #4B4B4B);

                                        /* Body/Regular */
                                        font-family: $first-font;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 140%; /* 22.4px */
                                        width: 48%;
                                        box-sizing: border-box;
                                        height: 47px;
                                        padding: 12px 16px;
                                        border-radius: 4px;
                                        border: 0.5px solid var(--Grey-Grey-300, #C8C8C8);
                                        background: var(--Grey-Grey-200, #E1E1E1);
                                    }
                                }
                            }
                            .view {
                                display: flex;
                                height: auto;
                                max-height: 9999px;
                                transition: all 0.5s cubic-bezier(0,1,0,1);
                            }
                        }
                    }
                }
                .reveal {
                    display: flex;
                }
            }
            .AccordionContent.show {
                display: flex;
                height: auto;
                max-height: 9999px;
                transition: all 0.5s cubic-bezier(0,1,0,1);
            }
        }
    }
    .ReviewButtons {
        @include flex-box($justify: space-between);
        width: 100%;
        button:first-child {
            display: flex;
            width: 202px;
            height: 57px;
            box-sizing: border-box;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
            background-color: transparent;
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        } 
        button:last-child {
            display: flex;
            width: 202px;
            height: 57px;
            box-sizing: border-box;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Black-And-White-White, #FFF);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
    }
}