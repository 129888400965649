@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";


.CoachPageContainer{
  @include container($width: 90%);
  position: relative;
  margin-top: 1.5rem;
  margin-left: 9%;
  flex-grow: 2;
  overflow-x: hidden;
  .BackToPage{
    display: flex;
    button{
      color: rgba(0, 0, 0, 0.80);
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      background-color: white;
      border: none;
      cursor: pointer;
    }
  }
  .AboutCoach{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 18px;
    gap: 30px;
    .CoachDetails{
      border-radius: 4px;
      border: 1px solid var(--primary-200, #D8E4FC);
      background: #FFF;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 350px;
      h2{
        color: #2A53A9;
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        margin-left: 15px;
      }
      .coachesExperience {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-left: 15px;
        margin-top: -30px;
        #yearsOfExperience {
          border-radius: 4px;
          background: rgba(60, 118, 241, 0.1);
          color: #2a53a9;
          font-family: $first-font;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          padding: 3px 4px;
        }
        span {
          border-radius: 4px;
          background: rgba(255, 187, 56, 0.1);
          color: #ffbb38;
          font-family: $first-font;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          padding: 3px 4px;
        }
        #rating {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #2e2c2c;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0.2px;
          
          .rating {
              width: 16px;
              height: 16px;
              color: #2a53a9;
            }
        }
      }
      #CoachAbout{
        color:  #2E2C2C;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        text-align: justify;
        width: 95%;
        margin-left: 15px;
        margin-top: -12px;
      }
    }
  }
  .CoachAchievement{
    display: grid;
    grid-template-columns: 30% 69%;
    gap: 10px;
    margin-top: 40px;
    margin-bottom: 50px;
    .SectionOne{
      border-radius: 4px;
      border: 0.5px solid #D8E4FC;
      min-height: 400px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px;
      .SectionOneLabel{
        color:  #2A53A9;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-top: 5px;
      }
      .CoachLineBreaker{
        border: solid 0.5px #D8E4FC;
        width: 95%;
        height: 0.5px;
        margin-left: -30px;
        margin-top: -10px;
      }
      #CoachLineBreaker{
        border: solid 0.5px #D8E4FC;
        width: 80%;
        margin-top: 10px;
        margin-left: -7px;
      }
      .AreaofExpertise{
        h3{
          color: #2E2C2C;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
        ul{
          li{
            color: #2E2C2C;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
      .MentorshipRoadmap{
        h2{
          color:  #2E2C2C;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
        p{
          color:  #2E2C2C;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          #MentorshipIcons{
            transform: translateY(0.3rem);
            margin-right: 5px;
            color: #9BA6BC;
          }
        }
      }
    }
    .SectionTwo{
      min-height: 400px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0px 20px 20px 20px;
      .SectionTwoLabel{
        color: #2E2C2C;
        font-family: $first-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        margin-top: -7px;
      }
      #CoachLineBreakerTwo{
        border: solid 0.5px #D8E4FC;
        width: 95%;
        margin-left: -10px;
        height: 0.5px;
        margin-top: -10px;
      }
      .CoachVerification{
        border-radius: 4px;
        border: 1px solid #D8E4FC;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        min-height: 60px;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 99%;
        span{
          display: flex;
          width: 48px;
          height: 48px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 24px;
          background: #ECF1FE;
          margin-left: 16px;
          #MentorshipIcons{
            color: #00B374;
            width: 24px;
            height: 24px;
          }
        }
        p{
          color: #2E2C2C;
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
      }
      button{
        border-radius: 4px;
        border: 2px solid #2A53A9;
        background: #2A53A9;
        color: #FFF;
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        width: 99.5%;
        height: 40px;
        cursor: pointer;
      }
    }

  }
}
