@import "../../static/styles/shared.scss";
@import "../../static/styles/variables.styles.scss";

.AdminSettingsContainer {
	@include container($width: 90%);
	position: relative;
	margin-top: 1.5rem;
	margin-left: 9%;
	margin-bottom: 3%;
	flex-grow: 2;
	overflow-x: hidden;
	.ProfileSettings {
		border-radius: 4px;
		border: 0.5px solid
			var(--Primary-200, #d8e4fc);
		background: var(--Default-White, #fff);
		display: flex;
		flex-direction: column;
		gap: 12px;
		.ProfileHeader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 12px;
			h1 {
				color: var(
					--Foundation-PRI-pri-500,
					#2a53a9
				);
				font-family: $first-font;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
			}
			.ProfileIcon {
				width: 20px;
				height: 20px;
				color: #2a53a9;
			}
		}
		.CourseInstructor {
			width: 97.3%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			border-radius: 4px;
			gap: 12px;
			padding: 16px;
			margin-top: 40px;
			.CourseInstructorProfileWrapper {
				width: 98%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 2%;
				.CourseInstructorProfile {
					width: 43%;
					border-right: solid 1px #f2f4f8;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					.CourseImage {
						background-color: #f2f4f8;
						border-radius: 100%;
						padding: 40px;
						width: 20px;
						height: 20px;
						color: #9ba6bc;
					}
					.CourseImageInput {
						width: 120px;
						height: 40px;
						transform: translateX(35px);
						border: solid 1px;
						z-index: 15;
						position: relative;
						margin-left: -30px;
						opacity: 0;
					}
					#UploadPhoto {
						border-radius: 4px;
						border: 1px solid #2a53a9;
						color: #2a53a9;
						font-family: $first-font;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						height: 40px;
						background-color: transparent;
						margin-left: -85px;
						padding: 0 20px;
					}
					.RemovePhoto {
						color: #2a53a9;
						font-family: $first-font;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						border: none;
						height: 40px;
						background-color: transparent;
					}
				}
				.CourseInstructorCondition {
					padding-left: 40px;
					display: flex;
					flex-direction: column;
					gap: 10px;
					justify-content: flex-start;
					align-items: flex-start;
					p {
						color: #2e2c2c;
						font-family: $first-font;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						margin-bottom: -15px;
						margin-top: 10px;
					}
					ol {
						li {
							color: var(
								--Foundation-Neutral-Black,
								#2e2c2c
							);
							font-family: $first-font;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
						}
					}
				}
			}
			.InstructorName {
				width: 98%;
				flex-wrap: wrap;
				border-radius: 4px;
				padding: 12px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-top: 40px;
				gap: 4%;
				.InputField {
					width: 47%;
					display: flex;
					flex-wrap: nowrap;
					flex-shrink: 0;
					flex-direction: column;
					gap: 5px;
					justify-content: flex-start;
					align-items: flex-start;
					margin-bottom: 20px;
					label {
						color: #2e2c2c;
						font-family: $first-font;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
					}
					#InputField {
						width: 100%;
						height: 35px;
						padding-left: 12px;
						border-radius: 4px;
						border: 0.5px solid
							var(--Primary-200, #d8e4fc);
						color: var(--Secondary-200, #9ba6bc);
						font-family: $first-font;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						background-color: none;
					}
				}
			}
			.SaveButton {
				width: 98%;
				display: flex;
				justify-content: flex-end;
				margin-top: -20px;
				button {
					border-radius: 4px;
					border: 2px solid
						var(--Foundation-PRI-pri-500, #2a53a9);
					width: 75px;
					height: 40px;
					background-color: transparent;
					color: var(
						--Foundation-PRI-pri-500,
						#2a53a9
					);
					font-family: $first-font;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
				}
			}
		}
	}
	.DisplaySetting {
		@include flex-box($direction: column, $align: flex-start);
		width: 100%;
		height: 447px;
		padding: 16px;
		box-sizing: border-box;
		margin-top: 50px;
		border-radius: 4px;
		border: 1px solid var(--Primary-200, #D8E4FC);
		box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05);
		gap: 32px;
		h1 {
			color: var(--Foundation-Neutral-Black, #2E2C2C);
			font-family: $first-font;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 110%; /* 26.4px */
			padding: 0;
			margin: 0;
		}
		.LightMode {
			@include flex-box($direction: column, $align: flex-start);
			gap: 4px;
			h5 {
				color: var(--Foundation-Neutral-Black, #2E2C2C);
				font-family: $first-font;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 28px; /* 175% */
				padding: 0;
				margin: 0;
			}
			p {
				color: #000;
				font-family: $first-font;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 22.4px */
				padding: 0;
				margin: 0;
			}
		}
		.ToggleModes {
			@include flex-box($direction: column, $align: flex-start);
			gap: 16px;
			width: 100%;
			.ToggleMode {
				width: 100%;
				@include flex-box($justify: space-between);
				p {
					color: var(--Foundation-Neutral-Black, #2E2C2C);
					font-family: $first-font;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 22.4px */
					padding: 0;
					margin: 0;
				}
			}
		}
		.FontSize {
			@include flex-box($justify: space-between);
			width: 100%;
			h5 {
				padding: 0;
				margin: 0;
				color: var(--Foundation-Neutral-Black, #2E2C2C);
				font-family: $first-font;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 28px; /* 175% */
			}
			button {
				display: flex;
				height: 48px;
				padding: 8px 16px;
				justify-content: center;
				align-items: center;
				border-radius: 4px;
				border: 1px solid var(--Primary-300, #C5D6FB);
				background: var(--Foundation-PRI-pri-500, #2A53A9);
				select {
					background: transparent;
					border: none;
					outline: none;
					color: var(--Grey-Grey-100, #FAFAFA);
					font-family: $first-font;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 125% */
				}
			}
		}
	}
}
