@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.MessagingNotificationsContainer{
    width: 100%;
    position: relative;
    background: #FFF;
    flex-grow: 2;
    overflow-x: hidden;
    .MessagingNotificationsContent{
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;
        margin: 20px;
        padding: 16px;
        background: #FFF;
        box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
        .MessagingNotificationsHeading{
            margin-bottom: 30px;
            padding: 12px 16px;
            height: 48px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            @include flex-box($justify: space-between);
            h6{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 22px */
                margin: 0;
                padding: 0;
            }
        }
        .MessagingCheckboxHeadings{
            @include flex-box();
            width: 100%;
            margin-bottom: 50px;
            .MessagingCheckboxHeading{
                width: 20%;
                @include flex-box($direction: column);
                h6{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px; /* 175% */
                    margin: 0;
                    padding: 0;
                }
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }

            }
        }
        .MessagingCheckBoxes{
            @include flex-box($direction: column);
            width: 100%;
            margin-bottom: 40px;
            gap: 40px;
            .MessagingCheckBox{
                @include flex-box($justify: space-between);
                width: 100%;
                .MessagingCheckBoxText{
                    @include flex-box($direction: column, $align: flex-start);
                    gap: 8px;
                    width: 50%;
                    h6{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px; /* 125% */
                        margin: 0;
                        padding: 0;
                    }
                    p{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                        margin: 0;
                        padding: 0;
                    }
                }
                #MessagingCheckBox{
                    width: 50%;
                }
            }
        }
        .MessagingNotificationsFrequency{
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            gap: 40px;
            h6{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; /* 125% */
                    margin: 0;
                    padding: 0;
                }
            .MessagingRadioButtons{
                @include flex-box($direction: column, $align: flex-start);
                width: 100%;
                gap: 40px;
                #MessagingRadioButton{
                    width: 50%;
                    height: 32px;
                    @include flex-box($justify:flex-start, $align: center);
                    label{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
            }
        }
    }
}