@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.LayoutContainer {
    @include flex-box($direction: column, $align: flex-start );
    gap: 27px;
    width: 100%;
    h4 {
        color: var(--Foundation-PRI-pri-500, #2A53A9);
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 17.6px */
        padding: 0;
        margin: 0;
    }
    .LayoutContent {
        @include flex-box($direction: column, $align: flex-start);
        width: 100%;
        padding: 24px 16px 16px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 0.5px solid var(--Foundation-PRI-pri-100, #BDCAE4);
        box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05); 
        .LayoutTabsContainer {
            @include flex-box($justify: space-between);
            width: 100%;
            .LayoutTabs {
                width: 50%;
                gap: 5%;
                @include flex-box($justify: flex-start);
                border-bottom: 1px solid var(--Primary-200, #D8E4FC);
                .LayoutTab {
                    text-decoration: none;
                    button {
                        background-color: transparent;
                        border: none;
                        display: flex;
                        height: 48px;
                        padding: 16px 0px;
                        justify-content: center;
                        align-items: center;
                        color: var(--CoolGray-40, #A2A9B0);
    
                        /* Body/Medium */
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 22.4px */
                    }
                }
            }
            .TabNavigation {
                width: 10%;
                @include flex-box($justify: space-between);
                .IconWrapper {
                    @include flex-box();
                    width: 48px;
                    height: 48px;
                    padding: 12px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    background: rgba(42, 83, 169, 0.05);
                    .TabIcon {
                        width: 24px;
                        height: 24px;
                        color: #2A53A9;
                    }
                }
            }
        }  
        .RelevanceTargetOptions {
            margin-top: 16px;
            width: 100%;
        }        
    }
    
}
.WeightContainer {
    @include flex-box($direction: column, $align: flex-start);
    width: 100%;
    h6 {
        color: var(--Foundation-Neutral-Black, #2E2C2C);
        font-family: $first-font;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
        padding: 0;
        margin: 0;
    }
    p {
        color: var(--Foundation-Neutral-Black, #2E2C2C);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        padding: 0;
        margin: 0;
        margin-top: 5px;
    }
    .WeightTextFields{
        margin-top: 16px;
        width: 100%;
        @include flex-box($justify: space-between, $align: flex-end);
        #TextField {
            width: 49%;
            @include flex-box($direction: column, $align: flex-start);
            gap: 8px;
            .LabelWrapper {
                @include flex-box();
                width: 100%;
                height: auto;
                gap: 16px;
                p {
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 22.4px */
                }
                .InfoIcon {
                    height: 24px;
                    width: 24px;
                    color: #2A53A9;
                }
            }
        }
        .TextField {
            width: 100%;
            height: 48px;
            padding: 12px 16px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1.5px solid var(--Foundation-PRI-pri-100, #BDCAE4);
        }
        .TextField::placeholder {
            color: var(--Foundation-Neutral-Black, #2E2C2C);

            /* Body/Regular */
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
        }
    }
    span {
        @include flex-box($justify: flex-end);
        width: 100%;
        gap: 1%;
        margin-top: 10px;
        cursor: pointer;
        p {
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            text-align: right;

            /* Body/Medium */
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
        }
        .AddIcon {
            height: 24px;
            width: 24px;
            color: #2A53A9;
        }
    }
    .TotalWeight {
        @include flex-box($justify: space-between);
        width: 100%;
        margin-top: 66px;
        h4 {
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 19.8px */
        }
        .WeightTotal {
            @include flex-box($justify: flex-start);
            width: 50%;
            height: 48px;
            padding: 12px 16px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 0.5px solid var(--Grey-Grey-300, #C8C8C8);
            background: var(--Grey-Grey-200, #E1E1E1);
            color: var(--Grey-Grey-600, #4B4B4B);

            /* Body/Regular */
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
        }
    }
    .WeightButtons {
        width: 100%;
        @include flex-box($justify: space-between);
        margin-top: 123px;
        button:first-child {
            display: flex;
            height: 57px;
            width: 202px;
            box-sizing: border-box;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            cursor: pointer;
        }
        button:last-child {
            display: flex;
            height: 57px;
            width: 202px;
            box-sizing: border-box;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Black-And-White-White, #FFF);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            cursor: pointer;
        }
    }
}
.TargetContainer {
    @include flex-box($direction: column, $align: flex-start);
    width: 100%;
    gap: 24px;
    h6 {
        color: var(--Foundation-Neutral-Black, #2E2C2C);
        font-family: $first-font;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
        padding: 0;
        margin: 0;
    }
    .Para {
        color: var(--Foundation-Neutral-Black, #2E2C2C);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        padding: 0;
        margin: 0;
        margin-top: -19px;
    }
    .TargetScores {
        @include flex-box($justify: space-between);
        width: 100%;
        .ScoreWrapper {
            width: 48%;
            @include flex-box($direction: column, $align: flex-start);
            gap: 8px;
            p {
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 22.4px */
                padding: 0;
                margin: 0;
            }
            span {
                display: flex;
                height: 48px;
                padding: 12px 16px;
                align-items: center;
                box-sizing: border-box;
                color: var(--CoolGray-90, #21272A);
                font-family: $first-font;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 19.8px */
            }
        }
        #TextField {
            @include flex-box($direction: column, $align: flex-start);
            width: 50%;
            gap: 8px;
            .LabelWrapper {
                @include flex-box($justify: flex-start);
                gap: 8px;
                p {
                    margin: 0;
                    padding: 0;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 22.4px */
                }
                .InfoIcon {
                    height: 24px;
                    width: 24px;
                    color: #2A53A9;
                }
            }
        }
        .TextField {
            width: 100%;
            height: 48px;
            padding: 12px 16px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1.5px solid var(--Foundation-PRI-pri-100, #BDCAE4);
        }
        .TextField::placeholder {
            color: var(--Foundation-Neutral-Black, #2E2C2C);

            /* Body/Regular */
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
        }
    }
    .TargetButtons {
        @include flex-box($justify: space-between);
        width: 100%;
        button:first-child {
            display: flex;
            width: 202px;
            height: 57px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: transparent;
            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
        button:last-child {
            display: flex;
            width: 202px;
            height: 57px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Black-And-White-White, #FFF);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;

        }
    }
}
.CriteriaContainer {
    @include flex-box($direction: column, $align: flex-start);
    width: 100%;
    h6 {
        color: var(--Foundation-Neutral-Black, #2E2C2C);
        font-family: $first-font;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
    }
    p {
        padding: 0;
        margin: 0;
        color: var(--Foundation-Neutral-Black, #2E2C2C);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }
    span {
        margin-top: 28px;
        color: var(--Foundation-Neutral-Black, #2E2C2C);
        text-align: center;
        font-family: $first-font;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 19.8px */
        width: 95%;
        @include flex-box();
        padding: 0;
    }
    .CriteriaAccordionWrapper {
        @include flex-box($direction: column, $align: flex-start);
        margin-top: 19px;
        width: 100%;
        gap: 24px;
        border-radius: 8px;
        padding: 8px 12px;
        box-sizing: border-box;
        border: 1px solid var(--Foundation-PRI-pri-100, #BDCAE4);
        .CriteriaAccordion {
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            .AccordionHeading {
                @include flex-box($justify: space-between, $align: center);
                width: 100%;
                height: 48px;
                border-radius: 4px;
                border: 0.5px solid var(--Foundation-PRI-pri-100, #BDCAE4);
                padding: 12px;
                box-sizing: border-box;
                cursor: pointer;
                h4 {
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);

                    /* Heading/H7 */
                    font-family: $first-font;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 22px */
                }
                .IconWrapper {
                    @include flex-box($justify: flex-end);
                    gap: 8px;
                    p {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);

                        /* Body/Medium */
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 22.4px */
                    }
                    .AccordionIcon {
                        height: 24px;
                        width: 24px;
                    }
                    .rotate {
                        transform: rotate(180deg);
                    }
                }
            }
            .AccordionContent {
                @include flex-box($direction: column, $align: flex-start);
                width: 100%;
                box-sizing: border-box;
                max-height: 0;
                overflow: hidden;
                transition: all 0.5s cubic-bezier(0,1,0,1);
                .Text {
                    @include flex-box($justify: space-between);
                    width: 100%;
                    margin-top: 35px;
                    margin-bottom: 8px;
                    p {
                        width: 48%;
                        padding: 0;
                        margin: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%; /* 22.4px */
                    }
                }
                .TextFields {
                    @include flex-box($justify: space-between);
                    width: 100%;
                    margin-bottom: 35px;
                    #SelectField {
                        width: 48%;
                        @include flex-box($direction: column, $align: flex-start);
                        gap: 8px;
                        label {
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 140%; /* 22.4px */
                        }
                    }
                    .SelectField {
                        width: 100%;
                        outline: none;
                        height: 48px;
                        padding: 12px 16px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        border: 1.5px solid var(--Foundation-PRI-pri-100, #BDCAE4);
                    }
                    #TextField {
                        width:48%;
                        @include flex-box($direction: column, $align: flex-start);
                        gap: 8px;
                        label {
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 140%; /* 22.4px */
                        }
                    }
                    .TextField {
                        width: 100%;
                        height: 48px;
                        padding: 12px 16px;
                        box-sizing: border-box;
                        border: none;
                        border-radius: 4px;
                        border-bottom: 1px solid var(--CoolGray-30, #C1C7CD);   
                        outline: none;
                    }
                    .TextField::placeholder {
                        color: var(--Secondary-200, #9BA6BC);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                    }
                    
                }
                .AddAnother {
                    @include flex-box($justify: flex-start);
                    position: relative;
                    left: 88%;
                    width: 20%;
                    gap: 10px;
                    cursor: pointer;
                    p {
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        text-align: right;

                        /* Body/Medium */
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 22.4px */
                        padding: 0;
                        margin: 0;
                    }
                    .AddIcon {
                        height: 24px;
                        width: 24px;
                        color: #2A53A9;
                    }
                }
                .TotalPoint {
                    @include flex-box($justify: space-between);
                    width: 100%;
                    margin: 54px 0;
                    h4 {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%; /* 19.8px */
                    }
                    .PointsTotal {
                        @include flex-box($justify: flex-start);
                        width: 50%;
                        height: 48px;
                        padding: 12px 16px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        border: 0.5px solid var(--Grey-Grey-300, #C8C8C8);
                        background: var(--Grey-Grey-200, #E1E1E1);
                        color: var(--Grey-Grey-600, #4B4B4B);

                        /* Body/Regular */
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
            }
            .AccordionContent.show {
                height: auto;
                max-height: 9999px;
                transition: all 0.5s cubic-bezier(0,1,0,1);
            }
        }
    }
    .CriteriaButtons {
        margin: 75px 0px;
        width: 100%;
        @include flex-box($justify: space-between);
        margin-top: 123px;
        button:first-child {
            display: flex;
            height: 57px;
            width: 202px;
            box-sizing: border-box;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
        button:last-child {
            display: flex;
            height: 57px;
            width: 202px;
            box-sizing: border-box;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
            color: var(--Black-And-White-White, #FFF);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
    }

}