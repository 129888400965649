@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.ConfirmVericationContainer{
    @include container($width: 90%);
    position: relative;
    margin-top: 49px;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .ContainerWrapper{
        width: 99%;
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        border-right: 1px solid var(--Primary-200, #D8E4FC);
        border-bottom: 1px solid var(--Primary-200, #D8E4FC);
        border-left: 1px solid var(--Primary-200, #D8E4FC);
        background: var(--Default-White, #FFF);
        padding: 48px;
        box-sizing: border-box;
        .TopSection{
            display: flex;
            align-items: flex-start;
            button{
                font-family: $first-font;
                font-size: 24px;
                font-weight: 600;
                color: var(--foundation-pri-pri-500, #2A53A9);
                width: auto;
                display: flex;
                justify-content: center;
                padding: 0;
                border: none;
                background: transparent;
            }

        }
        .PageLayout{
            width: 100%;
            .ProfileProgress{
                width: 100%;
                @include flex-box($display: flex, $direction: row, $align: flex-start, $justify: flex-start);
                .ProgressTabOne{
                    width: 25%;
                    a{
                        text-decoration: none;
                        button{
                            width: 100%;
                            padding: 16px 8px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            border: none;
                            border-bottom: solid 1px #9BA6BC;
                            background: transparent;
                            cursor: pointer;
                            .ProfileProgressTab{
                                @include flex-box($justify: flex-start, $direction: row,  $align: flex-start);
                                gap: 8px;
                                .ProfileProgressTabDetails{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    gap: 2px;
                                    span:first-child{
                                        font-family: $first-font;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 500;
                                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                                    }
                                    span:last-child{
                                        color: var(--Alert-Succes-600, #00B374);
                                        font-family: $first-font;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;                
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .VerificationTopSection{
                display: flex;
                flex-direction: column;
                padding: 48px;
                h3{
                    margin: 0;
                    padding: 0;
                    margin-bottom: 40px;
                    font-family: $first-font;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    color: var(--foundation-neutral-black, #2E2C2C);
                }
                .VerificationProfile{
                    width: 100%;
                    gap: 2%;
                    @include flex-box($display: flex, $direction: row);
                    .ProfileDetails{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        width: 49%;
                        border-right: 1px solid var(--cool-gray-20, #DDE1E6);
                        img{
                            width: 96px;
                            height: 96px;
                            border-radius: 100px;
                            margin: 0;
                            margin-right: 24px;
                        }
                        .ProfileParticulars{
                            p:first-child{
                                color: var(--foundation-neutral-black, #2E2C2C);
                                font-family: $first-font;
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 500;
                                padding: 0;
                                margin: 0;
                            }
                            p:last-child{
                                color: var(--secondary-200, #9BA6BC);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px; /* 125% */
                                padding: 0;
                                margin: 0;
                            }
                        }
                    }
                    .AboutMe{
                        width: 49%;
                        p{
                            color: var(--foundation-neutral-black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                        }
                    }
                }
            }
            .VerificationDivider{
                width: 100%;
                background: #9BA6BC;
                height: 0.5px;
            }
            .ProfileVerification{
                width: 100%;
                height: 461px;
                margin-bottom: 40px;
                h3{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: TT Wellingtons Trl;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%;
                }
                .VerificationProcess{
                    @include flex-box($align: center, $justify: flex-start);
                    width: 100%;
                    height: 392px;
                    gap: 2%;
                    .Proccessing{
                        width: 30%;
                        height: 100%;
                        border-radius: 5.313px;
                        border: 0.5px solid var(--Primary-200, #D8E4FC);
                        background: var(--Default-White, #FFF);
                        @include flex-box($direction: column, $align: center, $justify: center);
                        .Counter{
                            height: 256px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            p:first-child{
                                margin: 0;
                                font-family: $first-font;
                                font-size: 50px;
                                font-weight: 600;
                            }
                        }
                        p:last-child{
                            margin: 0;
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            text-align: center;
                            font-family: $first-font;
                            font-size: 26.566px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%;
                        }
                    }
                    .VerifyCheck{
                        gap: 24px;
                        display: flex;
                        flex-direction: column;
                        width: 68%;
                        height: 100%;
                        .MarkStatus{
                            width: 100%;
                            height: 64px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            border-radius: 4px;
                            border: 1px solid var(--Primary-200, #D8E4FC);
                            background: #FFF;
                            padding: 16px;
                            box-sizing: border-box;
                            .IconWrapper{
                                margin-right: 9px;
                                display: flex;
                                width: 48px;
                                height: 48px;
                                justify-content: center;
                                align-items: center;
                                border-radius: 24px;
                                background: var(--Primary-100, #ECF1FE);
                                .CheckMark{
                                    color: #00B374;
                                    width: 24px;
                                    height: 24px;
                                }
                                .XMark{
                                    color: var(--cool-gray-20, #DDE1E6);
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
            .ProceedButton{
                border: none;
                margin-top: 221px;
                width: 100%;
                padding: 16px 12px;
                border-radius: 4px;
                background: var(--Secondary-200, #9BA6BC);
                color: var(--Default-White, #FFF);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
            }
        }
    }
}