@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.RecruiterProfileDetailContainer {
	margin-top: -40px;
	display: flex;
	align-items: flex-start;
	padding: 48px;
	width: 100%;
	border: solid 1px;
	.ProfileDetailInnerContainer {
		@include container($width: 97%);
		margin: 0;
		position: relative;
		overflow-x: hidden;
		overflow-y: scroll;
		@include flex-box(
			$justify: flex-start,
			$direction: column,
			$align: flex-start
		);
		.ProfilePhotoSection {
			@include container($width: 99%);
			position: relative;
			@include flex-box(
				$justify: flex-start,
				$direction: column,
				$align: flex-start
			);
			.ProfilePhotoHeader {
				p {
					font-family: $first-font;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					line-height: 110%;
					color: #2e2c2c;
				}
			}
			.ProfilePhotoHandler {
				@include flex-box(
					$justify: flex-start,
					$direction: row,
					$align: center
				);
				gap: 80px;
				width: 99%;
				.ProfileImageLoader {
					@include flex-box(
						$justify: flex-start,
						$direction: row,
						$align: center
					);
					gap: 20px;
          .InstantImage{
            width: 90px;
            width: 90px;
            border: none;
            border-radius: 100%;
            img{
              width: 100%;
              height: 90px;
              object-fit: cover;
              border-radius: 100%;
            }
          }
          
					.ProfileImageSpace {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 96px;
						height: 96px;
						border-radius: 100px;
						background: var(
							--cool-gray-10,
							#f2f4f8
						);
					}
					.ProfileImageType {
						@include flex-box(
							$justify: center,
							$direction: row,
							$align: center
						);
						input[type="file"] {
							background-color: white;
							display: flex;
							height: 38px;
							width: 120px;
							justify-content: center;
							align-items: center;
							border-radius: 4px;
							border: 1px solid #2a53a9;
							position: relative;
							z-index: 5;
							color: transparent;
							opacity: 0;
						}
						.ProfileImageUpload {
							position: relative;
							display: flex;
							height: 38px;
							width: 120px;
							padding: 8px 12px;
							justify-content: center;
							align-items: center;
							border-radius: 4px;
							border: 1px solid #2a53a9;
							background-color: white;
							font-family: $first-font;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							color: #2a53a9;
							transform: translateX(-121px);
							z-index: 2;
              cursor: pointer;
						}
						.ProfileImageRemove {
							color: #2a53a9;
							font-family: $first-font;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							background-color: white;
							border: none;
							transform: translateX(-121px);
              cursor: pointer;
						}
					}
				}
				.ProfileImageRequirement {
					border-left: solid 1px #80a0e4;
					padding-left: 35px;
					padding-top: 0px;
					p {
						color: var(
							--foundation-neutral-black,
							#2e2c2c
						);
						font-family: $first-font;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 22.4px */
					}
					ol {
						li {
							color: var(
								--foundation-neutral-black,
								#2e2c2c
							);
							font-family: $first-font;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							text-transform: capitalize;
						}
					}
				}
			}
		}
		.ProfileLineBreaker {
			width: 98%;
			border: solid 0.5px #9ba6bc;
			margin-top: 1.5rem;
			margin-left: -1rem;
		}
		.ProfileDetailSection {
			@include container($width: 98%);
			display: flex;
			flex-direction: column;
			margin: 0;
			align-items: flex-start;
			.ProfileDetailHeader {
				h3 {
					font-family: $first-font;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					color: #2e2c2c;
				}
			}
			.ProfileDetailForm {
				@include container($width: 100%);
				@include flex-box(
					$justify: flex-start,
					$direction: row,
					$align: flex-start
				);
				margin-bottom: 30px;
				gap: 10px;
				flex-wrap: wrap;
				.ProfileTextField {
					display: flex;
					flex-direction: column;
					height: 48px;
					width: 100%;
					align-items: center;
					gap: 3px;
					border-radius: 4px;
					border: 0.5px solid #d8e4fc;
					margin-top: 1px;
					padding-left: 10px;
				}
				.ProfileTextField::placeholder {
					color: var(--Secondary-200, #9ba6bc);
					font-family: $first-font;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 22.4px */
				}
				#ProfileTextField {
					display: flex;
					flex-direction: column;
					gap: 5px;
					justify-content: flex-start;
					align-items: flex-start;
					margin-right: 16px;
					width: 48%;
					label {
						color: var(--cool-gray-90, #21272a);
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
					}
				}
				#ProfileContact {
					display: flex;
					flex-direction: column;
					gap: 6px;
					padding: 0;
					justify-content: flex-start;
					align-items: flex-start;
					margin-right: 16px;
					width: 48.5%;

					p {
						color: var(--cool-gray-90, #21272a);
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						margin: 0;
					}
					.ProfileContact {
						display: flex;
						flex-direction: row;
						height: 48px;
						width: 100%;
						align-items: flex-start;
						justify-content: flex-start;
						flex-grow: 1;
						gap: 1px;
						border-radius: 4px;
						border: 0.5px solid #d8e4fc;
					}
				}
				.PhoneInput {
					height: 48px;
					width: 100%;
					align-items: flex-start;
					justify-content: flex-start;
					.PhoneInputInput {
						height: 45px;
						width: 100%;
            padding-left: 10px;
            border: none;
            outline: none;
            color: var(--cool-gray-90, #21272a);
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
					}
					.PhoneInputCountryIcon {
            height: 30px;
						width: 35px;
            border: none;
						.PhoneInputInternationalIconGlobe {
							height: 100%;
							width: 100%;
              z-index: 5;
						}
						.PhoneInputInternationalIconPhone {
							display: none;
							opacity: 0;
							z-index: 1;
						}
            .PhoneInputCountryIconImg{
              border: none;
            }
					}
				}
				#ProfileTextArea {
					display: flex;
					flex-direction: column;
					gap: 5px;
          width: 99%;
					justify-content: flex-start;
					align-items: flex-start;
					label {
						color: var(--cool-gray-90, #21272a);
						font-family: $first-font;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
					}
          .ProfileTextArea {
            width: 99%;
            height: 200px;
            flex-direction: column;
            gap: 5px;
            border-radius: 4px;
            border: 0.5px solid#D8E4FC;
            padding-top: 7px;
            padding-left: 10px;
            color: var(--cool-gray-90, #21272a);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            resize: none;
          }
				}
				.ProfileSaveButton {
					display: flex;
					margin-left: 91%;
					justify-content: center;
					align-items: center;
					background-color: white;
					position: relative;
					border-radius: 4px;
					color: #2a53a9;
					border: 2px solid #2a53a9;
					width: 100px;
					height: 35px;
				}
				.ProfileDivider {
					width: 100%;
					border: solid 1px #d1d4db;
					margin-left: -1rem;
					margin-bottom: 20px;
					margin-top: 30px;
				}
				.ImportantSite {
					margin-top: 10px;
					width: 100%;
					display: flex;
					gap: 10px;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					.ImportantSiteHeader {
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						h2 {
							color: var(
								--foundation-neutral-black,
								#2e2c2c
							);
							font-family: $first-font;
							font-size: 24px;
							font-style: normal;
							font-weight: 600;
						}
						.AddMoreLinks {
							display: flex;
							flex-direction: row;
							gap: 10px;
							justify-content: center;
							align-items: center;
							text-decoration: none;
							p {
								color: var(
									--foundation-pri-pri-500,
									#2a53a9
								);
								font-family: $first-font;
								font-size: 16px;
								font-style: normal;
								font-weight: 500;
							}
							.AddMoreLinksIcon {
								color: var(
									--foundation-pri-pri-500,
									#2a53a9
								);
								width: 20px;
								height: 20px;
							}
						}
					}
					.AddLinksTextField {
						display: flex;
						flex-direction: column;
						gap: 5px;
						height: 35px;
						width: 680%;
						justify-content: center;
						padding-left: 10px;
						align-items: center;
						border-radius: 4px;
						border: 0.5px solid #d8e4fc;
						color: #9ba6bc;
					}
					#AddLinksTextField {
						display: flex;
						flex-direction: column;
						gap: 5px;
						justify-content: flex-start;
						align-items: flex-start;
						label {
							color: var(--cool-gray-90, #21272a);
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
						}
					}
					.SaveLinksButton {
						display: flex;
						margin-left: 90.1%;
						margin-top: 20px;
						justify-content: center;
						align-items: center;
						background-color: white;
						position: relative;
						border-radius: 4px;
						color: #2a53a9;
						border: 2px solid #2a53a9;
						width: 100px;
						height: 35px;
					}
				}
				.ProceedButton {
					margin-top: 30px;
					button {
						width: 1100px;
						height: 40px;
						border-radius: 4px;
						border: 2px solid #2a53a9;
						color: white;
						background: #2a53a9;
						margin-bottom: 35px;
					}
				}
			}
		}
	}
}
