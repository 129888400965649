@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.JobHiringStageContainer {
  .SwitchSection {
    text-align: left;
    margin-top: 48px;
    .Header {
      border: 0.5px solid var(--Primary-200, #d8e4fc);
      border-radius: 4px;
      margin: 0 16px 24px 16px;
      padding: 12px 16px;
      @include space-between();
      h2 {
        font-family: $first-font;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
  .SendInvite {
    padding: 10px;
    @include flex-box(
      $display: flex,
      $direction: row,
      $justify: space-between,
      $align: center
    );
    h3 {
      font-family: $first-font;
      width: 100%;
    }
    div {
      width: 25%;
      gap: 20px;
      margin-right: 20%;
      @include flex-box(
        $display: flex,
        $direction: row,
        $justify: space-between,
        $align: center
      );
    }
  }
  .ButtonsSection {
    margin-top: 120px;
    padding: 0 16px;
    @include space-between();
    .save {
      max-height: 57px;
      background-color: var(--Foundation-PRI-pri-500, #2a53a9);
      color: white;
      border: none;
    }
    button {
      padding: 16px 82px;
      box-sizing: border-box;
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }
    button:nth-child(1) {
      color: var(--Foundation-PRI-pri-500, #2a53a9);
      border: 2px solid var(--Foundation-PRI-pri-500, #2a53a9);
    }
  }
}
