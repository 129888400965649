@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

body.no-scroll {
  overflow: hidden;
}
.ApplicantJobViewContent {
  padding: 16px;
  box-sizing: border-box;
  width: 98%;
  box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05);
  @include flex-box($direction: column, $align: flex-start);

  .ApplicantJobViewNavigation {
    @include flex-box();
    gap: 18px;
    cursor: pointer;
    margin-bottom: 40px;

    .ApplicantBackIcon {
      height: 24px;
      width: 24px;
      color: #21272a;
    }

    p {
      padding: 0;
      margin: 0;
      color: var(--Foundation-Neutral-Black, #2e2c2c);
      font-family: $first-font;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      /* 26.4px */
    }
  }

  .JobViewTopsection {
    @include flex-box($justify: space-between);
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--Primary-200, #d8e4fc);
    background: var(--Default-White, #fff);
    margin-bottom: 25px;

    .ApplicantProfile {
      @include flex-box();
      gap: 8px;

      img {
        width: 88px;
        height: 88px;
        border-radius: 100%;
      }

      span {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        h6 {
          margin: 0;
          padding: 0;
          color: var(--Foundation-Neutral-Black, #2e2c2c);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 22.4px */
        }

        p {
          color: var(--Secondary-200, #9ba6bc);
          font-family: $first-font;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* 16.8px */
          padding: 0;
          margin: 0;
        }
      }
    }

    button {
      color: var(--Foundation-PRI-pri-500, #2a53a9);
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      /* 162.5% */
      letter-spacing: 0.2px;
      border-radius: 4px;
      padding: 8px 16px;
      background: transparent;
      border: 1px solid var(--Foundation-PRI-pri-500, #2a53a9);
      cursor: pointer;
    }
  }

  .ApplicantJobTabsSection {
    width: 100%;
    @include flex-box($justify: space-between);
    margin-bottom: 33px;

    .JobViewTabs {
      @include flex-box($justify: flex-start);
      gap: 24px;
      border-bottom: 1px solid var(--Primary-200, #d8e4fc);
      width: 35%;

      .JobViewTab {
        text-decoration: none;

        button {
          @include flex-box();
          color: var(--Foundation-Neutral-Black, #2e2c2c);
          font-family: $first-font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          padding: 16px 0px;
          height: 48px;
          box-sizing: border-box;
          border: none;
          background: transparent;
        }
      }
    }

    .JobViewTabsNavigation {
      @include flex-box($justify: flex-start);
      gap: 20px;

      .IconWrapper {
        @include flex-box();
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background: rgba(42, 83, 169, 0.05);
        cursor: pointer;

        .TabIcon {
          height: 20px;
          width: 20px;
          color: #2a53a9;
        }
      }
    }
  }
  .ApplicationDetails {
    width: 100%;
    display: none;
  }

  .ApplicantJobTabDetails {
    width: 100%;
    margin-top: 20px;
  }
  .display {
    display: block;
  }
  .HiringStage {
    width: 100%;
    display: none;
  }
  .HiringStage.display {
    display: block;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);

  &.overlayOpen {
    opacity: 1;
    z-index: 0;
    visibility: visible;
  }

  &.overlayHidden {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.drawer {
  top: 5%;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;

  background-color: #ffffff;
  width: 85%;
  flex-shrink: 0;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--Primary-200, #D8E4FC);
  box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05);

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &.hidden {
    visibility: hidden;
    width: 240px;
    transform: translateX(240px);
    flex-shrink: 0;
    transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  &.animate {
    visibility: visible;
    transform: none;
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0px 16px 24px 2px rgba(0, 0, 0, 0.14),
      0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
}

.left {
  left: 0;
  right: auto;
}

.right {
  right: 0;
  left: auto;
}