@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.PreferencesContainer {
	@include container($width: 97%);
	display: flex;
	justify-content: center;
	align-items: center;
  margin-left: 30px;
	.PreferencesInnerContainer {
		@include container($width: 99.5%);
		@include flex-box(
			$justify: flex-start,
			$direction: column,
			$align: flex-start
		);
		position: relative;
		overflow-x: scroll;
		overflow-y: scroll;
		margin: 0.2rem 0;

		.PrefenceCvUpload {
			margin-bottom: 30px;
			.PreferenceTitle {
				@include text(
					$font-size: 24px,
					$color: $first-text,
					$font-weight: 600,
					$font-family: $first-font,
					$text-align: justify,
					$line-height: normal
				);
			}
			.PreferenceCvField {
				@include container($width: auto);
				@include flex-box(
					$justify: center,
					$direction: crow,
					$align: center
				);
				gap: 5px;
				form {
					@include flex-box(
						$justify: center,
						$direction: crow,
						$align: center
					);
					gap: 15px;
					align-items: last baseline;
					div {
						display: flex;
						flex-direction: column;
						gap: 5px;
						label {
							color: var(--cool-gray-90, #21272a);
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
						}
						input {
							position: relative;
							border-radius: 4px;
							border: 0.5px solid #9ba6bc;
							width: 950px;
							height: 25px;
							padding: 5px 0px 3px 5px;
							@include text(
								$font-size: 14px,
								$color: #9ba6bc,
								$font-weight: 400,
								$font-family: $first-font,
								$text-align: justify,
								$line-height: normal
							);
						}
					}

					button {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: white;
						position: relative;
						border-radius: 4px;
						color: #2a53a9;
						border: 1px solid #2a53a9;
						width: 125px;
						height: 35px;
						@include text(
							$font-size: 14px,
							$color: #9ba6bc,
							$font-weight: 400,
							$font-family: $first-font,
							$text-align: justify,
							$line-height: normal
						);
					}
				}
			}
		}
		.PreferenceDivider {
			width: 100%;
			border: solid 1px #d1d4db;
			margin-left: -1rem;
			margin-bottom: 30px;
		}
		.PreferenceJobInterest {
			margin-bottom: 10px;
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 15px;
			.PreferenceTitle {
				@include text(
					$font-size: 24px,
					$color: $first-text,
					$font-weight: 600,
					$font-family: $first-font,
					$text-align: justify,
					$line-height: normal
				);
			}
			.PreferenceJobField {
				width: 100%;
				display: flex;
				form {
					width: 100%;
					#PreferenceSelectField {
						width: 100%;
						display: flex;
						flex-direction: column;
						gap: 5px;
						justify-content: flex-start;
						align-items: flex-start;
						label {
							color: var(--cool-gray-90, #21272a);
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
						}
						.PreferenceSelectField {
							width: 99%;
							height: 40px;
							padding: 5px 0px 0px 10px;
							border-radius: 4px;
							border: 1px solid #2a53a9;
							@include text(
								$font-size: 14px,
								$color: #9ba6bc,
								$font-weight: 400,
								$font-family: $first-font,
								$text-align: justify,
								$line-height: normal
							);
						}
					}
					.PreferenceSelectChoice {
						width: 99%;
						margin: 0;
						padding: 0;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-direction: row;

						//The area to tick
						.JobPreferencesSection {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							list-style: none;
							gap: 20px;
							padding: 0;
							li {
								border-radius: 4px;
								background: var(
									--Primary-100,
									#ecf1fe
								);
								display: flex;
								padding: 5px 15px;
								justify-content: center;
								align-items: center;
                gap: 12px;

                .CancelIcon{
                  cursor: pointer;
                }
							}
						}
					}

					button {
						display: flex;
						margin-left: 90%;
						justify-content: center;
						align-items: center;
						background-color: white;
						position: relative;
						border-radius: 4px;
						color: #2a53a9;
						border: 2px solid #2a53a9;
						width: 100px;
						height: 35px;
						margin-top: 20px;
						@include	text(
								$font-size: 14px,
								$color: #9ba6bc,
								$font-weight: 700,
								$font-family: $first-font,
								$text-align: justify,
								$line-height: normal
							);
					}
				}
			}
		}
		.PreferenceJobLocation {
			margin-bottom: 30px;
      width: 100%;
			.PreferenceTitle {
				@include text(
					$font-size: 24px,
					$color: $first-text,
					$font-weight: 600,
					$font-family: $first-font,
					$text-align: justify,
					$line-height: normal
				);
			}
			.PreferenceLocationField {
        width: 100%;

				form {
          width: 100%;
					.PreferenceSelectField {
						width: 99%;
						height: 40px;
						padding: 5px 0px 0px 10px;
						border-radius: 4px;
						border: 1px solid #2a53a9;
						@include text(
							$font-size: 14px,
							$color: #9ba6bc,
							$font-weight: 400,
							$font-family: $first-font,
							$text-align: justify,
							$line-height: normal
						);
					}
					#PreferenceSelectField {
						display: flex;
						flex-direction: column;
						gap: 5px;
						justify-content: flex-start;
						align-items: flex-start;
						label {
							color: var(--cool-gray-90, #21272a);
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
						}
					}
					.PreferenceSelectChoice {
						width: 100%;
						margin: -10px 0px 15px 0px;
						display: flex;
						flex-direction: row;
						gap: 15px;
						height: auto;
						flex-wrap: wrap;
            .JobPreferencesSection {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							list-style: none;
							gap: 20px;
							padding: 0;
							li {
								border-radius: 4px;
								background: var(
									--Primary-100,
									#ecf1fe
								);
								display: flex;
								padding: 5px 15px;
								justify-content: center;
								align-items: center;
                gap: 12px;

                .CancelIcon{
                  cursor: pointer;
                }
							}
						}
					}
					button {
						display: flex;
						margin-left: 90%;
            margin-top: 25px;
						justify-content: center;
						align-items: center;
						background-color: white;
						position: relative;
						border-radius: 4px;
						color: #2a53a9;
						border: 2px solid #2a53a9;
						width: 100px;
						height: 35px;
						@include text(
							$font-size: 14px,
							$color: #9ba6bc,
							$font-weight: 700,
							$font-family: $first-font,
							$text-align: justify,
							$line-height: normal
						);
					}
				}
			}
		}
		.PreferenceSalary {
      width: 100%;
			.PreferenceTitle {
				margin-bottom: 30px;
				@include text(
					$font-size: 24px,
					$color: $first-text,
					$font-weight: 600,
					$font-family: $first-font,
					$text-align: justify,
					$line-height: normal
				);
			}
			.PreferenceSalaryField {
        width: 99%;
				form {
          width: 100%;
					div {
						display: flex;
						gap: 20px;
						.PreferenceSelectFieldOne {
							width: 100%;
							height: 40px;
							padding: 5px 0px 0px 10px;
							border-radius: 4px;
							border: 1px solid #2a53a9;
							@include text(
								$font-size: 14px,
								$color: #9ba6bc,
								$font-weight: 400,
								$font-family: $first-font,
								$text-align: justify,
								$line-height: normal
							);
						}
						#PreferenceSelectFieldOne {
              width: 48%;
							display: flex;
							flex-direction: column;
							gap: 5px;
							justify-content: flex-start;
							align-items: flex-start;
							label {
								color: var(
									--cool-gray-90,
									#21272a
								);
								font-family: $first-font;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
							}
						}
						.PreferenceTextField {
							width: 100%;
							height: 35px;
							padding: 5px 0px 0px 10px;
							border-radius: 4px;
							border: 1px solid #2a53a9;
							@include text(
								$font-size: 15px,
								$color: #343232,
								$font-weight: 400,
								$font-family: $first-font,
								$text-align: justify,
								$line-height: normal
							);
						}
						#PreferenceTextField {
              width: 48%;
							display: flex;
							flex-direction: column;
							gap: 5px;
							justify-content: flex-start;
							align-items: flex-start;
							label {
								color: var(
									--cool-gray-90,
									#21272a
								);
								font-family: $first-font;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
							}
						}
					}
					.PreferenceSalarySlider {
						width: 97%;
						margin-top: 15px;
            margin-left: 10px;
						display: flex;
						flex-direction: column;
						gap: 2px;
						p {
							color: var(--cool-gray-90, #21272a);
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
						}
					}
					button {
						display: flex;
						margin-left: 91%;
						margin-top: 20px;
						margin-bottom: 25px;
						justify-content: center;
						align-items: center;
						background-color: white;
						position: relative;
						border-radius: 4px;
						color: #2a53a9;
						border: 2px solid #2a53a9;
						width: 100px;
						height: 35px;
						@include text(
							$font-size: 14px,
							$color: #9ba6bc,
							$font-weight: 700,
							$font-family: $first-font,
							$text-align: justify,
							$line-height: normal
						);
					}
				}
			}
		}
		.PreferenceCompanySize {
			margin-bottom: 30px;
      width: 100%;
			.PreferenceTitle {
				@include text(
					$font-size: 24px,
					$color: $first-text,
					$font-weight: 600,
					$font-family: $first-font,
					$text-align: justify,
					$line-height: normal
				);
			}
			.PreferenceCompanySize {
        width: 100%;
				form {
          width: 100%;
					.PreferenceSelectField {
						width: 99%;
						height: 40px;
						padding: 5px 0px 0px 10px;
						border-radius: 4px;
						border: 1px solid #2a53a9;
						@include text(
							$font-size: 14px,
							$color: #343232,
							$font-weight: 400,
							$font-family: $first-font,
							$text-align: justify,
							$line-height: normal
						);
					}
					#PreferenceSelectField {
						display: flex;
						flex-direction: column;
						gap: 5px;
						justify-content: flex-start;
						align-items: flex-start;
						label {
							color: var(--cool-gray-90, #21272a);
							font-family: $first-font;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
						}
					}
					.PreferenceSelectChoice {
						width: 99%;
						margin: -10px 0px 15px 0px;
						display: flex;
						flex-direction: row;
						gap: 15px;
						height: auto;
						flex-wrap: wrap;
            .JobPreferencesSection {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							list-style: none;
							gap: 20px;
							padding: 0;
							li {
								border-radius: 4px;
								background: var(
									--Primary-100,
									#ecf1fe
								);
								display: flex;
								padding: 5px 15px;
								justify-content: center;
								align-items: center;
                gap: 12px;

                .CancelIcon{
                  cursor: pointer;
                }
							}
						}
					}
					button {
						display: flex;
						margin-left: 90%;
						margin-bottom: -20px;
            margin-top: 25px;
						justify-content: center;
						align-items: center;
						background-color: white;
						position: relative;
						border-radius: 4px;
						color: #2a53a9;
						border: 2px solid #2a53a9;
						width: 100px;
						height: 35px;
						@include text(
							$font-size: 14px,
							$color: #9ba6bc,
							$font-weight: 700,
							$font-family: $first-font,
							$text-align: justify,
							$line-height: normal
						);
					}
				}
			}
		}
		.PreferenceProceed {
			button {
				width: 1100px;
				height: 40px;
				border-radius: 4px;
				border: 2px solid #2a53a9;
				color: white;
				background: #2a53a9;
				margin-bottom: 35px;
        $font-family: $first-font;
        $font-size: 14px,
				
			}
		}
	}
}
