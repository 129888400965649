@import "../../static/styles/shared.scss";
@import "../../static/styles/variables.styles.scss";

.PermissionContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .PermissionContent {
        position: fixed;
        top: 9%;
        padding: 42px 48px 42px 48px;
        box-sizing: border-box;
        gap: 24px;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 4px;
        background: var(--Dark-Base-Base-White, #FFF);
        box-shadow: 0px 2px 8px 0px rgba(41, 82, 225, 0.15);
        .PermTopSection {
            width: 100%;
            @include flex-box($justify: space-between);
            h6{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 25.2px */
                padding: 0;
                margin: 0;
            }
            .PermIconWrapper{
                width: 24px;
                height: 24px;
                border-radius: 100%;
                border: 1px solid #FF4040;
                cursor: pointer;
            }
        }
        .PermMain {
            display: flex;
            width: 962px;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            box-shadow: 0px 7px 5px 0px rgba(60, 118, 241, 0.05);
            .PermTopMain {
                @include flex-box($justify: space-between);
                width: 100%;
                margin-bottom: 15px;
                .PermTopWrapper {
                    width: 48%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    h6 {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%; /* 22.4px */
                        padding: 0;
                        margin: 0;
                    }
                    span {
                        display: flex;
                        justify-content: space-between;
                        height: 48px;
                        padding: 12px 16px;
                        align-items: center;
                        align-self: stretch;
                        border-radius: 4px;
                        border: 0.5px solid var(--Primary-200, #D8E4FC);
                        box-sizing: border-box;
                        color: var(--Secondary-200, #9BA6BC);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                        p{
                            padding: 0;
                            margin: 0;
                        }
                        button {
                            border: none;
                            background-color: transparent;
                            cursor: pointer;
                            color: var(--Foundation-PRI-pri-600, #264C9A);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 140%; /* 22.4px */
                        }
                    }
                }
            }
            .PermViewPermission {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                h6 {
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 25.2px */
                    padding: 0;
                    margin: 0;
                    margin-bottom: 12px;
                }
                p {
                    margin: 0;
                    padding: 0;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                }
                .PermDivider {
                    height: 0.5px;
                    width: 100%;
                    margin: 25px 0px;
                    background: var(--Foundation-PRI-pri-100, #BDCAE4);
                    border: none;
                }
                .PermPermissions { 
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                    span {
                        height: 48px;
                        width: 100%;
                        border-radius: 4px;
                        border: 0.5px solid var(--Secondary-200, #9BA6BC);
                        @include flex-box($justify: space-between);
                        padding: 8px 16px;
                        box-sizing: border-box;
                        h5 {
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%; /* 22px */
                            padding: 0;
                            margin: 0;
                        }
                        .PermArrow {
                            height: 24px;
                            width: 24px;
                        }
                    }
                }
                .PermActivityButtons {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 50px;
                    button {
                        display: flex;
                        width: 202px;
                        height: 57px;
                        box-sizing: border-box;
                        padding: 16px 24px;
                        justify-content: center;
                        align-items: center;
                        gap: 84px;
                        text-align: center;
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                        cursor: pointer;
                    }
                    button:first-child {
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        background-color: transparent;
                        border-radius: 4px;
                        border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                    }
                    button:last-child {
                        color: var(--Black-And-White-White, #FFF);
                        border-radius: 4px;
                        border: 0.5px solid var(--Primary-200, #D8E4FC);
                        background: var(--Foundation-PRI-pri-500, #2A53A9);
                    }
                }
            }
        }
    }
}