@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CoachSecuritySettingsContainer{
    @include container($width: 92%);
    position: relative;
    margin-left: 1.5%;
    background: #FFF;
    box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
    flex-grow: 2;
    overflow-x: hidden;
    .CoachSecuritySettingsContent{
        padding: 0px 16px;
        box-sizing: border-box;
        @include flex-box($direction: column, $align: flex-start);
        width: 100%;
        .SecuritySettingsHeading{
            @include flex-box($justify: space-between);
            width: 100%;
            margin-bottom: 36px;
            h2{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                padding: 0;
                margin: 0;
            }
        }
        .SecurityEmailSettings{
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            margin-bottom: 16px;
            h6{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px; /* 175% */
                margin: 0;
                padding: 0;
            }
            input{
                height: 48px;
                width: 100%;
                box-sizing: border-box;
                padding: 12px 16px;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
            }
            input::placeholder{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
        }
        .SecurityPasswordSettings{
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            h6{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px; /* 175% */
                padding: 0;
                margin: 0;
            }
            p{
                color: var(--Secondary-200, #9BA6BC);
                font-family: $first-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
            }
            .SecurityPasswordSettingsInputs{
                @include flex-box($direction: column, $align: flex-start);
                width: 100%;
                box-sizing: border-box;
                .PasswordSettingInputField{
                    width: 100%;
                    height: 48px;
                    box-sizing: border-box;
                    outline: none;
                    border: 0.5px solid #C1C7CD;
                    border-radius: 4px;
                    background-color: white;
                    padding-left: 10px;
                    position: relative;
                    margin-bottom: -5px;
                }
                .ErrorPasswordField{
                    display: flex;
                    color: var(--cool-gray-60, #697077);
                    font-family: $first-font;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    margin-top: -15px;
                    transform: translateY(-10px);
                    margin-bottom: 15px;
                }
                #PasswordSettingInputField{
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    gap: 5px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    label{
                        color: var(--cool-gray-90, #21272A);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }
            #SecuritySettingsCheckBox{
                width: 49.2%;
                label{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
            button{
                color: var(--Default-White, #FFF);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 16px */
                letter-spacing: 0.5px;
                padding: 16px 12px;
                border-radius: 4px;
                border: 2px solid var(--CoolGray-30, #C1C7CD);
                background: var(--CoolGray-30, #C1C7CD);
                width: 100%;
                margin-bottom: 38px;
            }
            .SecuritySettingAuthentication{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-bottom: 20px;
                h6{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px; /* 175% */
                }
                span{
                    box-sizing: border-box;
                    @include flex-box($justify: space-between);
                    p{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
            }
        }
    }
}