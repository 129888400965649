@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.UserJobSeekerContainer{
    width: 90%;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .UserJobSeekerContent{
        width: 100%;
        @include flex-box($direction: column, $align: flex-start);
        margin-bottom: 45px;
        .UserJobSeekerTopSection{
            width: 100%;
            height: 358px;
            padding: 20px;
            box-sizing: border-box;
            border-bottom: 1px solid #DDE1E6;
            background: url(../../../images/backgroundImage.png), lightgray 50% / cover no-repeat, rgba(197, 214, 251, 0.10);
            .UserJobSeekerTopHeading{
                width: 100%;
                @include flex-box($justify: space-between);
                span{
                    @include flex-box();
                    gap: 8px;
                    .UserJobSeekerBackIcon{
                        height: 24px;
                        width: 24px;
                        color: #21272A;
                        cursor: pointer;
                    }
                    h4{
                        padding: 0;
                        margin: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px; /* 64.516% */
                    }
                }
                button{
                    color: var(--Black-And-White-White, #FFF);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    padding: 16px 24px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    background: var(--Foundation-PRI-pri-500, #2A53A9);
                }
            }
            img{
                width: 200px;
                height: 200px;
                border-radius: 200px;
                position: absolute;
                top: 155px;
            }
        }
        .UserJobSeekerDetails{
            margin-top: -60px;
            width: 100%;
            height: 245px;
            @include flex-box($justify: flex-start);
            gap: 2%;
            .UserJobSeekerInfo{
                width: 32%;
                height: 100%;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                padding: 107px 44px 20px 20px;
                box-sizing: border-box;
                h6{
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; /* 125% */
                    margin-bottom: 11px;
                }
                p{
                    margin-bottom: 6px;
                }
                .FirstChild{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 125% */
                    padding: 0;
                    margin: 0;
                }
                .SecondChild{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 125% */
                    margin: 0;
                    padding: 0;
                    span{
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                    }
                }
                .ThirdChild{
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 125% */
                    margin: 0;
                    padding: 0;
                }
            }
            .UserJobSeekerBio{
                width: 66%;
                height: 100%;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                padding: 30px;
                box-sizing: border-box;
                h4{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 31px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; /* 64.516% */
                    margin: 0;
                    padding: 0;
                    margin-bottom: 32px;
                }
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .UserJobSeekerProfile{
            width: 100%;
            height: 244px;
            @include flex-box($justify: flex-start);
            gap: 2%;
            margin-top: 24px;
            .UserJobSeekerProfileStrength{
                width: 32%;
                height: 100%;
                padding: 24px 12px;
                box-sizing: border-box;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                h4{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 22px */
                    padding: 0;
                    margin: 0;
                    margin-bottom: 24px;
                }
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    padding: 0;
                    margin: 0;
                    margin-bottom: 8px;
                }
                span{
                    width: 100%;
                    @include flex-box($justify: flex-start);
                    gap: 5%;
                    margin-bottom: 32px;
                    .UserJobSeekerProgressWrapper{
                        width: 80%;
                        height: 10px;
                        background: var(--Primary-100, #ECF1FE);
                        .UserJobSeekerProgress{
                            height: 100%;
                            background: var(--primary-500-base, #3C76F1);
                        }
                    }
                }
            }
            .UserJobSeekerProfileView{
                width: 32%;
                height: 100%;
                padding: 24px 12px;
                box-sizing: border-box;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                gap: 12px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                h4{
                    margin: 0;
                    padding: 0;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 22px */
                }
                span{
                    @include flex-box($justify: flex-start);
                    gap: 8px;
                    p{
                        padding: 0;
                        margin: 0;
                        color: var(--Secondary-200, #9BA6BC);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                    }
                }
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 125% */
                }
            }
            .UserJobSeekerPortfolio{
                width: 32%;
                height: 100%;
                padding: 24px 12px;
                box-sizing: border-box;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 12px;
                h4{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 22px */
                    margin: 0;
                    padding: 0;
                }
                p{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    margin: 0;
                    padding: 0;
                }
                #UserJobSeekerText{
                    width: 100%;
                    @include flex-box($direction: column, $align: flex-start);
                    gap: 8px;
                    label{
                        color: var(--CoolGray-90, #21272A);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 19.6px */
                    }

                }
                .UserJobSeekerText{
                    width: 100%;
                    height: 48px;
                    box-sizing: border-box;
                    padding: 12px 16px;
                    border-radius: 4px;
                    border: 0.5px solid var(--Secondary-200, #9BA6BC);
                }
                .UserJobSeekerText::placeholder{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                }
            }
        }
        .UserJobSeekerExperience{
            width: 100%;
            margin-top: 24px;
            @include flex-box($direction: column, $align: flex-start);
            gap: 16px;
            padding: 16px 24px;
            box-sizing: border-box;
            background: #FFF;
            box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
            h2{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                margin: 0;
                padding: 0;
            }
            .UserJobSeekerExperienceData{
                @include flex-box($justify: flex-start, $align: center);
                gap: 9px;
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                img{
                    height: 56px;
                    width: 56px;
                    border-radius: 4px;
                }
                .UserJobSeekerWorkDetails{
                    @include flex-box($direction: column, $align: flex-start);
                    gap: 6px;
                    h6{
                        margin: 0;
                        padding: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                    }
                    .UserJobSeekerWorkInfo{
                        @include flex-box();
                        span:nth-child(1){
                            color: var(--Secondary-200, #9BA6BC);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                        }
                        span:nth-child(3){
                            color: var(--Foundation-PRI-pri-500, #2A53A9);
                            font-family: $first-font;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 166.667% */
                            padding: 0px 12px;
                            border-radius: 4px;
                            background: rgba(60, 118, 241, 0.10);
                        }
                        span:nth-child(5){
                            color: var(--Alert-Succes-600, #00B374);
                            font-family: $first-font;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 166.667% */
                            padding: 0px 12px;
                            border-radius: 4px;
                            background: rgba(0, 179, 116, 0.10);
                        }
                    }
                }

            }
        }
        .UserJobSeekerEducation{
            width: 100%;
            margin-top: 24px;
            @include flex-box($direction: column, $align: flex-start);
            gap: 16px;
            padding: 16px 24px;
            box-sizing: border-box;
            background: #FFF;
            box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
            h2{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                margin: 0;
                padding: 0;
            }
            .UserJobSeekerEducationData{
                @include flex-box($justify: flex-start, $align: center);
                gap: 9px;
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: #FFF;
                img{
                    height: 56px;
                    width: 56px;
                    border-radius: 4px;
                }
                .UserJobSeekerEducationDetails{
                    @include flex-box($direction: column, $align: flex-start);
                    gap: 6px;
                    h6{
                        margin: 0;
                        padding: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                    }
                    .UserJobSeekerEducationInfo{
                        @include flex-box();
                        span:nth-child(1){
                            color: var(--Secondary-200, #9BA6BC);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                        }
                        span:nth-child(3){
                            color: var(--Foundation-PRI-pri-500, #2A53A9);
                            font-family: $first-font;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 166.667% */
                            padding: 0px 12px;
                            border-radius: 4px;
                            background: rgba(60, 118, 241, 0.10);
                        }
                        span:nth-child(5){
                            color: var(--Alert-Succes-600, #00B374);
                            font-family: $first-font;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 166.667% */
                            padding: 0px 12px;
                            border-radius: 4px;
                            background: rgba(0, 179, 116, 0.10);
                        }
                    }
                }
            }
        }
        .UserJobSeekerLicense{
            width: 100%;
            margin-top: 24px;
            @include flex-box($direction: column, $align: flex-start);
            gap: 16px;
            padding: 16px 24px;
            box-sizing: border-box;
            background: #FFF;
            box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
            h2{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                margin: 0;
                padding: 0;
            }
            .UserJobSeekerCertificationData{
                @include flex-box($justify: space-between);
                width: 100%;
                .UserJobSeekerLicenseParticulars{
                    @include flex-box($justify: flex-start, $align: center);
                    gap: 24px;
                    img{
                        height: 56px;
                        width: 56px;
                        border-radius: 4.667px;
                    }
                    .UserJobSeekerCertInfo{
                        @include flex-box($direction: column, $align: flex-start);
                        gap: 5px;
                        h6{
                            padding: 0;
                            margin: 0;
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 26px; /* 162.5% */
                            letter-spacing: 0.2px;
                        }
                        .UserJobSeekerCertDetails{
                            @include flex-box($justify: flex-start);
                            gap: 9px;
                            span:first-child{
                                color: var(--Secondary-200, #9BA6BC);
                                font-family: $first-font;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 26px; /* 162.5% */
                                letter-spacing: 0.2px;
                            }
                            span:last-child{
                                color: var(--Foundation-PRI-pri-500, #2A53A9);
                                font-family: $first-font;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px; /* 166.667% */
                                padding: 0px 12px;
                                border-radius: 4px;
                                background: rgba(60, 118, 241, 0.10);
                            }
                        }
                    }

                }
                .UserJobSeekerShowButton{
                    @include flex-box();
                    gap: 8px;
                    padding: 8px 16px;
                    height: 48px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 1px solid var(--Primary-700, #305EC1);
                    p{
                        color: var(--primary-500-base, #3C76F1);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 125% */
                        margin: 0;
                        padding: 0;
                    }
                    .ButtonIcon{
                        width: 16px;
                        height: 16px;
                        color: #2A53A9;
                    }
                }
            }
        }
    }
}