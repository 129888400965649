@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.ModalContainer{
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .MenteeModal{
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        padding: 32px 90px 32px 39px;
        width: 209px;
        background: #FFF;
        box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
        .CloseIconWrapper{
            width: 130%;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
        }
        .MenteeViewWrapper{
            @include flex-box();
            gap: 16px;
            cursor: pointer;
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
            .ModalIcon{
                height: 24px;
                width: 24px;
            }    
        }
        .MenteeGradingWrapper{
            @include flex-box();
            gap: 16px;
            cursor: pointer;
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
            .ModalIcon{
                height: 24px;
                width: 24px;
                color: #00B374;
            }
        }
        .MenteeChatWrapper{
            @include flex-box();
            gap: 16px;
            cursor: pointer;
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }
            .ModalIcon{
                height: 24px;
                width: 24px;
                color: #082253;
            }   
        }
    } 
}