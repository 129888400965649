@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.AddCourseResourceContainer {
	@include container($width: 99%);
	position: relative;
	margin-top: 1.5rem;
	margin-left: 1%;
	flex-grow: 2;
	overflow-x: hidden;
	.CourseModuleResources {
		width: 100%;
		padding: 16px;
		gap: 24px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		border-radius: 4px;
		border: 1px solid var(--Primary-200, #d8e4fc);
		.ModuleResourcesAccordionWrapper {
			padding: 16px;
			width: 100%;
			box-sizing: border-box;
			border-radius: 4px;
			border: 1px solid
				var(--Primary-200, #d8e4fc);
			background: var(--Default-White, #fff);
			.ModuleResourcesHeading {
				@include flex-box(
					$align: center,
					$justify: space-between
				);
				width: 100%;
				height: 24px;
				box-sizing: border-box;
				cursor: pointer;
				h4 {
					color: var(
						--Foundation-PRI-pri-500,
						#2a53a9
					);
					font-family: $first-font;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 110%; /* 17.6px */
				}
				.ModuleResourcesAccordionIcon {
					height: 24px;
					width: 24px;
					color: #2a53a9;
				}
				.rotate {
					transform: rotate(180deg);
				}
			}
			.ModuleResourcesAdd {
				width: 100%;
				max-height: 0;
				overflow: hidden;
				transition: all 0.5s
					cubic-bezier(0, 1, 0, 1);
				@include flex-box(
					$align: center,
					$justify: space-between
				);
				p {
					color: var(
						--Foundation-Neutral-Black,
						#2e2c2c
					);
					font-family: $first-font;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 110%; 
					margin: 0;
					padding: 0;
				}
				.ModuleResourcesAddIcon {
					height: 24px;
					width: 24px;
					color: #2a53a9;
					cursor: pointer;
				}
			}
			.ModuleResourcesAdd.show {
				margin-top: 20px;
				height: auto;
				max-height: 9999px;
				transition: all 0.5s
					cubic-bezier(0, 1, 0, 1);
				border-radius: 4px;
				border: 1px solid
					var(--Primary-200, #d8e4fc);
				background: var(--Default-White, #fff);
				padding: 16px;
				box-sizing: border-box;
			}
		}
	}
	.ModuleResourcesButton {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 40px;
		margin-top: 68px;
		align-items: flex-end;
		margin-bottom: 40px;
		a {
			width: 200px;
			height: 100%;
			text-decoration: none;
			button {
				width: 100%;
				height: 100%;
				padding: 24px;
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 4px;
				border: 0.5px solid
					var(--Primary-200, #d8e4fc);
				background: var(
					--Foundation-PRI-pri-500,
					#2a53a9
				);
				color: var(--Black-And-White-White, #fff);
				font-family: $first-font;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 26px; /* 162.5% */
				letter-spacing: 0.2px;
			}
		}
	}
}
