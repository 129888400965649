@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.HiringStageContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
    .HiringStageDetails {
        width: 100%;
        @include flex-box($direction: column, $align: flex-start);
        border-radius: 4px;
        border: 1px solid var(--Primary-200, #D8E4FC);
        background: #FFF;
        padding: 16px;
        gap: 16px;
        box-sizing: border-box;
        h6 {
            padding: 0;
            margin: 0;
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 22px */
        }
        .HiringInputs {
            @include flex-box($justify: space-between);
            width: 100%;
            #HiringInput {
                width: 32%;
                @include flex-box($direction: column, $align: flex-start);
                gap: 8px;
                label {
                    color: var(--CoolGray-90, #21272A);
    
                    /* Body/Regular */
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
            .HiringInput{
                width: 100%;
                height: 48px;
                padding: 12px 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid var(--Secondary-200, #9BA6BC);
                background: var(--Primary-100, #ECF1FE);
            }
            .HiringInput::placeholder {
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
            }
        }
    }
    .HiringStages {
        width: 100%;
        @include flex-box($direction: column, $align: flex-start);
        gap: 48px;
        .HiringStageContent {
            width: 100%;
            gap: 30px;
            @include flex-box($direction: column, $align: flex-start);
            .HiringStageTop {
                width: 100%;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                padding: 13px 38px 13px 16px;
                height: 48px;
                box-sizing: border-box;
                @include flex-box($justify: flex-start);
                h4 {
                    padding: 0;
                    margin: 0;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                }
            }
            .HiringStageToggles {
                width: 100%;
                @include flex-box($justify: space-between);
                span {
                    width: 48%;
                    // height: 48px;
                    gap: 17px;
                    @include flex-box($justify: flex-start);
                    box-shadow: 0px 1px 0px 0px rgba(17, 17, 17, 0.08);
                    h6 {
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 22.4px */
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
    .HiringStageButtons{
        @include flex-box($justify: space-between);
        gap: 24px;
        button:first-child{
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            padding: 8px 16px;
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
        }
        button:last-child{
            color: var(--Black-And-White-White, #FFF);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
            padding: 8px 16px;
            border-radius: 4px;
            border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
            background: var(--Foundation-PRI-pri-500, #2A53A9);
        }
    }
}