@import "./static/styles/variables.styles.scss";


body {
  margin: 0;
  font-family: $first-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $first-font;
}
