@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.SettingsLayoutContainer{
    @include container();
    position: relative;
    z-index: 2;
    overflow-x: hidden;
    @include flex-box($justify: flex-start, $direction: column, $align: flex-start);
    gap: 0.3rem;
    .LayoutSegmentTwo{
        @include container();
        position: relative;
        margin-top: 163px;
        @include flex-box( $justify: flex-start, $align: flex-start);
        gap: 0.3rem;
        overflow-x: hidden;
    }
    .LayoutContent{
        overflow-y: scroll;
        overflow-x: hidden;
        @include container($width: 94%);
    }
}