@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.SideBarContainer{
  height: 100vh;
  position: fixed;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #FFF;
  box-shadow: 4px 0px 6px 4px rgba(42, 83, 169, 0.05);
  width: 90px;
  .InnerContainer{
    margin: 26px 26px auto 26px;
    @include flex-box($justify: space-between, $direction: column,  $align: center);
    gap: 43px;
    .RecruiterSideLinks{
      .RecruiterSideLink{
        margin-top: 4%;
        @include flex-box($justify: space-between, $direction: column,  $align: center);
        gap: 10px;
        text-decoration: none;
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: 50px;
        color: #9BA6BC;
      }
    }
  }
  
}