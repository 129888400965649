@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.NewRoadMapContainer{
    @include container($width: 90%);
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .RoadMapContainerContent{
        width: 100%;
        @include flex-box($display: flex, $direction: column, $justify: center, $align: flex-start);
        .NewRoadMapInput{
            width: 75%;
            padding: 12px 16px;
            border-radius: 4px;
            border: 0.5px solid var(--Primary-200, #D8E4FC);
        }
        .NewRoadMapInput::placeholder{
            color: var(--Secondary-200, #9BA6BC);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            letter-spacing: 0.2px;
        }
        .ErrorField{
            display: flex;
            align-items: flex-start;
            color: var(--cool-gray-60, #697077);
            font-family: $first-font;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-left:15px;
            transform: translateY(-1px);
        }
        .NewRoadMapTabs{
            @include flex-box($justify: flex-start);
            width: 100%;
            margin-top: 42px;
            .NewRoadMapTab{
                width: 25%;
                @include flex-box($justify: flex-start);
                gap: 8px;
                padding: 0px 8px;
                border-bottom: 0.5px solid var(--Secondary-200, #9BA6BC);
                p{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
            .NewRoadMapActiveTab{
                width: 25%;
                @include flex-box($justify: flex-start);
                gap: 8px;
                padding: 0px 8px;
                border-bottom: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
            }
        }
        .NewRoadMapInputSection{
            margin-top: 42px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            gap: 16px;
            padding: 16px;
            @include flex-box($display: flex, $direction: column, $justify: flex-start, $align: flex-start);
            .NewCourseDescription{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 16px;
                h6{
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    margin: 0;
                    padding: 0;
                }
                .CourseDescriptionInput{
                    width: 100%;
                    height: 183px;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                }
            }
            .NewRoadMapDivider{
                width: 100%;
                height: 0.5px;
                background: #9BA6BC;
                margin-top: 16px;
                margin-bottom: 16px;
            }
            .NewCourseExpectations{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .ExpectationsHeadings{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    h6{
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 26px; /* 162.5% */
                        letter-spacing: 0.2px;
                        margin: 0;
                        padding: 0;
                    }
                    p{
                        color: var(--Secondary-200, #9BA6BC);
                        font-family: $first-font;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px; /* 185.714% */
                        letter-spacing: 0.2px;
                    }
                }
                .CourseExpectationsInput{
                    height: 183px;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 12px 16px;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                }
                .ErrorField{
                    display: flex;
                    align-items: flex-start;
                    color: var(--cool-gray-60, #697077);
                    font-family: $first-font;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    margin-left:15px;
                    transform: translateY(-1px);
                }
                
            }
            .CourseModules{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                h6{
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    margin: 0;
                    padding: 0;
                }
                .CourseModuleWrapper{
                    @include flex-box($justify: space-between);
                    border-radius: 4px;
                    border: 1px solid var(--Primary-200, #D8E4FC);
                    background: var(--Default-White, #FFF);
                    width: 100%;
                    padding: 16px;
                    box-sizing: border-box;
                    p{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%; /* 17.6px */
                        margin: 0;
                        padding: 0;
                    }
                    .CourseModuleIcon{
                        height: 24px;
                        width: 24px;
                        color: #2A53A9;
                    }
                }
            }
            .ButtonWrapper{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                button{
                    padding: 16px 24px;
                    border-radius: 4px;
                    margin-top: 16px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    background: var(--Foundation-PRI-pri-500, #2A53A9);
                    color: var(--Black-And-White-White, #FFF);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: TT Wellingtons Trl;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                    height: 50px;
                    cursor: pointer;
                }
            }
        }

    }
}