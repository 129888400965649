@import "../../static/styles/shared.scss";
@import "../../static/styles/variables.styles.scss";

.ModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .Modal {
        position: fixed;
        left: 80%;
        width: 150px;
        padding: 8px 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 4px;
        background: var(--Dark-Base-Base-White, #FFF);
        box-shadow: 0px 2px 8px 0px rgba(41, 82, 225, 0.15);
        p {
            color: var(--Dark-Base-Base-Black, #2C2C2C);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            margin: 0;
            cursor: pointer;
        }
        p:hover {
            background: var(--Primary-100, #ECF1FE);
        }
    }
}
.DeleteContainer {
    position: fixed;
    top: 25%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .Delete {
        display: flex;
        width: 380px;
        height: 262px;
        box-sizing: border-box;
        padding: 16px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        background: var(--Dark-Base-Base-White, #FFF);
        box-shadow: 0px 2px 8px 0px rgba(41, 82, 225, 0.15);
        .DeleteIconWrapper {
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            background: var(--alert-danger-500-base, #FF4040);
            .DeleteIcon {
                width: 24px;
                height: 24px;
                color: #FFFFFF;
            }
        }
        h6{
            color: var(--CoolGray-90, #21272A);
            text-align: center;
            font-family: $first-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 26.4px */
            padding: 0;
            margin: 0;
        }
        p {
            padding: 0;
            margin: 0;
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            text-align: center;
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
        }
        .ActivityButtons {
            width: 100%;
            gap: 8%;
            @include flex-box($justify: flex-start);
            button:first-child{
                display: flex;
                width: 48%;
                height: 48px;
                padding: 16px 12px;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
                color: var(--Default-White, #FFF);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
            }
            button:last-child{
                display: flex;
                width: 48%;
                height: 48px;
                padding: 16px 12px;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                border-radius: 4px;
                border-radius: 4px;
                background: transparent;
                border: 1px solid var(--alert-danger-500-base, #FF4040);
                color: var(--alert-danger-500-base, #FF4040);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
            }
        }
    }
}

.AssignContainer {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .AssignContent {
       display: flex;
       box-sizing: border-box;
       height: auto;
       width: 442px;
       padding: 16px 24px;
       flex-direction: column;
       align-items: flex-start;
       gap: 16px;
       background: var(--Dark-Base-Base-White, #FFF);
       box-shadow: 0px 2px 8px 0px rgba(41, 82, 225, 0.15);
       .AssignTop {
            @include flex-box($justify: space-between);
            width: 100%;
            h6 {
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 25.2px */
                padding: 0;
                margin: 0;
            }
            .AssignIconWrapper{
                width: 24px;
                height: 24px;
                border-radius: 100%;
                border: 1px solid #FF4040;
                cursor: pointer;
            }
        }
        p {
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            padding: 0;
            margin: 0;
            span {
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                cursor: pointer;
            }
        }
        .AssignedRoles {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            gap: 16px;
            span {
                @include flex-box($justify: space-between);
                padding: 12px 18px;
                box-sizing: border-box;
                gap: 10px;
                border-radius: 4px;
                background: #FFF;
                box-shadow: -6px 6px 8px -2px rgba(108, 107, 107, 0.20);
                p {
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 16.5px */
                    padding: 0;
                    margin: 0;
                }
                button {
                    border-radius: 4px;
                    background: #ECF1FE;
                    border: none;
                    display: flex;
                    width: 98px;
                    height: 40px;
                    padding: 16px;
                    box-sizing: border-box;
                    justify-content: center;
                    align-items: center;
                    gap: 24px;
                    display: flex;
                    width: 98px;
                    height: 40px;
                    padding: 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 24px;
                    cursor: pointer;
                    color: var(--Foundation-PRI-pri-500, #2A53A9);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                }
            }
        }
        .AssignedButtons {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            button {
                display: flex;
                width: 134px;
                height: 57px;
                padding: 16px 24px;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                gap: 84px;
                flex-shrink: 0;
                text-align: center;
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 162.5% */
                letter-spacing: 0.2px;
                cursor: pointer;
            }
            button:first-child {
                color: var(--Foundation-PRI-pri-500, #2A53A9);
                background-color: transparent;
                border-radius: 4px;
                border: 1px solid var(--Foundation-PRI-pri-500, #2A53A9);
            }
            button:last-child {
                color: var(--Black-And-White-White, #FFF);
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                background: var(--Foundation-PRI-pri-500, #2A53A9);
            }
        }

    }
}