@import url('https://fonts.cdnfonts.com/css/tt-wellingtons-trl?styles=151856,151857');

$default: #ffffff;
//background-color

$first-background: #f8faff;
$second-background: #f2f4f8;

//text-color
$first-text: #2E2C2C;
$second-text: #6d89c4;
$third-text: #e3e4e5;
$fourth-text: #6484a8;
$fifth-text: #dadbdd;

//button-color
$first-button: #2A53A9;
$second-button: $default;

//breakpoints
$breakpoint-small: 576px;
$breakpoint-small-middle: 577px;
$breakpoint-medium: 1002px;

//font-family
$first-font: 'TT Wellingtons Trl', sans-serif;
