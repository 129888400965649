@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CoachNavContainer {
  @include container;
  @include flex-box($justify: space-between, $align: center);
  position: fixed;
  z-index: 10;
  margin-bottom: 1rem;
  padding: 10px 0;
  overflow-y: visible;
  box-shadow: -4px 4px 6px -4px rgba(42, 83, 169, 0.05);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%), #fff;
  .CoachNavLogo {
    margin-left: 48px;
  }
  .CoachNavIcons {
    @include flex-box($justify: space-between, $align: center);
    margin-right: 120px;
    gap: 8px;
    .jsNavToolTipButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      padding: none;
      border-radius: 100%;
      margin-left: -0.9rem;
      &:hover {
        background-color: transparent;
      }
    }
    .CoachNavNotificationIcon {
      width: 24px;
      height: 24px;

      transform: translateX(0.2rem);
      &:hover {
        border-radius: 100%;
        border: none;
        background-color: rgba(60, 70, 90, 0.1);
        padding: 0.3rem;
        transform: translate(0rem, 0rem);
      }
    }
    img {
      transform: translateX(0.5rem);
    }
    .CoachNavArrowIcon {
      width: 24px;
      height: 24px;
      &:hover {
        border-radius: 100%;
        border: none;
        background-color: rgba(60, 70, 90, 0.1);
        padding: 0.2rem;
        transform: translate(0.2rem, 0rem);
      }
    }
  }
}

.NotificationCard {
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 0px 0px 4px 4px;
  background: #fff;
  margin-left: -180px;
  margin-top: 31px;
  width: 300px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 15px;
  box-shadow: -4px 5px 8px 2px rgba(42, 83, 169, 0.15);
  
}
.NotificatorCards{
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  width: 85%;
  li{
    list-style-image: url("../../../static/images/icons/Indicator.png");
    list-style-position: outside;
    &:hover{
        background: #e7e9ec;
        color: white;
    }
    padding-left: 5px;
    a{
      display: flex;
      flex-direction: column;
      width: 90%;
      text-decoration: none;
      div:first-child{
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      p:first-child{
        font-family: $first-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: #2A53A9;
      }
      p:last-child{
        color: var(--secondary-200, #9BA6BC);
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
      
    }
    div:last-child{
      margin-top: -0.5rem;
      color: var(--secondary-200, #9BA6BC);
      font-family: $first-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
    }
    
  }
}
.settingsContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 170px;
  padding: 0;
  margin: 0;
  
  li{
    list-style: none;
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      text-decoration: none;
      width: 180px;
      color: #9BA6BC;
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      & > div{
        margin-left: 30px;
      }
      &:hover{
        background-color: #9da6b9;
        color: #fff;
        width: 180px;
      }
      
    }
  }
}
.ShowProfile{
  border-radius: 4px;
  margin: 0.3rem;
  border: 0.5px solid #D8E4FC;
  .ShowProfileOne{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    img{
      transform: translateY(-0.5rem) translateX(0.5rem);
    }
    .CoachDataName{
      
      p{
        color: var(--foundation-pri-pri-500, #2A53A9);
        font-family: $first-font;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        margin-top: 1rem;
      }
      h6{
        color: #2E2C2C;
        font-family: $first-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-top: -1rem;
      }
    }
  }
  .ShowProfileTwo{
    display: flex;
    button{
      color: #2A53A9;
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #2A53A9;
      flex: 1;
      margin: 0 10px 10px 10px;
      height: 35px;
    }
    margin-bottom: 20px;
  }
  .ShowProfileBreaker{
    border: 0.1px solid #D8E4FC;
    margin: 0 10px 10px 10px;
  }
  .ShowProfileThree{
    margin: 0 10px 20px 10px;
    display: flex; 
    flex-direction: column; 
    gap: 20px;  
    p{
      color: #2E2C2C;
      font-family: $first-font;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: -0.5rem;
    }
    a{
      color: #2A53A9;
      font-family: $first-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      background-color: #fff;
      border-radius: 4px;
      text-decoration: none;
    }
  }
  
}