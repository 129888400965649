@import "../../static/styles/shared.scss";
@import "../../static/styles/variables.styles.scss";

.ResourceCertificateContainer {
	margin-top: 42px;
	width: 100%;
	padding: 16px;
	box-sizing: border-box;
	border-radius: 4px;
	border: 1px solid var(--Primary-200, #d8e4fc);
	@include flex-box(
		$display: flex,
		$direction: column,
		$justify: flex-start,
		$align: flex-start
	);
	margin-bottom: 15px;
	.CertificateHeader {
		width: 96%;
		border-radius: 4px;
		border: 1px solid var(--Primary-200, #d8e4fc);
		@include flex-box(
			$display: flex,
			$direction: row,
			$justify: space-between,
			$align: center
		);
		padding: 10px 16px;
		h1 {
			color: #2a53a9;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
		}
		.CertIcon {
			width: 20px;
			height: 20px;
			color: #2a53a9;
		}
	}
	.CertificateImage {
		width: 99%;
		margin-top: 16px;
		img {
			width: 100%;
			height: 800px;
		}
	}
}
