@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.AddCertificateContainer {
	margin-top: 42px;
	width: 100%;
	padding: 16px;
	box-sizing: border-box;
	border-radius: 4px;
	border: 1px solid var(--Primary-200, #d8e4fc);
	@include flex-box(
		$display: flex,
		$direction: column,
		$justify: flex-start,
		$align: flex-start
	);
	margin-bottom: 15px;
	.CertificateHeader {
		width: 96%;
		border-radius: 4px;
		border: 1px solid var(--Primary-200, #d8e4fc);
		@include flex-box(
			$display: flex,
			$direction: row,
			$justify: space-between,
			$align: center
		);
		padding: 10px 16px;
		h1 {
			color: #2a53a9;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
		}
		.CertIcon {
			width: 20px;
			height: 20px;
			color: #2a53a9;
		}
	}
	.CertificateUpload {
		width: 99%;
		margin-top: 16px;
		border-radius: 4.265px;
		border: 1.066px dashed
			var(--Secondary-200, #9ba6bc);
		background: var(
			--Background-Primary-Bg,
			#f5f8fa
		);
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: center;
		align-items: center;
		#UploadFile1 {
			border: solid 1px;
			height: 70px;
			width: 60px;
			transform: translateY(35px);
			position: relative;
			z-index: 15;
			opacity: 0;
		}
		img {
			margin-top: -50px;
			width: 90px;
			height: 80px;
		}
		p {
			color: #2e2c2c;
			text-align: center;
			font-family: "TT Wellingtons Trl";
			font-size: 14.929px;
			font-style: normal;
			font-weight: 400;
		}
		#UploadFile2 {
			width: 80px;
			position: relative;
			z-index: 15;
			border: solid 1px;
			transform: translateY(-15px);
			opacity: 0;
		}
		h6 {
			color: #2a53a9;
			font-family: "TT Wellingtons Trl";
			font-size: 14.929px;
			font-style: normal;
			font-weight: 500;
			margin-top: -45px;
		}
	}
	.AddCertificateButtons {
		margin-top: 40px;
		width: 99.5%;
		display: flex;
		gap: 20px;
		justify-content: flex-end;
		#SaveCertificate {
			border-radius: 4px;
			border: 1px solid
				var(--Foundation-PRI-pri-500, #2a53a9);
			background-color: transparent;
			color: var(
				--Foundation-PRI-pri-500,
				#2a53a9
			);
			text-align: center;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			width: 150px;
			height: 50px;
		}
		#Publish {
			width: 150px;
			height: 50px;
			background-color: #2a53a9;
			border-radius: 4px;
			border: none;
			text-align: center;
			font-family: "TT Wellingtons Trl";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			color: #fff;
		}
	}
}
