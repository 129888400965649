@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.CoachCourseResourcesContainer{
    @include container($width: 90%);
    position: relative;
    margin-top: 1.5rem;
    margin-left: 9%;
    flex-grow: 2;
    overflow-x: hidden;
    .CourseResourcesWrapper{
        width: 100%;
        box-sizing: border-box;
        @include flex-box($display: flex, $direction: column, $align: flex-start, $justify: flex-start);
        h3{
            color: var(--Foundation-PRI-pri-500, #2A53A9);
            font-family: TT Wellingtons Trl;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; /* 108.333% */
            letter-spacing: 0.2px;
        }
        .CourseResourcesTabs{
            @include flex-box($justify: flex-start, $align: flex-start);
            width: 100%;
            margin-bottom: 42px;
            border-bottom: 0.5px solid var(--Secondary-200, #9BA6BC);
            .CourseResourcesTab{
                width: 25%;
                gap: 8px;
                @include flex-box($justify: flex-start, $align: center);
                p{
                    color: var(--Secondary-200, #9BA6BC);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 22.4px */
                }
            }
            .CourseResourcesActiveTab{
                width: 25%;
                gap: 8px;
                @include flex-box($justify: flex-start, $align: center);
                border-bottom: 2px solid var(--Foundation-PRI-pri-500, #2A53A9);
                p{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
            }
        }
        .CourseModuleResources{
            width: 100%;
            padding: 16px;
            gap: 24px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            border-radius: 4px;
            border: 1px solid var(--Primary-200, #D8E4FC);
            .ModuleResourcesAccordionWrapper{
                padding: 16px;
                width: 100%;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid var(--Primary-200, #D8E4FC);
                background: var(--Default-White, #FFF);
                .ModuleResourcesHeading{
                    @include flex-box($align: center, $justify: space-between);
                    width: 100%;
                    height: 24px;
                    box-sizing: border-box;
                    cursor: pointer;
                    h4{
                        color: var(--Foundation-PRI-pri-500, #2A53A9);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%; /* 17.6px */
                    }
                    .ModuleResourcesAccordionIcon{
                        height: 24px;
                        width: 24px;
                        color: #2A53A9;
                    }
                    .rotate{
                        transform: rotate(180deg);
                    }
                }
                .ModuleResourcesAdd{
                    width: 100%;
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.5s cubic-bezier(0,1,0,1);
                    @include flex-box($align: center, $justify: space-between);
                    p{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%; /* 17.6px */
                        margin: 0;
                        padding: 0;
                    }
                    .ModuleResourcesAddIcon{
                        height: 24px;
                        width: 24px;
                        color: #2A53A9;
                        cursor: pointer;
                    }
                }
                .ModuleResourcesAdd.show{
                    margin-top: 20px;
                    height: auto;
                    max-height: 9999px;
                    transition: all 0.5s cubic-bezier(0,1,0,1);
                    border-radius: 4px;
                    border: 1px solid var(--Primary-200, #D8E4FC);
                    background: var(--Default-White, #FFF);
                    padding: 16px;
                    box-sizing: border-box;
                }
            }
        }
        .ModuleResourcesButton{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 40px;
            margin-top: 68px;
            align-items: flex-end;
            margin-bottom: 40px;
            a{
                width: 200px;
                height: 100%;
                text-decoration: none;
                button{
                    width: 100%;
                    height: 100%;
                    padding: 24px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    background: var(--Foundation-PRI-pri-500, #2A53A9);
                    color: var(--Black-And-White-White, #FFF);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 162.5% */
                    letter-spacing: 0.2px;
                }
            }

        }
    }
}