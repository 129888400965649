.WarningCardContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 17;
	.WarningCard {
		width: 35%;
		min-height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		border: 1.561px solid
			var(--CoolGray-20, #dde1e6);
		background: var(--Default-White, #fff);
		.CancelIconSection {
			width: 92%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-top: -10px;
			transform: translateY(-15px);
			.CancelIcon {
				width: 24px;
				height: 24px;
				color: "#92989d";
				cursor: pointer;
				text-align: right;
			}
		}
		.WarningIcon {
			width: fit-content;
			height: fit-content;
			padding: 12px;
			border: none;
			border-radius: 100%;
			background: #d8e4fc;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: -20px;
			span {
				background: var(
					--Foundation-PRI-pri-100,
					#bdcae4
				);
				padding: 10px;
				border: none;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				#WarningIcon {
					width: 24px;
					height: 24px;
					color: #1e3b78;
				}
			}
		}
		.WarningMessage {
			width: 60%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			p {
				color: var(
					--Foundation-Neutral-Black,
					#2e2c2c
				);
				text-align: center;
				font-family: "TT Wellingtons Trl";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				margin-top: 40px;
			}
		}
		.WarningButton {
			width: 60%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 30px;
			button {
				width: 90%;
				height: 40px;
				cursor: pointer;
				border-radius: 6.242px;
				border: 3.121px solid
					var(--Foundation-PRI-pri-500, #2a53a9);
				background: var(
					--Foundation-PRI-pri-500,
					#2a53a9
				);
				color: var(--Default-White, #fff);
				font-family: "TT Wellingtons Trl";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				margin-top: 10px;
			}
		}
	}
}

.SuccessCardContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 17;
	.SuccessCard {
		width: 35%;
		min-height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		border: 1.561px solid
			var(--CoolGray-20, #dde1e6);
		background: var(--Default-White, #fff);
		.CancelIconSection {
			width: 92%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-top: -10px;
			transform: translateY(-15px);
			.CancelIcon {
				width: 24px;
				height: 24px;
				color: "#92989d";
				cursor: pointer;
				text-align: right;
			}
		}
		.SuccessIcon {
			width: fit-content;
			height: fit-content;
			padding: 12px;
			border: none;
			border-radius: 100%;
			background: #d8e4fc;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: -20px;
			span {
				background: var(
					--Foundation-PRI-pri-100,
					#bdcae4
				);
				padding: 10px;
				border: none;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				#SuccessIcon {
					width: 24px;
					height: 24px;
					color: #1e3b78;
				}
			}
		}
		.SuccessMessage {
			width: 60%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			p {
				color: var(
					--Foundation-Neutral-Black,
					#2e2c2c
				);
				text-align: center;
				font-family: "TT Wellingtons Trl";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				margin-top: 40px;
			}
		}
		.SuccessButton {
			width: 60%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 30px;
			button {
				width: 90%;
				height: 40px;
				cursor: pointer;
				border-radius: 6.242px;
				border: 3.121px solid
					var(--Foundation-PRI-pri-500, #2a53a9);
				background: var(
					--Foundation-PRI-pri-500,
					#2a53a9
				);
				color: var(--Default-White, #fff);
				font-family: "TT Wellingtons Trl";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				margin-top: 10px;
			}
		}
	}
}

.AlertCardContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 15;
	.AlertCard {
		width: 35%;
		min-height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		padding: 20px 0;
		border: 1.561px solid
			var(--CoolGray-20, #dde1e6);
		background: var(--Default-White, #fff);
		.SuccessIcon {
			width: fit-content;
			height: fit-content;
			padding: 12px;
			border: none;
			border-radius: 100%;
			background: #d8e4fc;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: -5px;
			span {
				background: var(
					--Foundation-PRI-pri-100,
					#bdcae4
				);
				padding: 10px;
				border: none;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				#SuccessIcon {
					width: 24px;
					height: 24px;
					color: #1e3b78;
				}
			}
		}
		.SuccessMessage {
			width: 60%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			p {
				color: var(
					--Foundation-Neutral-Black,
					#2e2c2c
				);
				text-align: center;
				font-family: "TT Wellingtons Trl";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				margin-top: 40px;
			}
		}
		.SuccessButton {
			width: 60%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 30px;
			button {
				width: 90%;
				height: 40px;
				cursor: pointer;
				border-radius: 6.242px;
				border: 3.121px solid
					var(--Foundation-PRI-pri-500, #2a53a9);
				background: var(
					--Foundation-PRI-pri-500,
					#2a53a9
				);
				color: var(--Default-White, #fff);
				font-family: "TT Wellingtons Trl";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				margin-top: 10px;
			}
		}
	}
}
