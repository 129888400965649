@import "../../../static/styles/shared.scss";
@import "../../../static/styles/variables.styles.scss";

.SpecializationModalContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .SpecializationModal{
        position: fixed;
        left: 68%;
        width: 300px;
        padding: 63px 44px 46px 34px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 16px;
        background: #FFF;
        box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
        button{
            width: 100%;
            border: 1px solid #C5D6FB;
            padding: 10px;
            text-align: start;
            background-color: transparent;
            color: var(--Foundation-Neutral-Black, #2E2C2C);
            font-family: $first-font;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
            cursor: pointer;
        }
        button:first-child{
            background: rgba(60, 118, 241, 0.80);
            color: #FAFAFA;
        }
    }
}

.CoachingPreferencesContainer{
    width: 100%;
    position: relative;
    background: #FFF;
    flex-grow: 2;
    overflow-x: hidden;
    .CoachingPreferencesContent{
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;
        margin: 20px;
        background: #FFF;
        box-shadow: -4px 4px 6px -4px rgba(108, 107, 107, 0.23);
        .PreferencesTopSection{
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            h6{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                margin: 0;
                padding: 0;
                margin-bottom: 24px;
            }
            .PreferencesAddSpecialization{
                @include flex-box($justify: space-between);
                width: 100%;
                margin-bottom: 23px;
                p{
                    padding: 0;
                    margin: 0;
                    width: 678px;
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                }
                button{
                    color: var(--Grey-Grey-100, #FAFAFA);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 125% */
                    height: 48px;
                    box-sizing: border-box;
                    padding: 8px 16px;
                    border-radius: 4px;
                    border: 1px solid var(--Primary-300, #C5D6FB);
                    background: var(--primary-500-base, #3C76F1);
                }
            }
            .PreferencesSpecializations{
                @include flex-box($direction: column, $align: flex-start);
                width: 100%;
                gap: 23px;
                .PreferencesSpecialization{
                    @include flex-box($justify: space-between);
                    width: 100%;
                    padding: 12px 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 0.5px solid var(--Primary-200, #D8E4FC);
                    p{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 22.4px */
                        margin: 0;
                        padding: 0;
                    }
                }
                .PreferencesSpecializationNotification{
                    @include flex-box($justify: space-between, $align: flex-start);
                    width: 100%;
                    .PreferencesNotificationAllow{
                        @include flex-box($direction: column, $align: flex-start, $justify: flex-start);
                        width: 100%;
                        gap: 9px;
                        h6{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 110%; /* 22px */
                            margin: 0;
                            padding: 0;
                        }
                        p{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
            }
        }
        .PreferencesMidSection{
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            h6{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 26.4px */
                margin: 0;
                padding: 0;
                margin-bottom: 24px;
            }
            .PreferencesCoachingStyle{
                @include flex-box($direction: column, $align: flex-start);
                width: 100%;
                gap: 24px;
                .PreferencesCoachingStyleHeading{
                    @include flex-box($direction: column, $align: flex-start);
                    width: 100%;
                    gap: 9px;
                    h3{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 28px; /* 175% */
                        margin: 0;
                        padding: 0;
                    }
                    p{
                        padding: 0;
                        margin: 0;
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
                .PreferencesGroupSessions{
                    @include flex-box($direction: column, $align: flex-start);
                    width: 100%;
                    gap: 16px;
                    .PreferencesGroupSession{
                        @include flex-box($justify: space-between);
                        width: 100%;
                        p{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
                .PreferencesAppointment{
                    @include flex-box($direction: column, $align: flex-start);
                    width: 100%;
                    gap: 4px;
                    h6{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 28px; /* 175% */
                        margin: 0;
                        padding: 0;
                    }
                    .PreferencesAppointmentSwitch{
                        @include flex-box($justify: space-between);
                        width: 100%;
                        box-sizing: border-box;
                        p{
                            padding: 0;
                            margin: 0;
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                        }
                    }
                }
            }
        }
        .PreferencesBottomSection{
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            .PreferencesCommunicationNotifications{
                @include flex-box($justify: space-between);
                width: 100%;
                height: 48px;
                padding: 12px 16px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 0.5px solid var(--Primary-200, #D8E4FC);
                margin-bottom: 12px;
                h6{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%; /* 22px */
                    margin: 0;
                    padding: 0;
                }
            }
            p{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    margin: 0;
                    padding: 0;
                    margin-bottom: 40px;
                }
            .PreferencesNotificationsChoice{
                @include flex-box($direction: column, $align: flex-start);
                width: 100%;
                margin-bottom: 40px;
                h6{
                    color: var(--Foundation-Neutral-Black, #2E2C2C);
                    font-family: $first-font;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; /* 125% */
                    margin: 0;
                    padding: 0;
                    margin-bottom: 40px;
                }
                .PreferencesCheckBoxes{
                    @include flex-box($direction: column, $align: flex-start);
                    width: 100%;
                    gap: 40px;
                    #PreferencesCheckBox{
                        width: 50%;
                        height: 32px;
                        @include flex-box($justify:flex-start, $align: center);
                        label{
                            color: var(--Foundation-Neutral-Black, #2E2C2C);
                            font-family: $first-font;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                        }
                    }
                }
            }
        }
        .PreferencesNotificationsFrequency{
            @include flex-box($direction: column, $align: flex-start);
            width: 100%;
            gap: 40px;
            h6{
                color: var(--Foundation-Neutral-Black, #2E2C2C);
                font-family: $first-font;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 125% */
                margin: 0;
                padding: 0;
            }
            .PreferencesRadioButtons{
                @include flex-box($direction: column, $align: flex-start);
                width: 100%;
                gap: 40px;
                #PreferencesRadioButton{
                    width: 50%;
                    height: 32px;
                    @include flex-box($justify:flex-start, $align: center);
                    label{
                        color: var(--Foundation-Neutral-Black, #2E2C2C);
                        font-family: $first-font;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
            }
        }
    }
}